// News page
.news-wrapper {
    .month-wrapper {
        background-color: #f2f2f2;
        margin: 20px 0;
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
    }
    .month-slider {
        // padding: 0 50px;
        // margin: 10px 0;
        // height: 40px;
        // width: 75%;
        margin: 10px 25px 20px 75px;
        .glide__track {
            // padding: 0 50px;
            .list-item {
                display: flex;
                align-items: center;
                justify-content: center;
                a {
                    // height: 40px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
        .glide__arrows .icon {
            color: #222222;
            font-size: 14px;
        }
    }
    .blocks-wrapper {
        .block {
            &:nth-child(3n) {
                width: 100%;
                margin: 20px 0;
            }
        }
        .ad-wrapper {
            width: 100%;
        }
    }
}



// Index page
.dzyga-news {
    .blocks-wrapper {
        .block {
            &:nth-child(3n) {
                width: 100%;
                margin: 20px 0;
            }
        }
    }
}