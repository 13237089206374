.breadcrumbs {
    color: #a3a3a4;
    font-family: MontserratBold;
    font-weight: 700;
    font-size: 14px;
    .breadcrumb-items {
        display: flex;
        .breadcrumb-item {
            display: flex;
            align-items: center;
            margin-right: 10px;
            &:last-child {
                &::after {
                    content: none; } }
            &::after {
                content: '\e803';
                font-family: "dzygambd";
                font-style: normal;
                font-weight: normal;
                font-size: 10px;
                margin-left: 10px; }

            a {
                display: flex;
                align-items: center;
                height: 40px;
                font-size: 14px; } } } }
