$hover_transition: all .3s linear;

.sign {
    .btn {
        padding: 10px 60px;
        display: inline-block;
        margin-left: auto;
        color: white;
        background-color: #222222;
        transition: $hover_transition;
        border-radius: 0;

        &:hover {
            background-color: #FFCD00;
            color: #ffffff;
        }
    }
}

.input-box {
    label {
        position: absolute;
        height: 50%;
        margin: auto;
        top: 0;
        left: 10px;
        color: #787878;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        opacity: 0;
        z-index: -1;
        transition: all .4s ease;
        font-size: 12px;
    }


    input:focus:not(:placeholder-shown)+label,
    input:not(:placeholder-shown)+label {
        top: -25px;
        transition: all .4s ease;
        opacity: 1;
        z-index: 1;
    }
}

.dzyga-main.tariff {
    width: 100%;
    padding: 0;
    padding: 20px 0px 50px 0;
}


.form-contact-us {
    padding: 15px 20px;
    background-color: $background_text;
    .form-btn-save {
        background-color: $medium_gray;
        color: white;
        padding: 10px 20px;
    }
}

.input-wrapper {
    &.photo {
        position: relative;

        label {
            width: 100%;
            background-color: #A4A6AB;
            cursor: pointer;
            margin-top: 5px;
        }

        label {
            width: 100%;
            background-position: center;
            background-size: cover;
            position: relative;
            left: 0;
            display: block;
            opacity: 1;
            z-index: 2;
        }


        input {
            display: none;
        }
    }
}
.input-box {
    margin-bottom: 40px;
    width: 100%;
    position: relative;

    .tagify {
        background-color: #A4A6AB;
        max-width: 100%;
        overflow-x: auto;

        .tagify__tag__removeBtn {
            color: white;
        }

        .tagify__tag>div {
            background-color: #222222;
        }

        .tagify__tag>div>* {
            color: white;
        }
    }

    &.photo {
        position: relative;

        label {
            width: 100%;
            background-color: #A4A6AB;
            cursor: pointer;
            margin-top: 5px;
        }

        label[for=photo] {
            width: 100%;
            background-position: center;
            background-size: cover;
            position: relative;
            left: 0;
            display: block;
            opacity: 1;
            z-index: 2;
        }


        input {
            display: none;
        }
    }

    // overflow: hidden
    &.error {
        input {
            border: 1px solid red;
        }
    }

    input {
        width: 100%;
        text-indent: 10px;
        height: 40px;
        border: none;
        overflow: hidden;
        background-color: #a3a3a4;
        color: #f2f2f2;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        font-size: 12px;

        &::placeholder {
            color: #f2f2f2
        }

        &::-webkit-input-placeholder {
            color: #f2f2f2
        }

        &::-moz-placeholder {
            color: #f2f2f2
        }

        &:-ms-input-placeholder {
            color: #f2f2f2
        }

        &:-moz-placeholder {
            color: #f2f2f2
        }
    }

    input[type="password"] {
        font: large Verdana, sans-serif;
        letter-spacing: 1px;

        &::placeholder {
            font-family: 'MontserratRegular', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
        }

        &::-webkit-input-placeholder {
            font-family: 'MontserratRegular', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
        }

        &::-moz-placeholder {
            font-family: 'MontserratRegular', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
        }

        &:-ms-input-placeholder {
            font-family: 'MontserratRegular', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
        }

        &:-moz-placeholder {
            font-family: 'MontserratRegular', sans-serif;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: normal;
        }
    }

    label {
        position: absolute;
        height: 50%;
        margin: auto;
        top: 0;
        // bottom: 0;
        left: 10px;
        color: #787878;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        opacity: 0;
        z-index: -1;
        transition: all .4s ease;
        font-size: 12px;
    }

    span {
        font-size: 0.9em;
    }
}

.input-wrapper {
    margin: 0 0 15px 0;
    width: 100%;
    position: relative;

    &.full {
        width: 100%;
    }

    &.phone {
        width: 100%;
        margin-bottom: 0;

        input:not(:only-of-type) {
            margin-bottom: 10px;
        }

        input {
            width: 100%;
            background-color: #a4a6ab;
            color: #fff;
            padding: 10px;
            margin-top: 5px;
            height: 36px;
        }
    }

    input,
    select {
        width: 100%;
        background-color: #a4a6ab;
        color: #fff;
        padding: 10px;
        margin-top: 5px;
        height: 36px;
    }

    span,
    label {
        font-size: 0.9em;
    }

    &.delete-field {
        display: none;
        margin-top: 15px;
    }
}

.btn-field {
    font-size: 1em;
    color: white;
    position: absolute;
    right: 5px;
    top: 15px;
}

.photo-portfolio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

    span {
        width: 100%;
    }

    input {
        display: none;
    }
}

.img-preview {
    height: 62px;
    width: 62px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
    position: relative;

    .js-delete-preview {
        position: absolute;
        top: 0;
        right: 0;
        color: white;
        cursor: pointer;
    }
}

.project,
.company {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    &.add {
        flex-wrap: nowrap;
        width: 100%;
    }

    .input-wrapper {
        width: calc(50% - 10px);
    }

    .btn-field {
        top: 30px;
    }

    &.delete {
        display: none;
    }
}

label.file {
    margin-right: 50%;
    background-color: $medium_gray;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 15px;
    padding: 10px 25px;
    width: 50%;
}

textarea {
    resize: none;
    height: 150px;
    width: 100%;
    background-color: #a4a6ab;
    color: #fff;
    padding: 10px;
    margin-top: 5px;
}

.select-box {
    margin-bottom: 40px;
    width: 100%;
    position: relative;

    &.error {
        select {
            border: 1px solid red;
        }
    }

    select {
        width: 100%;
        text-indent: 2px;
        padding: 0;
        height: 40px;
        border: none;
        border-radius: 0;
        overflow: hidden;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        background-color: #a3a3a4;
        color: #f2f2f2;
        font-size: 12px;
    }

    label {
        position: absolute;
        height: 50%;
        margin: auto;
        left: 10px;
        color: #787878;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        top: -25px;
        opacity: 1;
        z-index: 1;
        font-weight: 500;
        transition: all .4s ease;
        font-size: 12px;
        display: flex;

        .hint-wrapper:hover {
            .info {
                visibility: visible;
                overflow: visible;
                opacity: 1;
            }
        }

        .info {
            position: absolute;
            width: 250px;
            padding: 5px;
            top: -210px;
            right: -125px;
            background-color: #222;
            color: white;
            opacity: 0;
            visibility: hidden;
            overflow: hidden;
            transition: $hover_transition;
            padding: 10px;

            &::after {
                content: '';
                display: block;
                width: 0;
                height: 0;
                position: absolute;
                left: 110px;
                top: 100%;
                border-style: solid;
                border-width: 15px 12.5px 0 12.5px;
                border-color: #222 transparent transparent transparent;
            }

            a {
                color: $gold;
                font-family: MontserratBold;
            }
        }


        i {
            color: #222222;
            cursor: pointer;
            position: relative;
        }
    }
}

.sign-wrap {
    padding: 60px 0 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    margin: 0 auto;

    .password-refresh {
        background-color: #F2F2F2;
        padding: 15px 30px 20px;
        display: flex;
        flex-direction: column;
        h4 {
            margin-bottom: 40px;
            font-family: MontserratBold;
        }
        button[type=submit] {
            padding: 10px 35px;
            display: block;
            color: white;
            background-color: #222222;
            transition: $hover_transition;
            margin-bottom: 10px;
            width: 70%;

            &:hover {
                color: #FFCD00;
            }
        }
    }

    .content {
        display: flex;

        .info {
            margin-top: 55px;
            border-left: 1px solid #414046;
            height: auto;
            margin-left: 30px;
            padding-left: 30px;

            p {
                margin-bottom: 15px;
            }
        }
    }

    img {
        width: 250px;
        height: auto;
        margin-bottom: 55px;
    }

    .tabs-wrap {
        display: flex;
        flex-direction: column;
        margin-top: 55px;
        .tab-label-items {
            display: flex;
            max-width: 100%;

            .label-item {
                max-width: 50%;
                width: 170px;
                height: 40px;
                background-color: #f2f2f2;
                color: #222222;
                transition: $hover_transition;
                font-size: 14px;
                font-weight: 700;
                line-height: normal;
                font-family: 'MontserratBold', sans-serif;

                &.active {
                    background-color: #222222;
                    color: #f2f2f2;
                    transition: $hover_transition;
                }

                &:hover {
                    color: #FFCD00;
                    transition: $hover_transition;
                }
            }
        }

        .tab-content-items {
            background-color: #f2f2f2;
            width: 100%;
            max-width: 100%;

            .content-item {
                display: none;
                padding: 45px 20px 20px;
                flex-direction: column;

                &.active {
                    display: flex;
                }

                button {
                    width: 160px;
                    height: 40px;
                    margin-bottom: 15px;
                    background-color: #222222;
                    color: #f2f2f2;
                    font-size: 14px;
                    font-family: 'MontserratMedium', sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    transition: $hover_transition;

                    &:hover {
                        background-color: #FFCD00;
                        color: #222222;
                        transition: $hover_transition;
                    }
                }

                .resed-pas {
                    font-size: 12px;
                    color: #787878;
                    transition: $hover_transition;

                    &:hover {
                        color: #222222;
                        transition: $hover_transition;
                    }
                }
            }
        }
    }

    .form-wrapper {
        background-color: #F2F2F2;
        padding: 10px 20px;
        margin-top: 70px;
        max-width: 72%;

        .heading {
            background-color: inherit;
            text-align: center;
        }

        .step-2,
        .step-3,
        .step-2-company {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;

            .col {
                width: calc(50% - 10px);
            }

            button[type=submit] {
                padding: 10px 60px;
                display: block;
                margin-left: auto;
                color: white;
                background-color: #222222;
                transition: $hover_transition;

                &:hover {
                    color: #FFCD00;
                }
            }
        }

        .step-3 {
            button[type=submit] {
                margin-top: 40px;
            }
        }

        .step-2-company {
            .input-wrapper {
                margin-bottom: 25px;
            }

            span {
                font-size: 0.9em;
            }
        }

        &.tariffs {
            max-width: 100%;

            .heading {
                text-align: center;
            }

            .tariffs-wrapper {
                display: flex;

                .tariff-item {
                    padding: 30px 20px;
                    background-color: #414046;
                    color: white;
                    text-align: center;
                    display: flex;
                    flex-direction: column;
                    min-height: 213px;
                    max-height: 213px;
                    margin-right: 20px;

                    &.top {
                        min-height: 270px;
                        position: relative;
                        top: -25px;
                        padding-top: 55px;

                        span.top {
                            position: absolute;
                            top: 0;
                            left: 50%;
                            transform: translate(-50%, 0%);
                            background-color: #302F34;
                            padding: 5px 40px;
                            margin-bottom: 0;
                            font-size: 1em;
                        }
                    }

                    &:last-of-type {
                        margin-right: 0;
                    }

                    .tariff-price {
                        font-family: 'MontserratExtraBold';
                        font-size: 1.4em;
                    }

                    .tariff-description {
                        margin: 15px 0;
                    }

                    .tariff-btn {
                        background-color: #302F34;
                        border: 1px solid white;
                        padding: 5px 40px;
                    }

                    span {
                        margin-bottom: 15px;
                        display: block;
                        font-size: 0.9em;
                    }
                }
            }
        }
    }

    .step-list {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        margin-top: 15px;
        margin-bottom: 65px;

        .list-item {
            background-color: #a3a3a4;
            width: 35px;
            height: 35px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: white;
            border-radius: 50%;
            position: relative;
            margin: 0 50px;

            &:last-of-type {

                // margin-right: 0;
                &::after {
                    display: none;
                }
            }

            &::after {
                content: '';
                display: block;
                position: absolute;
                height: 2px;
                width: 100px;
                background-color: inherit;
                left: 100%;
            }

            &.active {
                background-color: #222222;
            }
        }
    }
}

.team-wrapper {
    .project {
        flex-wrap: nowrap;
    }

    .input-wrapper {
        width: auto;
        margin-right: 20px;
    }

    .select {
        width: 35%;
        margin-right: 0;
    }
}