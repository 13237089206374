.article-wrapper {
    h1 {
        font-size: 1.7em;
        font-family: MontserratBold;
        padding: 10 0px; }
    h2 {
        font-size: 1.5em;
        font-family: MontserratBold;
        padding: 10 0px; }
    h3 {
        font-size: 1.3em;
        font-family: MontserratBold;
        padding: 10 0px; }
    h4 {
        font-size: 1.2em;
        font-family: MontserratBold;
        padding: 10 0px; }
    h5 {
        font-size: 1.1em;
        font-family: MontserratBold;
        padding: 10 0px; }
    h6 {
        font-size: 1em;
        font-family: MontserratBold;
        padding: 10px 0; }
    .news-img {
        height: 305px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center; }
    .title-article {
        font-size: 18px;
        line-height: normal;
        font-family: 'MontserratExtraBold', sans-serif;
        font-weight: 900;
        margin-bottom: 10px; }
    .date {
        font-size: 12px;
        line-height: normal;
        margin-bottom: 40px;
        font-family: 'MontserratMedium';
        font-weight: 500;
        color: #9a999d; }
    .content {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 20px;
        .gallery-items {
            display: flex;
            flex-wrap: wrap;
            .gallery-item {
                width: calc(20% - 20px);
                margin-bottom: 20px;
                margin-right: 20px;
                height: 150px;
                position: relative;
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                &:nth-child(5n) {
                    margin-right: 0; }
                a {
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    top: 0;
                    left: 0; } } }
        p {
            margin: 0 0 15px 0;
            font-size: 13px;
            line-height: normal;
            font-family: 'MontserratMedium';
            font-weight: 500; }


        .gallery-items {
            margin: 20px 0 25px; }

        .ad-wrapper {
            width: 100%;
            margin-bottom: 30px; } } }
