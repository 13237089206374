@media (min-width:1201px) {
    .btn {
        transition: $transition;

        &:hover {
            color: $gold;
            background-color: white;
        }
    }

    .link-search {
        transition: $transition;

        &:hover {
            color: white;
        }
    }

    .cinema-wrapper .cinema-filter .list-item a {
        transition: $transition;

        &:hover {
            background-color: $dark_gray;
            color: white;
        }
    }

    .tabs-btn-wrapper .tab-btn {
        transition: $transition;

        &:hover {
            color: $gold;
        }
    }

    .dzyga-header .top-navigation a {
        transition: $transition;

        &:hover {
            color: $gold;
        }
    }

    .dzyga-header .top-navigation .social-list a {
        transition: $transition;

        &:hover {
            color: white;
        }
    }

    .add-to-bookmark {
        &:hover {
            .icon-bookmark-empty::before {
                content: '\e80b'
            }
        }
    }
}

@media (max-width:1200px) {
    body {
        margin-top: 80px;
    }

    main {
        transition: $transition;
    }

    h2.section-heading {
        font-size: 3em;
    }

    .container {
        overflow: hidden;
        width: 100%;
    }

    .dzyga-header {

        .top-navigation,
        .main-navigation {
            display: none;
        }
    }

    .dzyga-header-mobile {
        display: flex;
        justify-content: space-between;
        padding: 20px;
        align-items: center;
        background-color: $dark_gray;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 10;

        .mobile-menu {
            position: absolute;
            width: 100%;
            height: 100vh;
            top: 0;
            left: 0;
            background-color: rgba(0, 0, 0, 0.9);
            z-index: 10;
            transition: $transition;
            overflow: hidden;
            opacity: 0;
            color: $dark_gray;
            visibility: hidden;

            &.active {
                left: 0;
                opacity: 1;
                visibility: visible;
                overflow: visible;

                .wrapper {
                    left: 70%;
                }
            }

            .wrapper {
                background-color: $medium_gray;
                height: 100%;
                width: 30%;
                left: 100%;
                position: relative;
                padding: 20px;
                transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;
                color: white;

                .search-wrapper {
                    width: 100%;

                    input {
                        width: 100%;
                        padding: 5px 15px;
                        border-radius: 5px;
                    }
                }

                .navigation-list {
                    margin: 10px 0;

                    .list-item {
                        padding: 5px;
                    }
                }

                .btn {
                    margin: 10px 0;
                }

                .languages-list {
                    display: flex;
                    width: 100%;
                    justify-content: space-evenly;
                }

                .social-list {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 20px;
                }

                .pro-menu {
                    flex-direction: column;
                    justify-content: flex-start;
                    align-items: flex-start;
                    padding: 0;

                    &::before,
                    &::after {
                        display: none;
                    }

                    .list-item {
                        position: relative;
                    }

                    .list-item.children:hover>.sub-menu {
                        display: block;
                        right: 100%;
                        max-height: 300px;
                        overflow-y: scroll;
                        left: initial;
                        top: 0;
                        width: auto;
                        padding: 10px 5px;
                    }

                    .list-item.children .sub-menu .list-item {
                        width: 100%;
                    }
                }
            }
        }

        .logo,
        .btn-menu {
            position: relative;
            z-index: 11;
        }
    }

    .dzyga-premiere {
        .section-heading {
            left: 50px;
            top: 280px;
        }

        .premiere-slider .glide__slide .movie-poster {
            height: 370px;
        }
    }
}

@media (max-width: 1024px) {
    h2.section-heading {
        font-size: 2.8em;
    }

    .dzyga-premiere .section-heading {
        top: 265px;
    }

    .cinema,
    .glide__slide.cinema {
        min-height: auto;
        height: auto;
    }

    .tabs-btn-wrapper .tab-btn {
        padding: 10px 30px;
    }

    .single-cinema .cinema-preview .cinema-poster {
        margin-right: 0;
        height: 300px;
        width: calc(30% - 15px)
    }

    .single-cinema .cinema-preview .cinema-video {
        flex-grow: 0;
        width: 70%;
    }

    .glide__slide.video-wrapper {
        height: 300px;
    }
}

@media (max-width: 992px) {
    main .dzyga-main {
        width: 100%;
        padding-bottom: 0;
    }

    main aside {
        width: 100%;
        padding-bottom: 0;

        .aside-ad {
            height: 200px;
        }

        .person {
            width: calc(25% - 5px);
        }

        .premier-wrapper {
            flex-wrap: wrap;
            flex-direction: row;
            justify-content: space-between;

            .premier-film {
                width: calc(50% - 5px);

                &:last-child {
                    margin-bottom: 10px;
                }
            }
        }
    }

    .single-cinema .cinema-preview .cinema-poster {
        height: 350px;
    }
}

@media (max-width: 768px) {
    h2.section-heading {
        font-size: 2.5em;
    }

    .dzyga-premiere .premiere-slider {
        width: calc(100% - 150px);
    }

    .dzyga-faq {
        margin-top: 20px;
        padding: 20px 0;
    }

    .dzyga-bottom-navigation .logo-ling {
        margin-right: 80px;
    }

    .dzyga-bottom-navigation .logo {
        width: 180px;
    }

    .person .person-info-wrapper .person-photo {
        width: 250px;
    }

    .single-cinema .cinema-preview .cinema-poster {
        height: 300px;
    }

    .glide__slide.video-wrapper {
        height: 470px;
    }

    .dzyga-header-mobile .mobile-menu .wrapper {
        width: 45%;
    }

    .dzyga-header-mobile .mobile-menu.active .wrapper {
        left: 55%;
    }
}

@media (max-width: 660px) {

    // Start FIX
    ul.team li {
        display: block;
        /* все в столбик */
    }

    .project-form .col.col-left {
        width: 100%;
    }

    .project.delete {
        display: block;
        /* все в столбик */
    }

    .project.delete .input-wrapper {
        margin-bottom: 10px;
    }

    .input-wrapper.btn-del,
    .select2,
    .input-wrapper.team-first_name,
    .input-wrapper.team-email,
    .input-wrapper.team-position,
    .input-wrapper.team-auth_id {
        width: 100% !important;
    }

    .form-btn.js-del-team {
        margin-left: 0 !important;
    }

    .dzyga-main .cinemas {
        width: 100%;
    }

    .dzyga-main .cinemas .company-item {
        flex-wrap: nowrap;
        flex-direction: column;
        margin-bottom: 350px;
    }

    .dzyga-main .cinemas .company-item .img {
        min-width: 150px;
        height: 100%;
        background: no-repeat 50%;
        background-size: cover;
        position: relative;
        width: 180px;
        flex-shrink: 0;
    }

    .project.h20.delete {
        display: none !important;
    }

    .project.team.delete {
        display: block !important;
    }

    .project.team.delete label {
        display: block !important;
    }

    /* Цвет текста и фона для ввода */
    .mce-content-body,
    .form-group input,
    .form-group select,
    .form-group textarea {
        background-color: #a4a6ab;
        color: #fff;
    }

    .casting .cinema-castings-wrapper .characteristics-list {
        display: inherit;
        /* все в столбик */
    }

    .casting-item .casting-desc {
        margin-top: 30px !important;
    }

    .modal-dialog-custom {
        width: auto;
    }

    /*.modal-footer {*/
    /*    text-align: left;*/
    /*}*/
    .btns {
        position: relative;
        top: 20px;
        right: 0;
        width: 100%;
    }

    .btns2 {
        display: flex;
        justify-content: flex-start;
        padding-left: 0 !important;
        /*line-height: 20px;*/
        /*min-height: 20px;*/
    }

    .btns2 a {
        margin-right: 5px;
        /*min-height: 20px;*/
    }

    .icons a {
        margin-bottom: 0;
        min-height: 32px;
    }

    /* Фикс моб меню */
    .dzyga-header-mobile .mobile-menu {
        background-color: #303032;
        height: calc(100vh - 78px);
        top: 78px;
    }

    .dzyga-header-mobile .mobile-menu .wrapper .social-list {
        margin-bottom: 25px;
        margin-left: 0;
        margin-right: 0;
    }

    .dzyga-header-mobile .mobile-menu .wrapper .social-list .list-item:last-child {
        padding-right: 0;
    }

    //End FIX

    h2.section-heading {
        font-size: 1.8em;
    }

    .dzyga-header-mobile .mobile-menu .wrapper {
        width: 55%;
    }

    .dzyga-header-mobile .mobile-menu.active .wrapper {
        left: 45%;
    }

    .dzyga-header-mobile {
        padding: 15px 20px;
    }

    body {
        margin-top: 70px;
    }

    main .dzyga-main {
        padding: 20px 0 0 0;
    }

    .dzyga-premiere .section-heading {
        transform: none;
        position: relative;
        top: initial;
        left: 0;
    }

    .dzyga-premiere .premiere-slider {
        width: calc(100% - 40px);
        margin: 0 auto;
    }

    .cinema,
    .glide__slide.cinema {
        flex-direction: column;
    }

    .cinema .cinema-poster,
    .glide__slide.cinema .cinema-poster {
        width: 100%;
        height: 243px;
    }

    .dzyga-cinema .cinema-slider,
    .laureats-slider {
        width: calc(100% - 40px)
    }

    .cinema-info-wrapper {
        width: 100%;
    }

    .glide__arrows .glide__arrow--left {
        left: -40px;
    }

    .glide__arrows .glide__arrow--right {
        right: -40px;
    }

    .block {
        width: 100%;
    }

    main aside .person {
        width: calc(50% - 5px);
    }

    .dzyga-bottom-navigation .container {
        flex-direction: column;
        align-items: center;
    }

    .dzyga-bottom-navigation .logo-ling {
        margin: 0 0 10px 0;
    }

    .cinema-info-wrapper {
        padding: 15px 15px 20px 15px;
    }

    .cinema-rating {
        position: relative;
        top: 0;
        right: 0;
        flex-wrap: nowrap;
        align-items: center;
    }

    .tabs-btn-wrapper {
        flex-wrap: wrap;
        justify-content: space-between;
    }

    .cinema-wrapper .tabs-btn-wrapper {
        margin-bottom: 10px;
    }

    .tabs-btn-wrapper .tab-btn {
        width: calc(50% - 5px);
        margin-bottom: 10px;
        padding: 10px 5px;
        display: block;
    }

    .box-office-table .t-name {
        padding-left: 5px;
    }

    .box-office-table .t-money,
    .box-office-table .t-rolling {
        padding-left: 5px;
    }

    .box-office-table thead tr th {
        font-size: 0.9em;
    }

    .box-office-table .t-name {
        font-size: 0.95em;
    }

    .box-office-table tbody tr td {
        font-size: .9em;
    }

    .box-office-wrapper .tabs-btn-wrapper {
        margin-bottom: 0;
    }

    .reviews-wrapper .cinema .cinema-poster {
        width: 100%;
    }

    .breadcrumbs {
        display: none;
    }

    .article-wrapper .news-img {
        margin-top: 0;
    }

    .article-wrapper .content .gallery-items {
        justify-content: space-between;
    }

    .article-wrapper .content .gallery-items .gallery-item {
        width: calc(50% - 20px);
        height: auto;
    }

    .article-wrapper .content .gallery-items .gallery-item:nth-child(5n) {
        margin-right: 20px;
    }

    .personality-wrapper .personality-items .personality-item .img {
        min-width: 150px;
        width: 150px;
    }

    .person .person-info-wrapper .person-information {
        width: 100%;
    }

    .person .photos .frames-slider {
        width: calc(100% - 40px);
    }

    .single-cinema .cinema-preview .cinema-video {
        width: 100%;
        height: 300px;
    }

    .single-cinema .cinema-preview .cinema-poster {
        width: 100%;
        height: 443px;
        margin-bottom: 15px;
    }
}

@media screen and (max-width: 600px) {
    table.box-office-table thead {
        display: none;
    }

    table.box-office-table tr {
        /*height: 60px;*/
        display: block;
        margin-bottom: 1rem;
        border-bottom: 2px solid #e8e9eb;
    }

    table.box-office-table td {
        height: 30px !important;
        display: block;
        text-align: right;
    }

    table.box-office-table td:first-child {
        height: 70px !important;
    }

    table.box-office-table td:before {
        content: attr(data-label);
        float: left;
        font-weight: bold;
    }

    .box-office-table .t-money,
    .box-office-table .t-name,
    .box-office-table .t-rolling {
        padding-left: 0;
    }

    /* Отображение кастинга */
    main .dzyga-main.audition {
        width: calc(100% - 0px);
    }

    /* Перенос даты приема заявок на новую строку */
    .project-castings-list.audition span:first-child {
        display: block;
    }
}

/* Личное меню в ноуте */
@media (max-height: 720px) {
    .account-wrapper .account-list {
        overflow: hidden !important;
        overflow-y: scroll !important;
        height: 300px;
        padding-right: 15px;
    }
}