@charset "UTF-8";
*, *::after, *::before {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
  outline: none;
  line-height: normal; }

html {
  line-height: normal;
  -webkit-text-size-adjust: 100%; }

button,
input,
optgroup,
select,
textarea {
  font-family: inherit;
  line-height: 1;
  margin: 0;
  border: none; }

[type=button],
[type=reset],
[type=submit],
button {
  -webkit-appearance: button; }

[type=button]::-moz-focus-inner,
[type=reset]::-moz-focus-inner,
[type=submit]::-moz-focus-inner,
button::-moz-focus-inner {
  border-style: none;
  padding: 0; }

[type=button]:-moz-focusring,
[type=reset]:-moz-focusring,
[type=submit]:-moz-focusring,
button:-moz-focusring {
  outline: ButtonText dotted 1px; }

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0;
  /* <-- Apparently some margin are still there even though it's hidden */ }

textarea {
  overflow: auto; }

[type=checkbox],
[type=radio] {
  box-sizing: border-box;
  padding: 0; }

[type=number]::-webkit-inner-spin-button,
[type=number]::-webkit-outer-spin-button {
  height: auto; }

[type=search] {
  -webkit-appearance: textfield;
  outline-offset: -2px; }

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none; }

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit; }

button {
  cursor: pointer;
  border: none; }

ol, ul {
  list-style: none; }

input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0; }

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0; }

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none; }

@font-face {
  font-family: 'dzyga';
  src: url("../fonts/dzyga.eot?74477912");
  src: url("../fonts/dzyga.eot?74477912#iefix") format("embedded-opentype"), url("../fonts/dzyga.ttf?74477912") format("truetype"), url("../fonts/dzyga.svg?74477912#dzyga") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'dzyga';
      src: url('../font/dzyga.svg?61282810#dzyga') format('svg');
    }
  }
  */
[class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "dzyga";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .2em;
  text-align: center;
  /* opacity: .8; */
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1em;
  /* Animation center compensation - margins should be symmetric */
  /* remove if not needed */
  margin-left: .2em;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */ }

.icon-globe:before {
  content: '\e800'; }

/* '' */
.icon-search:before {
  content: '\e801'; }

/* '' */
.icon-left-open:before {
  content: '\e802'; }

/* '' */
.icon-right-open:before {
  content: '\e803'; }

/* '' */
.icon-star:before {
  content: '\e804'; }

/* '' */
.icon-star-empty:before {
  content: '\e805'; }

/* '' */
.icon-plus-circled:before {
  content: '\e806'; }

/* '' */
.icon-up-big:before {
  content: '\e807'; }

/* '' */
.icon-up-open:before {
  content: '\e808'; }

/* '' */
.icon-down-big:before {
  content: '\e809'; }

/* '' */
.icon-help-circled:before {
  content: '\e80a'; }

/* '' */
.icon-bookmark:before {
  content: '\e80b'; }

/* '' */
.icon-phone:before {
  content: '\e80c'; }

/* '' */
.icon-mail:before {
  content: '\e80d'; }

/* '' */
.icon-location:before {
  content: '\e80e'; }

/* '' */
.icon-pencil:before {
  content: '\e80f'; }

/* '' */
.icon-cancel:before {
  content: '\e810'; }

/* '' */
.icon-plus:before {
  content: '\e811'; }

/* '' */
.icon-calendar:before {
  content: '\e812'; }

/* '' */
.icon-bookmark-empty:before {
  content: '\f097'; }

/* '' */
.icon-facebook:before {
  content: '\f09a'; }

/* '' */
.icon-star-half-alt:before {
  content: '\f123'; }

/* '' */
.icon-youtube-play:before {
  content: '\f16a'; }

/* '' */
.icon-instagram:before {
  content: '\f16d'; }

/* '' */
.icon-twitter:before {
  content: '\f309'; }

/* '' */
.icon-site:before {
  content: '\f4f0'; }

/* '' */
a {
  color: inherit; }

section {
  overflow-x: hidden; }

.container {
  width: 1200px;
  padding: 0 20px;
  margin: auto;
  position: relative; }

.icon {
  font-family: DzygaMBD;
  font-style: normal;
  color: inherit; }

.list-dropdown {
  max-height: 1em;
  position: relative;
  z-index: 2; }
  .list-dropdown li {
    opacity: 0;
    overflow: hidden;
    visibility: hidden;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    padding-bottom: 2px; }
    .list-dropdown li:first-of-type {
      opacity: 1;
      visibility: visible;
      overflow: visible;
      top: 0;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  .list-dropdown:hover > li {
    opacity: 1;
    visibility: visible;
    overflow: visible;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

.btn {
  padding: 10px 40px;
  background-color: #FFCD00;
  border-radius: 5px; }

h2.section-heading {
  font-family: MontserratBlack;
  font-size: 3.7em;
  text-transform: uppercase;
  color: #A0A0A0; }

.ad-wrapper, .news-img {
  position: relative;
  margin: 20px 0; }
  .ad-wrapper a, .news-img a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }

/* CINEMA ITEM */
.glide__slide.cinema, .cinema {
  height: auto;
  min-height: 240px;
  display: flex;
  align-items: flex-start;
  background-color: #F2F2F2;
  position: relative; }
  .glide__slide.cinema a.cinema-link, .cinema a.cinema-link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; }
  .glide__slide.cinema .cinema-poster, .cinema .cinema-poster {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    height: 240px; }

.cinema-poster {
  min-width: 170px;
  height: 100%;
  position: relative; }
  .cinema-poster img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.cinema-info-wrapper {
  padding: 15px 50px 15px 20px;
  position: relative;
  background-color: #F2F2F2;
  flex-grow: 1; }
  .cinema-info-wrapper a {
    position: relative;
    z-index: 3; }
  .cinema-info-wrapper p {
    font-family: MontserratRegular;
    margin-bottom: 5px;
    line-height: 1.1em; }
    .cinema-info-wrapper p a {
      font-family: MontserratBold;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      .cinema-info-wrapper p a[href]:hover {
        color: #FFCD00; }
      .cinema-info-wrapper p a:not(:last-of-type)::after {
        content: ', '; }
  .cinema-info-wrapper .cinema-name {
    font-family: MontserratBold;
    font-size: 1.1em;
    padding-bottom: 10px; }
    .cinema-info-wrapper .cinema-name a span.name-eng {
      font-family: MontserratRegular;
      font-size: 0.9em; }
  .cinema-info-wrapper .cinema-duration {
    font-family: MontserratBold; }
  .cinema-info-wrapper .cinema-genre {
    font-family: MontserratRegular; }
    .cinema-info-wrapper .cinema-genre a {
      font-family: MontserratBold; }
      .cinema-info-wrapper .cinema-genre a:not(:last-of-type)::after {
        content: ','; }
  .cinema-info-wrapper .cinema-description {
    margin-top: 10px; }

/* CINEMA RATING */
.cinema-rating {
  position: absolute;
  top: 20px;
  right: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end; }
  .cinema-rating p {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    align-items: center; }
  .cinema-rating span {
    font-family: MontserratBold;
    font-size: 1.1em;
    margin-left: 10px; }
  .cinema-rating .icon {
    color: #FFCD00; }

.add-to-bookmark.active > .icon-bookmark-empty:before {
  content: '\e80b'; }

.glide__arrows button {
  border: none;
  box-shadow: none; }
  .glide__arrows button:hover > .icon {
    color: #222222;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

.glide__arrows .glide__arrow--left {
  left: -50px; }

.glide__arrows .glide__arrow--right {
  right: -50px; }

.glide__arrows .icon {
  font-size: 1.5em;
  color: #FFCD00;
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

.blocks-wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 30px; }

.block {
  width: calc(50% - 10px);
  position: relative; }
  .block img {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  .block a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
    .block a:hover + .block-img > img {
      transform: scale(1.1);
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
      filter: brightness(1.1); }
  .block .block-info {
    padding: 15px 35px 20px 20px;
    background-color: #F2F2F2;
    color: #222222; }
  .block .block-img {
    height: 300px;
    overflow: hidden; }
    .block .block-img .img-overlay {
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
  .block .block-heading {
    font-size: 1.1em;
    font-family: MontserratBold;
    margin-bottom: 5px; }
  .block.big {
    width: 100%;
    margin: 20px 0; }

.icon-star, .icon-star-empty {
  color: #FFCD00; }

h6.heading {
  font-family: MontserratBold;
  font-size: 1.1em; }

h5 {
  font-family: MontserratExtraBold;
  font-size: 1.1em; }

h4.aside-heading {
  text-transform: uppercase;
  font-size: 1.1em;
  color: #A3A3A4;
  font-family: MontserratExtraBold; }

/* PAGINATION */
.pagination {
  display: flex;
  justify-content: center; }
  .pagination .list-item {
    margin-right: 5px;
    background-color: #F2F2F2;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #222222;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .pagination .list-item:hover {
      color: #FFCD00; }
    .pagination .list-item:last-child {
      margin-right: 0px; }
    .pagination .list-item a {
      padding: 0;
      font-family: MontserratBold;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 100%; }
    .pagination .list-item.active {
      background-color: #303032;
      color: #FFCD00; }

/* TABS */
.tabs-btn-wrapper {
  display: flex; }
  .tabs-btn-wrapper .tab-btn {
    padding: 10px 40px;
    background-color: #F2F2F2;
    color: #303032;
    font-family: MontserratBold; }
    .tabs-btn-wrapper .tab-btn.active {
      color: white;
      background-color: #303032; }

.tabs-content {
  position: relative; }
  .tabs-content .tab {
    display: none; }
    .tabs-content .tab.active {
      display: block; }

.review-item {
  padding: 10px 20px;
  background-color: #F2F2F2;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #BCBCBC; }
  .review-item.comment {
    flex-wrap: nowrap; }
    .review-item.comment .reviewer-avatar {
      width: 50px;
      height: 50px;
      align-self: flex-start; }
    .review-item.comment .reviewer {
      width: calc(100% - 50px); }
  .review-item:last-of-type {
    border-bottom: none; }
  .review-item .reviewer-name {
    font-family: MontserratBold;
    margin-bottom: 5px;
    display: block; }
  .review-item .reviewer-rating {
    font-family: MontserratRegular;
    margin-bottom: 10px; }
  .review-item .icon-star, .review-item .icon-star-half-alt {
    color: #FFCD00; }

.reviewer-avatar {
  width: 70px;
  height: 70px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 50%;
  margin: 0 10px 10px 0;
  position: relative; }
  .reviewer-avatar img.pro {
    position: absolute;
    width: 25px;
    bottom: 0;
    left: 0;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    z-index: 1; }
  .reviewer-avatar a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2; }
    .reviewer-avatar a:hover ~ * {
      filter: brightness(1.2); }

.block-open {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0 20px;
  color: #A3A3A4;
  margin-top: 10px; }
  .block-open::after {
    content: '';
    display: block;
    border-style: solid;
    border-width: 6px 6px 0 6px;
    border-color: #A3A3A4 transparent transparent transparent;
    margin-left: 5px; }
  .block-open.active::after {
    border-width: 0px 6px 6px 6px;
    border-color: transparent transparent #A3A3A4 transparent; }

.account-wrapper {
  display: flex;
  align-items: center;
  color: white;
  font-family: MontserratBold;
  position: relative; }
  .account-wrapper::after {
    content: '\e803';
    font-family: 'dzygambd';
    transform: rotate(90deg);
    position: relative;
    top: 3px;
    margin-left: 5px; }
  .account-wrapper .account-icon {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 10px; }
  .account-wrapper:hover .account-list {
    opacity: 1;
    visibility: visible;
    overflow: visible; }
  .account-wrapper .account-list {
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
    width: 200px;
    opacity: 0;
    visibility: hidden;
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    background-color: #F2F2F2; }
    .account-wrapper .account-list .list-item {
      padding: 12px 8px;
      font-family: 'MontserratRegular';
      color: #222222;
      border-bottom: 1px solid #BCBCBC;
      text-align: right; }
      .account-wrapper .account-list .list-item:last-of-type {
        border-bottom: 0; }
      .account-wrapper .account-list .list-item a {
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        .account-wrapper .account-list .list-item a:hover {
          color: #FFCD00; }

a.link {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0; }

/* MOBILE BUTTON */
.btn-menu {
  width: 50px;
  height: 40px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: block; }
  .btn-menu span {
    display: block;
    position: absolute;
    height: 4px;
    width: 80%;
    background: #FFCD00;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out; }
    .btn-menu span:nth-of-type(1) {
      top: 0px; }
    .btn-menu span:nth-of-type(2), .btn-menu span:nth-of-type(3) {
      top: 18px; }
    .btn-menu span:nth-of-type(4) {
      top: 36px; }
  .btn-menu.active span {
    background-color: #FFCD00; }
    .btn-menu.active span:nth-of-type(1) {
      top: 18px;
      width: 0%;
      left: 50%; }
    .btn-menu.active span:nth-of-type(2) {
      transform: rotate(45deg); }
    .btn-menu.active span:nth-of-type(3) {
      transform: rotate(-45deg); }
    .btn-menu.active span:nth-of-type(4) {
      top: 18px;
      width: 0%;
      left: 50%; }

.img-wrapper {
  position: relative; }
  .img-wrapper img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; }

.filmography-list .list-item {
  display: flex;
  align-items: center;
  padding: 10px; }
  .filmography-list .list-item:nth-of-type(2n) {
    background-color: #F2F2F2; }
  .filmography-list .list-item .film {
    font-family: MontserratBold; }
    .filmography-list .list-item .film span {
      font-family: MontserratRegular; }
      .filmography-list .list-item .film span::before {
        content: '/';
        margin: 0 5px; }
  .filmography-list .list-item .role {
    flex-grow: 1;
    text-align: right;
    font-family: MontserratRegular; }
    .filmography-list .list-item .role span {
      font-family: MontserratBold; }
  .filmography-list .list-item .icon {
    margin-right: 15px; }

::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: white; }

::-moz-placeholder {
  /* Firefox 19+ */
  color: white; }

:-ms-input-placeholder {
  /* IE 10+ */
  color: white; }

:-moz-placeholder {
  /* Firefox 18- */
  color: white; }

.glide {
  position: relative;
  width: 100%;
  box-sizing: border-box; }
  .glide * {
    box-sizing: inherit; }
  .glide__track {
    overflow: hidden; }
  .glide__slides {
    position: relative;
    width: 100%;
    list-style: none;
    backface-visibility: hidden;
    transform-style: preserve-3d;
    touch-action: pan-Y;
    overflow: hidden;
    padding: 0;
    white-space: nowrap;
    display: flex;
    flex-wrap: nowrap;
    will-change: transform; }
    .glide__slides--dragging {
      user-select: none; }
  .glide__slide {
    width: 100%;
    height: 100%;
    flex-shrink: 0;
    white-space: normal;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent; }
    .glide__slide a {
      user-select: none;
      -webkit-user-drag: none;
      -moz-user-select: none;
      -ms-user-select: none; }
  .glide__arrows {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide__bullets {
    -webkit-touch-callout: none;
    user-select: none; }
  .glide--rtl {
    direction: rtl; }

.glide__arrow {
  position: absolute;
  display: block;
  top: 50%;
  z-index: 2;
  color: white;
  text-transform: uppercase;
  padding: 9px 12px;
  background-color: transparent;
  border: 2px solid rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  text-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.1);
  opacity: 1;
  cursor: pointer;
  transition: opacity 150ms ease, border 300ms ease-in-out;
  transform: translateY(-50%);
  line-height: 1; }
  .glide__arrow:focus {
    outline: none; }
  .glide__arrow:hover {
    border-color: white; }
  .glide__arrow--left {
    left: 2em; }
  .glide__arrow--right {
    right: 2em; }
  .glide__arrow--disabled {
    opacity: 0.33; }

.glide__bullets {
  position: absolute;
  z-index: 2;
  bottom: 2em;
  left: 50%;
  display: inline-flex;
  list-style: none;
  transform: translateX(-50%); }

.glide__bullet {
  background-color: rgba(255, 255, 255, 0.5);
  width: 9px;
  height: 9px;
  padding: 0;
  border-radius: 50%;
  border: 2px solid transparent;
  transition: all 300ms ease-in-out;
  cursor: pointer;
  line-height: 0;
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.1);
  margin: 0 0.25em; }
  .glide__bullet:focus {
    outline: none; }
  .glide__bullet:hover, .glide__bullet:focus {
    border: 2px solid white;
    background-color: rgba(255, 255, 255, 0.5); }
  .glide__bullet--active {
    background-color: white; }

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab; }

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing; }

/*!
 * baguetteBox.js
 * @author  feimosi
 * @version %%INJECT_VERSION%%
 * @url https://github.com/feimosi/baguetteBox.js
 */
#baguetteBox-overlay {
  display: none;
  opacity: 0;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000000;
  background-color: #222;
  background-color: rgba(0, 0, 0, 0.8);
  transition: opacity .5s ease; }
  #baguetteBox-overlay.visible {
    opacity: 1; }
  #baguetteBox-overlay .full-image {
    display: inline-block;
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center; }
    #baguetteBox-overlay .full-image figure {
      display: inline;
      margin: 0;
      height: 100%; }
    #baguetteBox-overlay .full-image img {
      display: inline-block;
      width: auto;
      height: auto;
      max-height: 100%;
      max-width: 100%;
      vertical-align: middle;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.6); }
    #baguetteBox-overlay .full-image figcaption {
      display: block;
      position: absolute;
      bottom: 0;
      width: 100%;
      text-align: center;
      line-height: 1.8;
      white-space: normal;
      color: #ccc;
      background-color: #000;
      background-color: rgba(0, 0, 0, 0.6);
      font-family: sans-serif; }
    #baguetteBox-overlay .full-image:before {
      content: "";
      display: inline-block;
      height: 50%;
      width: 1px;
      margin-right: -1px; }

#baguetteBox-slider {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  white-space: nowrap;
  transition: left .4s ease, transform .4s ease; }
  #baguetteBox-slider.bounce-from-right {
    animation: bounceFromRight .4s ease-out; }
  #baguetteBox-slider.bounce-from-left {
    animation: bounceFromLeft .4s ease-out; }

@keyframes bounceFromRight {
  0% {
    margin-left: 0; }
  50% {
    margin-left: -30px; }
  100% {
    margin-left: 0; } }

@keyframes bounceFromLeft {
  0% {
    margin-left: 0; }
  50% {
    margin-left: 30px; }
  100% {
    margin-left: 0; } }

.baguetteBox-button#next-button, .baguetteBox-button#previous-button {
  top: 50%;
  top: calc(50% - 30px);
  width: 44px;
  height: 60px; }

.baguetteBox-button {
  position: absolute;
  cursor: pointer;
  outline: none;
  padding: 0;
  margin: 0;
  border: 0;
  border-radius: 15%;
  background-color: #323232;
  background-color: rgba(50, 50, 50, 0.5);
  color: #ddd;
  font: 1.6em sans-serif;
  transition: background-color .4s ease; }
  .baguetteBox-button:focus, .baguetteBox-button:hover {
    background-color: rgba(50, 50, 50, 0.9); }
  .baguetteBox-button#next-button {
    right: 2%; }
  .baguetteBox-button#previous-button {
    left: 2%; }
  .baguetteBox-button#close-button {
    top: 20px;
    right: 2%;
    right: calc(2% + 6px);
    width: 30px;
    height: 30px; }
  .baguetteBox-button svg {
    position: absolute;
    left: 0;
    top: 0; }

/*
    Preloader
    Borrowed from http://tobiasahlin.com/spinkit/
*/
.baguetteBox-spinner {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -20px;
  margin-left: -20px; }

.baguetteBox-double-bounce1,
.baguetteBox-double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #fff;
  opacity: .6;
  position: absolute;
  top: 0;
  left: 0;
  animation: bounce 2s infinite ease-in-out; }

.baguetteBox-double-bounce2 {
  animation-delay: -1s; }

@keyframes bounce {
  0%, 100% {
    transform: scale(0); }
  50% {
    transform: scale(1); } }

@font-face {
  font-family: "DzygaMBD";
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/dzyga.ttf); }

@font-face {
  font-family: 'MontserratRegular';
  font-style: normal;
  font-weight: 400;
  src: url(../fonts/Montserrat-Regular.ttf); }

@font-face {
  font-family: 'MontserratMedium';
  font-style: normal;
  font-weight: 500;
  src: url(../fonts/Montserrat-Medium.ttf); }

@font-face {
  font-family: 'MontserratBold';
  font-style: normal;
  font-weight: 700;
  src: url(../fonts/Montserrat-Bold.ttf); }

@font-face {
  font-family: 'MontserratExtraBold';
  font-style: normal;
  font-weight: 800;
  src: url(../fonts/Montserrat-ExtraBold.ttf); }

@font-face {
  font-family: 'MontserratBlack';
  font-style: normal;
  font-weight: 900;
  src: url(../fonts/Montserrat-Black.ttf); }

html {
  height: 100%; }

body {
  padding: 0;
  margin: 0;
  font-family: 'MontserratMedium';
  overflow-x: hidden;
  position: relative;
  font-size: 13px;
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  color: #494949; }
  body.hide {
    overflow: hidden; }

img {
  width: 100%;
  display: block; }

/* HEADER */
.dzyga-header .main-navigation,
.dzyga-header .pro-menu,
.dzyga-header .top-navigation {
  position: relative; }
  .dzyga-header .main-navigation::before, .dzyga-header .main-navigation::after,
  .dzyga-header .pro-menu::before,
  .dzyga-header .pro-menu::after,
  .dzyga-header .top-navigation::before,
  .dzyga-header .top-navigation::after {
    content: '';
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: inherit;
    top: 0; }
  .dzyga-header .main-navigation::before,
  .dzyga-header .pro-menu::before,
  .dzyga-header .top-navigation::before {
    left: -100%; }
  .dzyga-header .main-navigation::after,
  .dzyga-header .pro-menu::after,
  .dzyga-header .top-navigation::after {
    right: -100%; }

.dzyga-header .top-navigation {
  background-color: #303032;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  .dzyga-header .top-navigation.fixed {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: auto;
    z-index: 5; }
  .dzyga-header .top-navigation .languages-list {
    padding-left: 15px; }
    .dzyga-header .top-navigation .languages-list .list-item {
      background-color: #303032; }
    .dzyga-header .top-navigation .languages-list::before {
      content: '\e800';
      font-family: "dzygambd";
      position: absolute;
      left: 0;
      top: 2px;
      color: #A3A3A4; }
    .dzyga-header .top-navigation .languages-list a {
      color: #A3A3A4; }
  .dzyga-header .top-navigation .navigation-list {
    display: flex;
    padding: 0 0; }
    .dzyga-header .top-navigation .navigation-list .list-item:not(:last-of-type) {
      margin-right: 50px; }
      .dzyga-header .top-navigation .navigation-list .list-item:not(:last-of-type) a {
        padding: 5px; }
    .dzyga-header .top-navigation .navigation-list .list-item a.active {
      color: #FFCD00;
      font-family: MontserratExtraBold; }
  .dzyga-header .top-navigation .social-list {
    display: flex; }
    .dzyga-header .top-navigation .social-list .list-item:not(:last-of-type) {
      margin-right: 20px; }
    .dzyga-header .top-navigation .social-list a {
      color: #FFCD00; }
  .dzyga-header .top-navigation a {
    color: white; }

.dzyga-header .main-navigation {
  background-color: #222222;
  position: relative;
  padding: 15px 20px 15px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap; }
  .dzyga-header .main-navigation .search-wrapper {
    display: flex;
    flex-grow: 1;
    position: relative;
    margin: 0 75px; }
    .dzyga-header .main-navigation .search-wrapper .search {
      width: 80%;
      border-right: 1px solid #222222; }
      .dzyga-header .main-navigation .search-wrapper .search input {
        width: 100%;
        height: 100%;
        padding: 10px;
        border-radius: 5px 0 0 5px; }
        .dzyga-header .main-navigation .search-wrapper .search input::-webkit-input-placeholder {
          /* Chrome/Opera/Safari */
          color: #A3A3A4; }
        .dzyga-header .main-navigation .search-wrapper .search input::-moz-placeholder {
          /* Firefox 19+ */
          color: #A3A3A4; }
        .dzyga-header .main-navigation .search-wrapper .search input:-ms-input-placeholder {
          /* IE 10+ */
          color: #A3A3A4; }
        .dzyga-header .main-navigation .search-wrapper .search input:-moz-placeholder {
          /* Firefox 18- */
          color: #A3A3A4; }
    .dzyga-header .main-navigation .search-wrapper .select {
      width: 20%; }
      .dzyga-header .main-navigation .search-wrapper .select select {
        width: 100%;
        height: 100%;
        border-radius: 0 5px 5px 0;
        background-color: white; }
    .dzyga-header .main-navigation .search-wrapper .link-search {
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      padding: 10px;
      background-color: #FFCD00;
      border-radius: 5px; }
  .dzyga-header .main-navigation.fixed {
    position: fixed;
    height: auto;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

.dzyga-header .pro-menu {
  background-color: #303032;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  position: relative;
  width: 100%;
  margin: 10px 0 0 0; }
  .dzyga-header .pro-menu > .list-item {
    padding: 10px; }
    .dzyga-header .pro-menu > .list-item.children {
      transition: all 0.3s ease-in-out; }
    .dzyga-header .pro-menu > .list-item::after {
      transition: all 0.3s ease-in-out; }
    .dzyga-header .pro-menu > .list-item svg path {
      transition: all 0.3s ease-in-out; }
  .dzyga-header .pro-menu .list-item.children:hover {
    color: #FFCD00; }
    .dzyga-header .pro-menu .list-item.children:hover svg path {
      fill: #FFCD00; }
    .dzyga-header .pro-menu .list-item.children:hover::after {
      border-color: #FFCD00 transparent transparent; }
    .dzyga-header .pro-menu .list-item.children:hover > .sub-menu {
      display: flex; }
  .dzyga-header .pro-menu .list-item,
  .dzyga-header .pro-menu a {
    display: flex;
    align-items: center;
    color: white;
    font-family: MontserratBold;
    cursor: pointer; }
    .dzyga-header .pro-menu .list-item .sub-menu,
    .dzyga-header .pro-menu a .sub-menu {
      position: absolute;
      width: calc(100% - 40px);
      top: 36px;
      left: 20px;
      display: none;
      flex-wrap: wrap;
      background-color: #303032;
      z-index: 5;
      color: white;
      padding: 15px 20px;
      border-top: 1px solid #222222; }
      .dzyga-header .pro-menu .list-item .sub-menu .list-item:last-of-type a,
      .dzyga-header .pro-menu a .sub-menu .list-item:last-of-type a {
        justify-content: center;
        border-radius: 5px;
        padding: 10px;
        background-color: #FFCD00;
        font-family: MontserratBold;
        width: 100%; }
        .dzyga-header .pro-menu .list-item .sub-menu .list-item:last-of-type a:hover,
        .dzyga-header .pro-menu a .sub-menu .list-item:last-of-type a:hover {
          color: #222222; }
      .dzyga-header .pro-menu .list-item .sub-menu .list-item a,
      .dzyga-header .pro-menu a .sub-menu .list-item a {
        font-family: MontserratRegular;
        font-size: 0.9em;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
        padding: 10px; }
        .dzyga-header .pro-menu .list-item .sub-menu .list-item a:hover,
        .dzyga-header .pro-menu a .sub-menu .list-item a:hover {
          color: #FFCD00; }
      .dzyga-header .pro-menu .list-item .sub-menu .list-item,
      .dzyga-header .pro-menu a .sub-menu .list-item {
        width: 12.5%;
        font-size: 0.9em; }
    .dzyga-header .pro-menu .list-item.children::after,
    .dzyga-header .pro-menu a.children::after {
      content: '';
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: white transparent transparent transparent;
      margin-left: 5px; }
    .dzyga-header .pro-menu .list-item svg,
    .dzyga-header .pro-menu a svg {
      margin-right: 5px;
      width: 15px;
      height: 15px; }
    .dzyga-header .pro-menu .list-item.active .sub-menu,
    .dzyga-header .pro-menu a.active .sub-menu {
      display: flex; }
  .dzyga-header .pro-menu::after {
    z-index: 2; }

.dzyga-header-mobile {
  display: none; }

.profile-moderation {
  background-color: #A4A6AB;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0;
  width: 100%; }
  .profile-moderation p {
    margin-left: 10px;
    color: white; }

/* MAIN STRUCTURE */
main {
  display: flex;
  margin: auto;
  flex-flow: wrap;
  flex-grow: 1;
  margin-top: 0; }
  main aside {
    padding-bottom: 50px; }
  main .dzyga-main {
    padding: 20px 20px 50px 0;
    width: calc(100% - 280px); }
    main .dzyga-main .search-result > p {
      margin-bottom: 20px; }
    main .dzyga-main .search-result .result-item {
      display: flex;
      margin-bottom: 20px;
      position: relative; }
      main .dzyga-main .search-result .result-item .result-link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
      main .dzyga-main .search-result .result-item .result-bg {
        width: 80px;
        height: 97px;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 20px; }
      main .dzyga-main .search-result .result-item .result-info {
        width: calc(100% - 100px); }
      main .dzyga-main .search-result .result-item .result-category {
        margin: 5px 0 10px 0; }
    main .dzyga-main section .section-heading {
      margin-top: 30px; }
    main .dzyga-main.more-details-section {
      width: 100%;
      margin: 0 auto; }
      main .dzyga-main.more-details-section table {
        margin: 15px 0; }
      main .dzyga-main.more-details-section table,
      main .dzyga-main.more-details-section td,
      main .dzyga-main.more-details-section th {
        border: 1px solid #BCBCBC;
        border-collapse: collapse; }
        main .dzyga-main.more-details-section table thead,
        main .dzyga-main.more-details-section td thead,
        main .dzyga-main.more-details-section th thead {
          background-color: #303032;
          color: #FFCD00; }
          main .dzyga-main.more-details-section table thead th,
          main .dzyga-main.more-details-section td thead th,
          main .dzyga-main.more-details-section th thead th {
            padding: 10px; }
        main .dzyga-main.more-details-section table tbody td,
        main .dzyga-main.more-details-section td tbody td,
        main .dzyga-main.more-details-section th tbody td {
          padding: 5px;
          background-color: #F2F2F2; }
      main .dzyga-main.more-details-section .btn-register {
        padding: 10px;
        background-color: #303032;
        color: #FFCD00;
        max-width: 200px;
        text-align: center;
        margin-left: auto;
        display: block;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        main .dzyga-main.more-details-section .btn-register:hover {
          color: white; }
    main .dzyga-main.more-details-faq {
      width: calc(100% - 280px);
      margin: 0 auto; }
      main .dzyga-main.more-details-faq .faq-content {
        overflow: hidden;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        main .dzyga-main.more-details-faq .faq-content p a {
          color: #FFCD00; }
  main aside {
    background-color: #F2F2F2;
    width: 280px;
    margin-top: 20px; }
    main aside.home {
      margin-top: 0; }

/* ASIDE */
aside .aside-heading {
  background-color: #303032;
  padding: 10px;
  width: 100%; }

aside .review-wrapper {
  color: #222222; }
  aside .review-wrapper .review-item {
    padding: 10px 10px 15px 10px;
    border-bottom: 1px solid #BCBCBC;
    position: relative; }
    aside .review-wrapper .review-item:last-of-type {
      border-bottom: none; }
    aside .review-wrapper .review-item .reviewer {
      display: flex; }
      aside .review-wrapper .review-item .reviewer .reviewer-icon {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin: 0 15px 10px 0; }
    aside .review-wrapper .review-item .reviewer-info {
      line-height: 1.5em; }
      aside .review-wrapper .review-item .reviewer-info a {
        font-family: MontserratBold;
        position: relative;
        z-index: 2;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        aside .review-wrapper .review-item .reviewer-info a:hover {
          color: #FFCD00;
          transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      aside .review-wrapper .review-item .reviewer-info .rating {
        display: flex;
        align-items: center; }
        aside .review-wrapper .review-item .reviewer-info .rating .icon:first-child {
          margin-left: 5px; }
    aside .review-wrapper .review-item .review-link {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 1; }

aside .aside-ad {
  height: 560px;
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin: 20px 0; }

aside .box-office {
  margin-bottom: 20px; }
  aside .box-office .list-item {
    display: flex;
    justify-content: space-between;
    font-family: MontserratBold;
    color: #303032;
    padding: 10px;
    background-color: #F2F2F2; }
    aside .box-office .list-item:nth-of-type(2n) {
      background-color: #E6E6E6; }
    aside .box-office .list-item a {
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      aside .box-office .list-item a:hover {
        color: #FFCD00; }
    aside .box-office .list-item .icon-up-big {
      color: #FFCD00; }

aside .persons-wrapper {
  display: flex;
  flex-wrap: wrap;
  padding: 20px 10px;
  justify-content: space-between; }

aside .person {
  width: calc(50% - 5px);
  position: relative;
  margin-bottom: 10px; }
  aside .person .person-photo {
    width: 100%;
    height: 150px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    margin-bottom: 10px; }
  aside .person .person-name {
    font-family: MontserratBold;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  aside .person .person-proffesion {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    font-family: MontserratRegular; }
  aside .person a {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 3; }
    aside .person a:hover ~ * {
      color: #FFCD00; }

aside .premier-wrapper {
  display: flex;
  flex-direction: column;
  padding: 10px 10px 0px 10px;
  margin-bottom: 20px; }
  aside .premier-wrapper .premier-film {
    background-color: #e6e6e6;
    position: relative;
    display: flex;
    margin-bottom: 10px; }
    aside .premier-wrapper .premier-film:last-child {
      margin-bottom: 0; }
    aside .premier-wrapper .premier-film .premier-film-photo {
      height: 110px;
      width: 90px;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover; }
    aside .premier-wrapper .premier-film .premier-film-desc {
      display: flex;
      flex-direction: column;
      padding: 10px 15px;
      font-size: 12px;
      line-height: normal;
      color: #222222;
      flex-grow: 1;
      width: calc(100% - 110px); }
    aside .premier-wrapper .premier-film .premier-film-name {
      font-style: normal;
      font-weight: 700;
      font-family: 'MontserratBold', sans-serif;
      word-break: break-word; }
    aside .premier-wrapper .premier-film .premier-film-date {
      font-family: 'MontserratRegular', sans-serif;
      font-weight: 400; }
    aside .premier-wrapper .premier-film a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }

aside.aside-profile-edit {
  height: 301px;
  padding-bottom: 0; }

aside .account-list .list-item {
  border-bottom: 1px solid #BCBCBC; }
  aside .account-list .list-item:last-of-type {
    border-bottom: none; }
  aside .account-list .list-item.active {
    background-color: #303032;
    color: white; }
  aside .account-list .list-item a {
    padding: 10px;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    display: block; }
    aside .account-list .list-item a:hover {
      color: #FFCD00; }

/* SECTION PREMIERE */
.dzyga-premiere {
  width: 100%;
  background-color: #F2F2F2;
  padding: 10px 0 15px;
  position: relative;
  overflow: visible; }
  .dzyga-premiere::before, .dzyga-premiere::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: inherit;
    top: 0; }
  .dzyga-premiere::before {
    left: -100%; }
  .dzyga-premiere::after {
    right: -100%; }
  .dzyga-premiere .section-heading {
    display: inline-block;
    transform: rotate(-90deg);
    transform-origin: left bottom 0;
    position: absolute;
    left: 72px; }
  .dzyga-premiere .premiere-slider {
    margin: 0 100px;
    width: calc(100% - 200px); }
    .dzyga-premiere .premiere-slider .glide__slide {
      box-shadow: 0px 0px 15px rgba(23, 23, 24, 0.2);
      position: relative;
      margin-top: 10px;
      margin-bottom: 10px; }
      .dzyga-premiere .premiere-slider .glide__slide .movie-poster {
        height: 434px;
        background-position: center;
        background-size: cover; }
      .dzyga-premiere .premiere-slider .glide__slide a {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0; }
      .dzyga-premiere .premiere-slider .glide__slide img {
        height: 430px; }
      .dzyga-premiere .premiere-slider .glide__slide .movie-footer {
        background-color: white;
        padding: 12px 15px; }
        .dzyga-premiere .premiere-slider .glide__slide .movie-footer .movie-name {
          font-family: MontserratBold;
          margin-bottom: 3px; }
        .dzyga-premiere .premiere-slider .glide__slide .movie-footer .movie-date {
          font-family: MontserratRegular;
          font-size: 12px;
          text-transform: lowercase; }

/* SECTION CINEMA */
.dzyga-cinema .ad-wrapper {
  margin-top: 0;
  height: 225px; }

.dzyga-cinema .cinema-slider {
  margin: auto;
  width: calc(100% - 60px); }

/* SECTION LAUREATES */
.laureats-slider {
  width: calc(100% - 60px);
  margin: auto; }
  .laureats-slider .glide__slide {
    position: relative; }
    .laureats-slider .glide__slide a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
    .laureats-slider .glide__slide .movie-poster {
      height: 280px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat; }
    .laureats-slider .glide__slide .movie-footer {
      background-color: #F2F2F2;
      padding: 10px;
      height: 70px;
      max-height: 70px; }
      .laureats-slider .glide__slide .movie-footer .cinema-rating {
        position: relative;
        top: initial;
        right: initial;
        display: inline-flex;
        justify-content: flex-start;
        flex-wrap: nowrap;
        align-items: center; }
        .laureats-slider .glide__slide .movie-footer .cinema-rating p {
          justify-content: flex-start; }
    .laureats-slider .glide__slide .movie-name {
      font-family: MontserratBold;
      font-size: 0.9em;
      margin-bottom: 5px; }
    .laureats-slider .glide__slide .rating {
      font-size: 0.9em;
      display: flex;
      align-items: center; }
      .laureats-slider .glide__slide .rating .icon {
        color: #FFCD00;
        font-size: 0.9em; }

/* SECTION FAQ */
.dzyga-faq {
  position: relative;
  background-color: #F2F2F2;
  color: #222222;
  padding: 30px 0;
  flex-shrink: 0; }
  .dzyga-faq .faq-heading {
    display: flex;
    align-items: center;
    padding: 0;
    background-color: inherit;
    cursor: pointer;
    margin-bottom: 10px; }
    .dzyga-faq .faq-heading::after {
      display: none; }
    .dzyga-faq .faq-heading a {
      margin-left: 10px;
      width: 18px;
      height: 18px;
      background-color: #222222;
      border-radius: 50%;
      position: relative;
      display: block;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      .dzyga-faq .faq-heading a::before {
        content: '';
        display: block;
        width: 10px;
        height: 3px;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%); }
      .dzyga-faq .faq-heading a::after {
        content: '';
        display: block;
        width: 3px;
        height: 10px;
        background-color: white;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
        opacity: 1; }
    .dzyga-faq .faq-heading.active a {
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
      transform: rotate(360deg);
      background-color: #FFCD00; }
      .dzyga-faq .faq-heading.active a::after {
        opacity: 0; }

.block-content {
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  .block-content p {
    margin-bottom: 15px; }
  .block-content.active {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

/* SECTION BOTTOM NAVIGATION */
.dzyga-bottom-navigation {
  background-color: #222222;
  padding: 30px 0; }
  .dzyga-bottom-navigation .container {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap; }
    .dzyga-bottom-navigation .container .footer-info {
      flex-grow: 1;
      width: 100%; }
      .dzyga-bottom-navigation .container .footer-info a {
        color: #FFCD00;
        font-family: MontserratBold; }
    .dzyga-bottom-navigation .container .heading {
      background-color: transparent;
      color: white;
      text-align: left;
      width: 100%; }
    .dzyga-bottom-navigation .container form {
      width: 100%;
      flex-grow: 1;
      display: flex;
      justify-content: space-around;
      align-items: flex-start; }
      .dzyga-bottom-navigation .container form .input-wrapper {
        display: flex;
        flex-direction: column; }
      .dzyga-bottom-navigation .container form input {
        margin-bottom: 15px; }
      .dzyga-bottom-navigation .container form textarea {
        resize: none;
        height: 150px; }
  .dzyga-bottom-navigation .logo-ling {
    margin-right: 110px; }
  .dzyga-bottom-navigation .logo {
    fill: #303032; }
  .dzyga-bottom-navigation .bottom-nav-list {
    flex-grow: 1; }
  .dzyga-bottom-navigation .bottom-nav-list .list-item {
    font-size: 1.2em;
    color: #FFCD00;
    font-family: MontserratBold;
    margin-bottom: 15px; }
    .dzyga-bottom-navigation .bottom-nav-list .list-item .sub-list {
      margin-top: 15px; }
      .dzyga-bottom-navigation .bottom-nav-list .list-item .sub-list .list-item {
        font-size: 0.8em;
        margin-bottom: 10px;
        font-family: MontserratRegular; }

/* SECTION FILMS */
.cinema-wrapper .tabs-btn-wrapper {
  margin-bottom: 20px; }

.cinema-wrapper .tab {
  position: absolute;
  opacity: 0;
  top: 0;
  left: 0;
  width: 100%;
  display: block; }
  .cinema-wrapper .tab.active.temp {
    display: block;
    position: relative;
    opacity: 1; }
  .cinema-wrapper .tab.temp {
    position: relative;
    opacity: 0;
    display: none; }

.cinema-wrapper .cinema {
  margin-bottom: 20px;
  height: auto; }
  .cinema-wrapper .cinema .cinema-poster {
    height: 240px; }

/* MONTH SLIDER */
.month-slider,
.month {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 0;
  margin: 0 50px 20px 85px;
  font-size: 0.9em;
  background-color: #F2F2F2;
  width: calc(100% - 110px);
  position: relative; }
  .month-slider::before, .month-slider::after,
  .month::before,
  .month::after {
    content: '';
    width: 25px;
    height: 100%;
    background-color: inherit;
    position: absolute; }
  .month-slider::before,
  .month::before {
    left: -85px;
    width: 85px; }
  .month-slider::after,
  .month::after {
    right: -30px;
    width: 60px; }
  .month-slider .all-month,
  .month .all-month {
    text-align: center;
    position: absolute;
    left: -70px; }
  .month-slider .glide__track,
  .month .glide__track {
    margin: auto; }
  .month-slider a,
  .month a {
    font-family: MontserratBold;
    font-size: 0.9em; }
  .month-slider .list-item,
  .month .list-item {
    text-align: center;
    transition: all .3s ease-in-out;
    color: #A3A3A4;
    margin-right: 20px; }
    .month-slider .list-item:hover,
    .month .list-item:hover {
      color: #222222; }
  .month-slider a.active,
  .month a.active {
    color: #222222;
    font-family: MontserratExtraBold; }
  .month-slider .glide__arrows .icon,
  .month .glide__arrows .icon {
    color: #303032;
    font-size: 0.9em;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .month-slider .glide__arrows .icon:hover,
    .month .glide__arrows .icon:hover {
      color: #FFCD00; }
  .month-slider .glide__arrows .glide__arrow--left,
  .month .glide__arrows .glide__arrow--left {
    left: -20px;
    padding: 0; }
  .month-slider .glide__arrows .glide__arrow--right,
  .month .glide__arrows .glide__arrow--right {
    right: -10px;
    padding: 0; }

/* SECTION SINGLE FILM*/
.single-cinema .cinema-preview {
  background-color: #303032;
  padding: 15px;
  color: white;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .single-cinema .cinema-preview .cinema-info {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 15px;
    width: 100%; }
    .single-cinema .cinema-preview .cinema-info .cinema-name {
      width: 100%;
      font-family: MontserratBold, sans-serif;
      margin-bottom: 15px;
      font-size: 1.2em; }
      .single-cinema .cinema-preview .cinema-info .cinema-name .name-eng {
        font-family: MontserratRegular; }
        .single-cinema .cinema-preview .cinema-info .cinema-name .name-eng::before {
          content: '/';
          margin: 0 5px; }
    .single-cinema .cinema-preview .cinema-info .cinema-production {
      margin-right: 25px; }
    .single-cinema .cinema-preview .cinema-info .cinema-status {
      border-left: 1px solid #FFCD00;
      padding: 0 20px; }
    .single-cinema .cinema-preview .cinema-info .cinema-rating {
      top: 0; }
  .single-cinema .cinema-preview .cinema-poster {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    height: 380px;
    width: 30%;
    margin-right: 15px; }
  .single-cinema .cinema-preview .cinema-video {
    flex-grow: 1; }

.single-cinema .cinema-full-information {
  display: flex;
  background-color: white;
  flex-wrap: wrap; }
  .single-cinema .cinema-full-information .cinema-info-wrapper {
    background-color: inherit;
    width: 55%; }
  .single-cinema .cinema-full-information .cinema-production {
    width: 45%;
    background-color: #F2F2F2; }
  .single-cinema .cinema-full-information .cinema-description {
    width: 100%;
    margin-top: 30px;
    padding: 0 20px; }

.single-cinema .cinema-reactions-wrapper {
  margin-top: 15px; }
  .single-cinema .cinema-reactions-wrapper .tab {
    background-color: #f2f2f2;
    padding: 10px 0; }

.single-cinema .cinema-frames {
  background-color: #F2F2F2;
  padding: 10px 20px;
  margin: 20px 0; }
  .single-cinema .cinema-frames .heading {
    background-color: inherit;
    font-size: 1.1em; }
  .single-cinema .cinema-frames .frames-slider {
    width: calc(100% - 80px);
    margin: 15px auto 15px auto; }
    .single-cinema .cinema-frames .frames-slider .glide__slide {
      min-height: 110px;
      height: 110px;
      background-position: center;
      background-size: cover;
      position: relative; }
      .single-cinema .cinema-frames .frames-slider .glide__slide a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }

.single-cinema .cinema-video .heading {
  background-color: inherit;
  font-size: 1.1em;
  padding: 0 20px; }

.single-cinema .cinema-video .video-slider {
  width: calc(100% - 80px);
  margin: 15px auto 15px auto; }
  .single-cinema .cinema-video .video-slider .video {
    width: 100%; }

.single-cinema .cinema-reactions-wrapper .enter-warning {
  padding: 15px;
  background-color: #A3A3A4;
  text-align: center; }
  .single-cinema .cinema-reactions-wrapper .enter-warning a {
    font-family: MontserratBold; }

.single-cinema .cinema-reactions-wrapper .comment-form {
  padding: 40px 15px 15px 15px;
  color: #414046;
  border-bottom: 1px solid #bcbcbc;
  position: relative; }
  .single-cinema .cinema-reactions-wrapper .comment-form .reviewer-rating {
    margin-bottom: 10px; }
    .single-cinema .cinema-reactions-wrapper .comment-form .reviewer-rating .icon {
      cursor: pointer; }
  .single-cinema .cinema-reactions-wrapper .comment-form input[type=radio] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden; }
  .single-cinema .cinema-reactions-wrapper .comment-form textarea {
    background-color: white;
    color: #414046; }
  .single-cinema .cinema-reactions-wrapper .comment-form .btn {
    border-radius: 0px;
    background-color: #303032;
    color: white;
    margin-left: auto;
    margin-top: 15px;
    padding: 15px 40px;
    display: block; }

.dzyga-news-cinema .heading {
  margin: 30px 0 15px 0; }

/* REVIEWS */
.reviews-wrapper .title-wrapper {
  font-size: 1.2em; }

.reviews-wrapper .cinema {
  height: auto;
  margin-top: 20px; }
  .reviews-wrapper .cinema .cinema-poster {
    height: 255px;
    width: 180px; }
  .reviews-wrapper .cinema .cinema-info-wrapper {
    display: block; }
    .reviews-wrapper .cinema .cinema-info-wrapper .reviewer {
      display: flex;
      align-items: center; }
    .reviews-wrapper .cinema .cinema-info-wrapper .cinema-name {
      width: 100%;
      display: block;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      .reviews-wrapper .cinema .cinema-info-wrapper .cinema-name:hover {
        color: #FFCD00; }
      .reviews-wrapper .cinema .cinema-info-wrapper .cinema-name span {
        font-family: MontserratRegular;
        font-size: 0.9em; }
    .reviews-wrapper .cinema .cinema-info-wrapper .reviewer-name {
      font-family: MontserratBold;
      margin-bottom: 5px;
      display: block; }

.reviews-wrapper .review-item {
  padding: 0;
  border-bottom: none; }

.reviews-wrapper .pagination {
  margin-top: 20px; }

/* SECTION SINGLE PERSON */
.person a {
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  .person a:hover {
    color: #FFCD00; }

.person .person-info-wrapper {
  display: flex; }
  .person .person-info-wrapper .person-photo {
    width: 280px;
    height: 340px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .person .person-info-wrapper .person-information {
    width: calc(100% - 280px);
    padding-left: 20px; }
    .person .person-info-wrapper .person-information .main-info {
      width: 55%; }
    .person .person-info-wrapper .person-information .name {
      font-size: 1.4em;
      font-family: MontserratExtraBold; }
    .person .person-info-wrapper .person-information .profession {
      font-family: MontserratBold;
      margin-bottom: 10px; }
      .person .person-info-wrapper .person-information .profession a:not(:last-of-type)::after {
        content: ','; }
    .person .person-info-wrapper .person-information .characteristics-list {
      margin: 20px 0;
      font-family: MontserratRegular; }
      .person .person-info-wrapper .person-information .characteristics-list span {
        font-family: MontserratBold;
        margin-left: 5px; }
    .person .person-info-wrapper .person-information .biography {
      margin-bottom: 15px; }
    .person .person-info-wrapper .person-information .awards-list span {
      font-family: MontserratBold; }

.person .filmography {
  margin: 30px 0; }

.person .video {
  margin-bottom: 20px; }
  .person .video .video-wrapper {
    height: 480px; }

.person .heading {
  margin-bottom: 10px; }

.person .photos {
  margin-top: 15px; }
  .person .photos .frames-slider {
    width: calc(100% - 80px);
    margin: 15px auto; }
  .person .photos .glide__slide.cinema {
    height: 115px;
    min-height: 115px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat; }

/* FOOTER */
footer {
  background-color: #303032; }
  footer p {
    padding: 20px 0;
    text-align: center;
    color: #A3A3A4; }

/* PRO VERSION */
.notifications-list .list-item {
  background-color: #F2F2F2;
  margin-bottom: 10px;
  display: flex;
  align-items: center; }
  .notifications-list .list-item:last-of-type {
    margin-bottom: 0; }
  .notifications-list .list-item span {
    font-family: 'MontserratBold'; }
  .notifications-list .list-item .project-stage {
    margin-right: 30px; }
  .notifications-list .list-item .project-role {
    margin-right: 15px; }
  .notifications-list .list-item svg {
    height: 40px;
    width: 40px;
    background-color: #222222;
    padding: 8px;
    margin-right: 30px; }
  .notifications-list .list-item .project-name {
    font-family: 'MontserratBold'; }
    .notifications-list .list-item .project-name::after {
      content: '-';
      margin: 0 5px; }

/* PRO CASTINGS */
.pro-version {
  flex-direction: row-reverse; }
  .pro-version .dzyga-main {
    padding: 20px 0 50px 20px;
    display: block; }
  .pro-version .project-item,
  .pro-version .company-item,
  .pro-version .person-item {
    display: flex;
    align-items: flex-start;
    position: relative;
    flex-wrap: wrap;
    margin-bottom: 20px; }
    .pro-version .project-item .project-img,
    .pro-version .project-item .company-img,
    .pro-version .company-item .project-img,
    .pro-version .company-item .company-img,
    .pro-version .person-item .project-img,
    .pro-version .person-item .company-img {
      width: 120px;
      position: relative; }
    .pro-version .project-item .info-wrapper,
    .pro-version .company-item .info-wrapper,
    .pro-version .person-item .info-wrapper {
      width: calc(100% - 120px);
      height: 100%; }
    .pro-version .project-item .info-content,
    .pro-version .company-item .info-content,
    .pro-version .person-item .info-content {
      padding: 10px 15px;
      background-color: #F2F2F2;
      height: 170px; }
      .pro-version .project-item .info-content p,
      .pro-version .company-item .info-content p,
      .pro-version .person-item .info-content p {
        margin-bottom: 5px; }
        .pro-version .project-item .info-content p a,
        .pro-version .company-item .info-content p a,
        .pro-version .person-item .info-content p a {
          font-family: MontserratBold;
          margin-left: 5px; }
          .pro-version .project-item .info-content p a:not(:last-child)::after,
          .pro-version .company-item .info-content p a:not(:last-child)::after,
          .pro-version .person-item .info-content p a:not(:last-child)::after {
            content: ','; }
          .pro-version .project-item .info-content p a:first-child,
          .pro-version .company-item .info-content p a:first-child,
          .pro-version .person-item .info-content p a:first-child {
            margin-left: 0; }
          .pro-version .project-item .info-content p a:first-child:not(:only-child),
          .pro-version .company-item .info-content p a:first-child:not(:only-child),
          .pro-version .person-item .info-content p a:first-child:not(:only-child) {
            margin-left: 0; }
        .pro-version .project-item .info-content p a.add-to-bookmark:not(:last-child)::after,
        .pro-version .company-item .info-content p a.add-to-bookmark:not(:last-child)::after,
        .pro-version .person-item .info-content p a.add-to-bookmark:not(:last-child)::after {
          content: ''; }
    .pro-version .project-item .name,
    .pro-version .company-item .name,
    .pro-version .person-item .name {
      margin-bottom: 10px;
      font-size: 1.2em; }
    .pro-version .project-item .project-castings-list,
    .pro-version .company-item .project-castings-list,
    .pro-version .person-item .project-castings-list {
      position: relative;
      margin-top: 10px; }
      .pro-version .project-item .project-castings-list .list-item,
      .pro-version .company-item .project-castings-list .list-item,
      .pro-version .person-item .project-castings-list .list-item {
        background-color: #F2F2F2;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 5px;
        padding-left: 20px; }
        .pro-version .project-item .project-castings-list .list-item:last-of-type,
        .pro-version .company-item .project-castings-list .list-item:last-of-type,
        .pro-version .person-item .project-castings-list .list-item:last-of-type {
          margin-bottom: 0; }
        .pro-version .project-item .project-castings-list .list-item span,
        .pro-version .company-item .project-castings-list .list-item span,
        .pro-version .person-item .project-castings-list .list-item span {
          font-family: MontserratBold;
          margin-right: 10px; }
        .pro-version .project-item .project-castings-list .list-item .project-apply,
        .pro-version .company-item .project-castings-list .list-item .project-apply,
        .pro-version .person-item .project-castings-list .list-item .project-apply {
          padding: 10px 30px;
          background-color: #303032;
          color: white;
          transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
          .pro-version .project-item .project-castings-list .list-item .project-apply.active,
          .pro-version .company-item .project-castings-list .list-item .project-apply.active,
          .pro-version .person-item .project-castings-list .list-item .project-apply.active {
            background-color: #FFCD00;
            color: #222222; }
            .pro-version .project-item .project-castings-list .list-item .project-apply.active:hover,
            .pro-version .company-item .project-castings-list .list-item .project-apply.active:hover,
            .pro-version .person-item .project-castings-list .list-item .project-apply.active:hover {
              color: white; }
          .pro-version .project-item .project-castings-list .list-item .project-apply:hover,
          .pro-version .company-item .project-castings-list .list-item .project-apply:hover,
          .pro-version .person-item .project-castings-list .list-item .project-apply:hover {
            color: #FFCD00; }
    .pro-version .project-item .link-more,
    .pro-version .company-item .link-more,
    .pro-version .person-item .link-more {
      color: #222222;
      font-family: MontserratBold;
      font-size: 1.2em;
      margin-top: 15px;
      display: block; }
  .pro-version .company-item,
  .pro-version .person-item,
  .pro-version .project-item {
    height: 150px;
    background-color: #F2F2F2; }
    .pro-version .company-item .img,
    .pro-version .person-item .img,
    .pro-version .project-item .img {
      width: 150px;
      min-width: 150px;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      position: relative; }
      .pro-version .company-item .img a,
      .pro-version .person-item .img a,
      .pro-version .project-item .img a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
    .pro-version .company-item .info-content,
    .pro-version .person-item .info-content,
    .pro-version .project-item .info-content {
      height: 100%;
      padding: 10px 15px;
      flex-grow: 1;
      height: 100%; }
    .pro-version .company-item .name,
    .pro-version .person-item .name,
    .pro-version .project-item .name {
      margin-bottom: 5px;
      font-family: MontserratExtraBold;
      display: block; }
    .pro-version .company-item .contacts-list,
    .pro-version .person-item .contacts-list,
    .pro-version .project-item .contacts-list {
      margin-top: 10px;
      display: flex;
      flex-wrap: wrap;
      max-width: 80%; }
      .pro-version .company-item .contacts-list .list-item,
      .pro-version .person-item .contacts-list .list-item,
      .pro-version .project-item .contacts-list .list-item {
        margin: 0 30px 5px 0; }
        .pro-version .company-item .contacts-list .list-item.social,
        .pro-version .person-item .contacts-list .list-item.social,
        .pro-version .project-item .contacts-list .list-item.social {
          margin: 0 15px 5px 0; }
      .pro-version .company-item .contacts-list a,
      .pro-version .person-item .contacts-list a,
      .pro-version .project-item .contacts-list a {
        display: block;
        margin-bottom: 5px;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        .pro-version .company-item .contacts-list a:last-of-type,
        .pro-version .person-item .contacts-list a:last-of-type,
        .pro-version .project-item .contacts-list a:last-of-type {
          margin-bottom: 0; }
        .pro-version .company-item .contacts-list a:hover,
        .pro-version .person-item .contacts-list a:hover,
        .pro-version .project-item .contacts-list a:hover {
          color: #FFCD00; }
  .pro-version .person-item,
  .pro-version .project-item {
    height: 220px;
    background-color: #F2F2F2;
    flex-wrap: nowrap; }
    .pro-version .person-item .img,
    .pro-version .project-item .img {
      width: 180px;
      flex-shrink: 0; }
    .pro-version .person-item a,
    .pro-version .project-item a {
      font-family: MontserratBold;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      .pro-version .person-item a:hover,
      .pro-version .project-item a:hover {
        color: #FFCD00; }
    .pro-version .person-item p,
    .pro-version .project-item p {
      font-family: MontserratBold; }
      .pro-version .person-item p span,
      .pro-version .project-item p span {
        font-family: MontserratRegular; }
      .pro-version .person-item p.age,
      .pro-version .project-item p.age {
        margin: 15px 0 10px 0; }
      .pro-version .person-item p.last-projects, .pro-version .person-item p.project-actors,
      .pro-version .project-item p.last-projects,
      .pro-version .project-item p.project-actors {
        display: flex;
        flex-wrap: wrap; }
        .pro-version .person-item p.last-projects a, .pro-version .person-item p.project-actors a,
        .pro-version .project-item p.last-projects a,
        .pro-version .project-item p.project-actors a {
          margin-left: 0; }
          .pro-version .person-item p.last-projects a::after, .pro-version .person-item p.project-actors a::after,
          .pro-version .project-item p.last-projects a::after,
          .pro-version .project-item p.project-actors a::after {
            margin-right: 5px; }
        .pro-version .person-item p.last-projects .heading, .pro-version .person-item p.project-actors .heading,
        .pro-version .project-item p.last-projects .heading,
        .pro-version .project-item p.project-actors .heading {
          width: 100%;
          margin-top: 15px;
          background-color: inherit;
          font-size: 1em;
          padding: 0; }
  .pro-version .project-item {
    height: 220px;
    min-height: 220px;
    align-items: flex-start; }
    .pro-version .project-item .img {
      min-width: 170px;
      width: 170px;
      position: relative; }
      .pro-version .project-item .img img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; }
  .pro-version .castings .project-item {
    height: auto;
    background-color: white; }
    .pro-version .castings .project-item .info-content {
      height: 170px; }
  .pro-version.profile, .pro-version.castings {
    flex-direction: row; }
    .pro-version.profile .dzyga-main, .pro-version.castings .dzyga-main {
      padding: 20px 20px 50px 0;
      display: block; }
      .pro-version.profile .dzyga-main .heading-wrapper, .pro-version.castings .dzyga-main .heading-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: flex-start; }
      .pro-version.profile .dzyga-main .castings, .pro-version.castings .dzyga-main .castings {
        width: 100%; }
      .pro-version.profile .dzyga-main .add-company, .pro-version.castings .dzyga-main .add-company {
        padding: 10px 0;
        width: 165px;
        text-align: center;
        border-radius: 5px;
        background-color: #FFCD00;
        align-self: center;
        margin: 0 15px 0 0;
        font-family: MontserratBold;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        .pro-version.profile .dzyga-main .add-company:hover, .pro-version.castings .dzyga-main .add-company:hover {
          color: white; }
      .pro-version.profile .dzyga-main .company-item,
      .pro-version.profile .dzyga-main .person-item,
      .pro-version.profile .dzyga-main .project-item, .pro-version.castings .dzyga-main .company-item,
      .pro-version.castings .dzyga-main .person-item,
      .pro-version.castings .dzyga-main .project-item {
        flex-wrap: nowrap; }
        .pro-version.profile .dzyga-main .company-item .contacts-list,
        .pro-version.profile .dzyga-main .person-item .contacts-list,
        .pro-version.profile .dzyga-main .project-item .contacts-list, .pro-version.castings .dzyga-main .company-item .contacts-list,
        .pro-version.castings .dzyga-main .person-item .contacts-list,
        .pro-version.castings .dzyga-main .project-item .contacts-list {
          max-width: 100%; }
        .pro-version.profile .dzyga-main .company-item .item-nav-links,
        .pro-version.profile .dzyga-main .person-item .item-nav-links,
        .pro-version.profile .dzyga-main .project-item .item-nav-links, .pro-version.castings .dzyga-main .company-item .item-nav-links,
        .pro-version.castings .dzyga-main .person-item .item-nav-links,
        .pro-version.castings .dzyga-main .project-item .item-nav-links {
          padding: 10px 15px; }
          .pro-version.profile .dzyga-main .company-item .item-nav-links a,
          .pro-version.profile .dzyga-main .person-item .item-nav-links a,
          .pro-version.profile .dzyga-main .project-item .item-nav-links a, .pro-version.castings .dzyga-main .company-item .item-nav-links a,
          .pro-version.castings .dzyga-main .person-item .item-nav-links a,
          .pro-version.castings .dzyga-main .project-item .item-nav-links a {
            display: block;
            padding: 10px 35px;
            border-radius: 5px;
            border: 1px solid #BCBCBC;
            margin-bottom: 15px;
            color: white;
            font-family: MontserratBold;
            background-color: #303032; }
            .pro-version.profile .dzyga-main .company-item .item-nav-links a:last-of-type,
            .pro-version.profile .dzyga-main .person-item .item-nav-links a:last-of-type,
            .pro-version.profile .dzyga-main .project-item .item-nav-links a:last-of-type, .pro-version.castings .dzyga-main .company-item .item-nav-links a:last-of-type,
            .pro-version.castings .dzyga-main .person-item .item-nav-links a:last-of-type,
            .pro-version.castings .dzyga-main .project-item .item-nav-links a:last-of-type {
              margin-bottom: 0;
              background-color: #A4A6AB; }
      .pro-version.profile .dzyga-main .cinema, .pro-version.castings .dzyga-main .cinema {
        height: auto;
        margin-bottom: 20px; }
        .pro-version.profile .dzyga-main .cinema:last-of-type, .pro-version.castings .dzyga-main .cinema:last-of-type {
          margin-bottom: 0; }
        .pro-version.profile .dzyga-main .cinema .cinema-poster, .pro-version.castings .dzyga-main .cinema .cinema-poster {
          height: 240px; }
      .pro-version.profile .dzyga-main .reviews .cinema-info-wrapper .reviewer-rating, .pro-version.castings .dzyga-main .reviews .cinema-info-wrapper .reviewer-rating {
        margin-bottom: 10px; }
      .pro-version.profile .dzyga-main .reviews .cinema-info-wrapper .cinema-name, .pro-version.castings .dzyga-main .reviews .cinema-info-wrapper .cinema-name {
        margin-bottom: 10px;
        display: block; }
      .pro-version.profile .dzyga-main .reviews a[href], .pro-version.castings .dzyga-main .reviews a[href] {
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        .pro-version.profile .dzyga-main .reviews a[href]:hover, .pro-version.castings .dzyga-main .reviews a[href]:hover {
          color: #FFCD00; }

/* PRO PROFILE */
.profile .person-photo,
.company .person-photo {
  width: 280px;
  height: 340px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.profile .info-wrapper,
.company .info-wrapper {
  display: flex; }

.profile .information,
.company .information {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .profile .information .contacts-info,
  .company .information .contacts-info {
    width: 45%;
    padding: 15px;
    background-color: #F2F2F2;
    font-family: MontserratBold;
    margin-bottom: 20px; }
    .profile .information .contacts-info .contacts-list,
    .company .information .contacts-info .contacts-list {
      margin-bottom: 20px; }
      .profile .information .contacts-info .contacts-list .list-item,
      .company .information .contacts-info .contacts-list .list-item {
        margin-bottom: 3px; }
        .profile .information .contacts-info .contacts-list .list-item:first-of-type,
        .company .information .contacts-info .contacts-list .list-item:first-of-type {
          margin-top: 5px; }
        .profile .information .contacts-info .contacts-list .list-item:last-of-type,
        .company .information .contacts-info .contacts-list .list-item:last-of-type {
          margin-top: 10px; }
      .profile .information .contacts-info .contacts-list:last-of-type,
      .company .information .contacts-info .contacts-list:last-of-type {
        margin-bottom: 0; }

.profile .link-edit,
.company .link-edit {
  display: block;
  padding: 10px 0 15px 0;
  text-align: center;
  background-color: #303032;
  font-family: 'MontserratBold';
  color: white;
  margin-top: 10px; }

.profile-form,
.project-form,
.company-form,
.casting-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; }
  .profile-form .description,
  .project-form .description,
  .company-form .description,
  .casting-form .description {
    width: 100%;
    background-color: #a4a6ab;
    color: #fff;
    padding: 10px;
    margin-top: 5px; }
  .profile-form .physical-characteristics,
  .project-form .physical-characteristics,
  .company-form .physical-characteristics,
  .casting-form .physical-characteristics {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .profile-form .physical-characteristics .form-heading,
    .project-form .physical-characteristics .form-heading,
    .company-form .physical-characteristics .form-heading,
    .casting-form .physical-characteristics .form-heading {
      width: 100%; }
  .profile-form .form-heading,
  .project-form .form-heading,
  .company-form .form-heading,
  .casting-form .form-heading {
    width: 100%;
    margin-bottom: 20px;
    display: block; }
  .profile-form .col,
  .project-form .col,
  .company-form .col,
  .casting-form .col {
    flex-grow: 1; }
    .profile-form .col.left,
    .project-form .col.left,
    .company-form .col.left,
    .casting-form .col.left {
      width: 33%;
      margin-right: 20px;
      flex-grow: 0; }
      .profile-form .col.left .input-wrapper,
      .project-form .col.left .input-wrapper,
      .company-form .col.left .input-wrapper,
      .casting-form .col.left .input-wrapper {
        width: 100%; }
        .profile-form .col.left .input-wrapper .delete-photo,
        .project-form .col.left .input-wrapper .delete-photo,
        .company-form .col.left .input-wrapper .delete-photo,
        .casting-form .col.left .input-wrapper .delete-photo {
          position: absolute;
          top: 20px;
          z-index: 5;
          color: white;
          right: 5px; }
    .profile-form .col.right,
    .project-form .col.right,
    .company-form .col.right,
    .casting-form .col.right {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      width: calc(66% - 20px); }
      .profile-form .col.right .input-wrapper,
      .project-form .col.right .input-wrapper,
      .company-form .col.right .input-wrapper,
      .casting-form .col.right .input-wrapper {
        margin: 0 0 15px 0;
        width: calc(50% - 10px);
        position: relative; }
        .profile-form .col.right .input-wrapper.full,
        .project-form .col.right .input-wrapper.full,
        .company-form .col.right .input-wrapper.full,
        .casting-form .col.right .input-wrapper.full {
          width: 100%; }
        .profile-form .col.right .input-wrapper.phone,
        .project-form .col.right .input-wrapper.phone,
        .company-form .col.right .input-wrapper.phone,
        .casting-form .col.right .input-wrapper.phone {
          width: 100%;
          margin-bottom: 0; }
          .profile-form .col.right .input-wrapper.phone input:not(:only-of-type),
          .project-form .col.right .input-wrapper.phone input:not(:only-of-type),
          .company-form .col.right .input-wrapper.phone input:not(:only-of-type),
          .casting-form .col.right .input-wrapper.phone input:not(:only-of-type) {
            margin-bottom: 10px; }
        .profile-form .col.right .input-wrapper.delete-field,
        .project-form .col.right .input-wrapper.delete-field,
        .company-form .col.right .input-wrapper.delete-field,
        .casting-form .col.right .input-wrapper.delete-field {
          display: none; }
      .profile-form .col.right .full,
      .project-form .col.right .full,
      .company-form .col.right .full,
      .casting-form .col.right .full {
        width: 100%; }
      .profile-form .col.right .project,
      .project-form .col.right .project,
      .company-form .col.right .project,
      .casting-form .col.right .project {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap; }
        .profile-form .col.right .project .btn-field,
        .project-form .col.right .project .btn-field,
        .company-form .col.right .project .btn-field,
        .casting-form .col.right .project .btn-field {
          top: 30px; }
        .profile-form .col.right .project.delete,
        .project-form .col.right .project.delete,
        .company-form .col.right .project.delete,
        .casting-form .col.right .project.delete {
          display: none; }
      .profile-form .col.right .col-inner,
      .project-form .col.right .col-inner,
      .company-form .col.right .col-inner,
      .casting-form .col.right .col-inner {
        width: calc(50% - 10px); }
        .profile-form .col.right .col-inner .input-wrapper,
        .project-form .col.right .col-inner .input-wrapper,
        .company-form .col.right .col-inner .input-wrapper,
        .casting-form .col.right .col-inner .input-wrapper {
          width: 100%; }
    .profile-form .col.mailing,
    .project-form .col.mailing,
    .company-form .col.mailing,
    .casting-form .col.mailing {
      width: 20%;
      flex-grow: 0;
      margin-right: 20px; }
      .profile-form .col.mailing span,
      .project-form .col.mailing span,
      .company-form .col.mailing span,
      .casting-form .col.mailing span {
        margin-bottom: 5px; }
      .profile-form .col.mailing .input-wrapper,
      .project-form .col.mailing .input-wrapper,
      .company-form .col.mailing .input-wrapper,
      .casting-form .col.mailing .input-wrapper {
        width: 100%; }
        .profile-form .col.mailing .input-wrapper label,
        .project-form .col.mailing .input-wrapper label,
        .company-form .col.mailing .input-wrapper label,
        .casting-form .col.mailing .input-wrapper label {
          background-color: #A3A3A4;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          color: white;
          cursor: pointer;
          height: 36px;
          padding: 10px; }
        .profile-form .col.mailing .input-wrapper input,
        .project-form .col.mailing .input-wrapper input,
        .company-form .col.mailing .input-wrapper input,
        .casting-form .col.mailing .input-wrapper input {
          overflow: hidden;
          flex-grow: 0;
          width: 0;
          height: 0;
          visibility: hidden;
          opacity: 0;
          position: absolute;
          bottom: 0;
          left: 0; }
          .profile-form .col.mailing .input-wrapper input:checked + label,
          .project-form .col.mailing .input-wrapper input:checked + label,
          .company-form .col.mailing .input-wrapper input:checked + label,
          .casting-form .col.mailing .input-wrapper input:checked + label {
            background-color: #FFCD00; }
    .profile-form .col a.form-heading,
    .project-form .col a.form-heading,
    .company-form .col a.form-heading,
    .casting-form .col a.form-heading {
      font-family: MontserratExtraBold;
      font-size: 1.1em; }
  .profile-form .col-md,
  .project-form .col-md,
  .company-form .col-md,
  .casting-form .col-md {
    width: 66%; }
    .profile-form .col-md .input-wrapper,
    .project-form .col-md .input-wrapper,
    .company-form .col-md .input-wrapper,
    .casting-form .col-md .input-wrapper {
      width: 100%; }
  .profile-form span,
  .project-form span,
  .company-form span,
  .casting-form span {
    font-size: 0.9em; }
  .profile-form .input-wrapper,
  .project-form .input-wrapper,
  .company-form .input-wrapper,
  .casting-form .input-wrapper {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;
    width: calc(33% - 10px);
    position: relative; }
    .profile-form .input-wrapper .tagify,
    .project-form .input-wrapper .tagify,
    .company-form .input-wrapper .tagify,
    .casting-form .input-wrapper .tagify {
      background-color: #A4A6AB;
      max-width: 100%;
      overflow-x: auto;
      margin-top: 5px; }
      .profile-form .input-wrapper .tagify .tagify__tag__removeBtn,
      .project-form .input-wrapper .tagify .tagify__tag__removeBtn,
      .company-form .input-wrapper .tagify .tagify__tag__removeBtn,
      .casting-form .input-wrapper .tagify .tagify__tag__removeBtn {
        color: white; }
      .profile-form .input-wrapper .tagify .tagify__tag > div,
      .project-form .input-wrapper .tagify .tagify__tag > div,
      .company-form .input-wrapper .tagify .tagify__tag > div,
      .casting-form .input-wrapper .tagify .tagify__tag > div {
        background-color: #222222; }
      .profile-form .input-wrapper .tagify .tagify__tag > div > *,
      .project-form .input-wrapper .tagify .tagify__tag > div > *,
      .company-form .input-wrapper .tagify .tagify__tag > div > *,
      .casting-form .input-wrapper .tagify .tagify__tag > div > * {
        color: white; }
    .profile-form .input-wrapper span,
    .project-form .input-wrapper span,
    .company-form .input-wrapper span,
    .casting-form .input-wrapper span {
      font-size: 0.9em; }
    .profile-form .input-wrapper label,
    .project-form .input-wrapper label,
    .company-form .input-wrapper label,
    .casting-form .input-wrapper label {
      font-size: 0.9em; }
    .profile-form .input-wrapper input,
    .profile-form .input-wrapper textarea,
    .profile-form .input-wrapper select,
    .project-form .input-wrapper input,
    .project-form .input-wrapper textarea,
    .project-form .input-wrapper select,
    .company-form .input-wrapper input,
    .company-form .input-wrapper textarea,
    .company-form .input-wrapper select,
    .casting-form .input-wrapper input,
    .casting-form .input-wrapper textarea,
    .casting-form .input-wrapper select {
      width: 100%;
      background-color: #A4A6AB;
      color: white;
      padding: 10px;
      margin-top: 5px;
      height: 36px; }
    .profile-form .input-wrapper.phone-wrapper span,
    .project-form .input-wrapper.phone-wrapper span,
    .company-form .input-wrapper.phone-wrapper span,
    .casting-form .input-wrapper.phone-wrapper span {
      margin-bottom: 0;
      font-size: 0.9em; }
    .profile-form .input-wrapper.photo label, .profile-form .input-wrapper.poster label,
    .project-form .input-wrapper.photo label,
    .project-form .input-wrapper.poster label,
    .company-form .input-wrapper.photo label,
    .company-form .input-wrapper.poster label,
    .casting-form .input-wrapper.photo label,
    .casting-form .input-wrapper.poster label {
      width: 100%;
      background-color: #A4A6AB;
      cursor: pointer;
      margin-top: 5px; }
    .profile-form .input-wrapper.photo input, .profile-form .input-wrapper.poster input,
    .project-form .input-wrapper.photo input,
    .project-form .input-wrapper.poster input,
    .company-form .input-wrapper.photo input,
    .company-form .input-wrapper.poster input,
    .casting-form .input-wrapper.photo input,
    .casting-form .input-wrapper.poster input {
      display: none; }
    .profile-form .input-wrapper textarea,
    .project-form .input-wrapper textarea,
    .company-form .input-wrapper textarea,
    .casting-form .input-wrapper textarea {
      height: 130px;
      resize: none; }
    .profile-form .input-wrapper[role="group"],
    .project-form .input-wrapper[role="group"],
    .company-form .input-wrapper[role="group"],
    .casting-form .input-wrapper[role="group"] {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap; }
      .profile-form .input-wrapper[role="group"] span,
      .project-form .input-wrapper[role="group"] span,
      .company-form .input-wrapper[role="group"] span,
      .casting-form .input-wrapper[role="group"] span {
        width: 100%;
        flex-grow: 1;
        display: block; }
      .profile-form .input-wrapper[role="group"] label,
      .project-form .input-wrapper[role="group"] label,
      .company-form .input-wrapper[role="group"] label,
      .casting-form .input-wrapper[role="group"] label {
        width: 50%; }
    .profile-form .input-wrapper.buttons,
    .project-form .input-wrapper.buttons,
    .company-form .input-wrapper.buttons,
    .casting-form .input-wrapper.buttons {
      display: flex;
      width: 100%;
      flex-direction: row;
      align-items: center; }
    .profile-form .input-wrapper .delete-field,
    .project-form .input-wrapper .delete-field,
    .company-form .input-wrapper .delete-field,
    .casting-form .input-wrapper .delete-field {
      display: none;
      width: 100%;
      margin-top: 15px; }
    .profile-form .input-wrapper .btn-field,
    .project-form .input-wrapper .btn-field,
    .company-form .input-wrapper .btn-field,
    .casting-form .input-wrapper .btn-field {
      font-size: 1em;
      color: white;
      position: absolute;
      right: 5px; }
    .profile-form .input-wrapper.full,
    .project-form .input-wrapper.full,
    .company-form .input-wrapper.full,
    .casting-form .input-wrapper.full {
      width: 100%; }
  .profile-form input[type=submit],
  .project-form input[type=submit],
  .company-form input[type=submit],
  .casting-form input[type=submit] {
    width: 160px;
    cursor: pointer;
    background-color: #303032;
    padding: 10px;
    color: white; }
  .profile-form .input-wrapper.delete-field,
  .project-form .input-wrapper.delete-field,
  .company-form .input-wrapper.delete-field,
  .casting-form .input-wrapper.delete-field {
    display: none;
    width: 100%;
    margin-top: 15px; }
  .profile-form .half,
  .project-form .half,
  .company-form .half,
  .casting-form .half {
    width: 50%; }
  .profile-form .fields-wrapper,
  .project-form .fields-wrapper,
  .company-form .fields-wrapper,
  .casting-form .fields-wrapper {
    width: 100%;
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-bottom: 20px; }
    .profile-form .fields-wrapper:last-of-type,
    .project-form .fields-wrapper:last-of-type,
    .company-form .fields-wrapper:last-of-type,
    .casting-form .fields-wrapper:last-of-type {
      margin-bottom: 0; }
    .profile-form .fields-wrapper .heading-wrapper,
    .project-form .fields-wrapper .heading-wrapper,
    .company-form .fields-wrapper .heading-wrapper,
    .casting-form .fields-wrapper .heading-wrapper {
      width: 100%;
      position: relative;
      margin-bottom: 5px; }
      .profile-form .fields-wrapper .heading-wrapper::after,
      .project-form .fields-wrapper .heading-wrapper::after,
      .company-form .fields-wrapper .heading-wrapper::after,
      .casting-form .fields-wrapper .heading-wrapper::after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: #a4a6ab;
        left: 0;
        top: 50%; }
      .profile-form .fields-wrapper .heading-wrapper .fields-heading,
      .project-form .fields-wrapper .heading-wrapper .fields-heading,
      .company-form .fields-wrapper .heading-wrapper .fields-heading,
      .casting-form .fields-wrapper .heading-wrapper .fields-heading {
        width: 100%;
        font-size: 0.9em;
        font-family: MontserratMedium;
        position: relative;
        background-color: white;
        z-index: 2;
        padding-right: 20px; }
    .profile-form .fields-wrapper .email,
    .project-form .fields-wrapper .email,
    .company-form .fields-wrapper .email,
    .casting-form .fields-wrapper .email {
      width: 45%;
      margin-right: 30px; }
    .profile-form .fields-wrapper .position,
    .project-form .fields-wrapper .position,
    .company-form .fields-wrapper .position,
    .casting-form .fields-wrapper .position {
      width: 35%;
      margin-right: 30px; }
    .profile-form .fields-wrapper .access-level,
    .project-form .fields-wrapper .access-level,
    .company-form .fields-wrapper .access-level,
    .casting-form .fields-wrapper .access-level {
      flex-grow: 1;
      width: auto; }
    .profile-form .fields-wrapper .half,
    .project-form .fields-wrapper .half,
    .company-form .fields-wrapper .half,
    .casting-form .fields-wrapper .half {
      margin-right: 30px;
      width: calc(50% - 15px); }
      .profile-form .fields-wrapper .half:last-of-type,
      .project-form .fields-wrapper .half:last-of-type,
      .company-form .fields-wrapper .half:last-of-type,
      .casting-form .fields-wrapper .half:last-of-type {
        margin-right: 0; }
  .profile-form .form-btn-save,
  .project-form .form-btn-save,
  .company-form .form-btn-save,
  .casting-form .form-btn-save {
    padding: 10px;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    background-color: #303032;
    width: 160px;
    text-align: center;
    color: white;
    margin-left: auto; }
    .profile-form .form-btn-save:hover,
    .project-form .form-btn-save:hover,
    .company-form .form-btn-save:hover,
    .casting-form .form-btn-save:hover {
      color: #FFCD00; }
  .profile-form .casting-name,
  .project-form .casting-name,
  .company-form .casting-name,
  .casting-form .casting-name {
    width: 66%; }
  .profile-form label[for=photo],
  .project-form label[for=photo],
  .company-form label[for=photo],
  .casting-form label[for=photo] {
    height: 340px;
    background-position: center;
    background-size: cover; }
  .profile-form .photo-portfolio,
  .project-form .photo-portfolio,
  .company-form .photo-portfolio,
  .casting-form .photo-portfolio {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row; }
    .profile-form .photo-portfolio span,
    .project-form .photo-portfolio span,
    .company-form .photo-portfolio span,
    .casting-form .photo-portfolio span {
      width: 100%; }
    .profile-form .photo-portfolio input,
    .project-form .photo-portfolio input,
    .company-form .photo-portfolio input,
    .casting-form .photo-portfolio input {
      display: none; }
  .profile-form label[for=photo-portfolio],
  .project-form label[for=photo-portfolio],
  .company-form label[for=photo-portfolio],
  .casting-form label[for=photo-portfolio] {
    width: 100%;
    margin-right: auto;
    padding: 10px;
    background-color: #303032;
    color: white;
    margin-top: 5px;
    cursor: pointer;
    text-align: center;
    margin-bottom: 15px; }
  .profile-form .img-preview,
  .project-form .img-preview,
  .company-form .img-preview,
  .casting-form .img-preview {
    height: 60px;
    width: 60px;
    margin-right: 10px;
    margin-bottom: 10px;
    background-size: cover;
    background-position: center;
    position: relative; }
    .profile-form .img-preview .js-delete-preview,
    .project-form .img-preview .js-delete-preview,
    .company-form .img-preview .js-delete-preview,
    .casting-form .img-preview .js-delete-preview {
      position: absolute;
      top: 0;
      right: 0;
      color: white;
      cursor: pointer; }
  .profile-form.casting-form .form-btn-save,
  .project-form.casting-form .form-btn-save,
  .company-form.casting-form .form-btn-save,
  .casting-form.casting-form .form-btn-save {
    margin-left: 0; }
  .profile-form .actor-characteristics,
  .project-form .actor-characteristics,
  .company-form .actor-characteristics,
  .casting-form .actor-characteristics {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    display: none; }
  .profile-form .default-profession,
  .project-form .default-profession,
  .company-form .default-profession,
  .casting-form .default-profession {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
    .profile-form .default-profession.new-profession,
    .project-form .default-profession.new-profession,
    .company-form .default-profession.new-profession,
    .casting-form .default-profession.new-profession {
      display: none; }

.casting .single-cinema .cinema-preview .cinema-info .cinema-status {
  border-left: none;
  border-right: 1px solid #FFCD00;
  padding: 0 20px 0 0;
  margin-right: 20px; }
  .casting .single-cinema .cinema-preview .cinema-info .cinema-status span {
    font-family: MontserratBold; }

.casting p span {
  font-family: MontserratBold; }

.casting .cinema-castings-wrapper .heading {
  font-size: 1.2em; }

.casting .cinema-castings-wrapper .casting-item {
  background-color: #F2F2F2;
  padding: 10px 20px;
  position: relative;
  margin-bottom: 20px; }
  .casting .cinema-castings-wrapper .casting-item .casting-apply {
    position: absolute;
    top: 0;
    right: 0;
    background-color: #FFCD00;
    padding: 10px 25px;
    color: #222222;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .casting .cinema-castings-wrapper .casting-item .casting-apply:hover {
      color: white; }
  .casting .cinema-castings-wrapper .casting-item .casting-desc {
    margin-top: 15px; }
    .casting .cinema-castings-wrapper .casting-item .casting-desc p {
      color: #222222;
      margin-top: 5px; }

.casting .cinema-castings-wrapper .characteristics-list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 15px; }
  .casting .cinema-castings-wrapper .characteristics-list .list-item {
    width: 50%;
    margin-bottom: 3px; }
    .casting .cinema-castings-wrapper .characteristics-list .list-item span {
      font-family: MontserratBold;
      margin-left: 10px; }

.company .info-wrapper {
  display: flex; }
  .company .info-wrapper .company-img {
    width: 280px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin-right: 20px; }
  .company .info-wrapper .company-information {
    width: calc(100% - 300px); }
    .company .info-wrapper .company-information .heading {
      padding-top: 0; }
    .company .info-wrapper .company-information .category {
      color: #222222;
      font-family: MontserratBold;
      margin-bottom: 20px; }
    .company .info-wrapper .company-information p a {
      font-family: MontserratBold; }
      .company .info-wrapper .company-information p a:not(:last-child)::after {
        content: ','; }
    .company .info-wrapper .company-information p span {
      font-family: MontserratBold; }
    .company .info-wrapper .company-information p.biography {
      margin: 30px 0 15px 0; }

.company .contacts-list {
  font-family: MontserratBold;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between; }
  .company .contacts-list .list-item {
    flex-grow: 1;
    margin-bottom: 10px; }
    .company .contacts-list .list-item:last-of-type {
      width: 100%; }
    .company .contacts-list .list-item.list-heading {
      width: 100%; }

.company .company-team .team-wrapper {
  display: flex;
  flex-wrap: wrap; }
  .company .company-team .team-wrapper .member-name {
    font-family: 'MontserratBlack';
    font-size: 1.1em; }
  .company .company-team .team-wrapper .team-member {
    width: 30%;
    display: flex;
    margin-bottom: 25px;
    position: relative; }
    .company .company-team .team-wrapper .team-member a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  .company .company-team .team-wrapper .member-photo {
    width: 100px;
    height: 100px;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    margin-right: 20px; }

.company .company-clients {
  margin-bottom: 25px; }
  .company .company-clients .clients-wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between; }
  .company .company-clients .client-item {
    width: calc(25% - 20px);
    position: relative; }
    .company .company-clients .client-item a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0; }
  .company .company-clients .client-img {
    height: 100px;
    width: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin-bottom: 20px; }
  .company .company-clients .client-name {
    font-family: 'MontserratBlack';
    font-size: 1.1em;
    text-align: center; }

.company .company-team h6.heading,
.company .company-clients h6.heading {
  margin-bottom: 20px;
  font-family: 'MontserratBlack';
  position: relative; }
  .company .company-team h6.heading span,
  .company .company-clients h6.heading span {
    position: relative;
    background-color: white;
    z-index: 2;
    padding-right: 20px; }
  .company .company-team h6.heading::after,
  .company .company-clients h6.heading::after {
    content: '';
    width: 100%;
    height: 1px;
    background-color: #222222;
    position: absolute;
    left: 0;
    top: 50%; }

.heading {
  font-family: MontserratExtraBold;
  font-size: 1.5em;
  padding: 10px 0;
  background-color: white; }

.pro-aside .aside-box.actors .list-wrapper {
  max-height: 100%; }

.pro-aside .heading {
  font-size: 1.3em; }

.pro-aside .list-wrapper {
  padding: 0 20px;
  margin: 10px 15px 20px 0;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-height: 230px;
  overflow-y: auto; }
  .pro-aside .list-wrapper::-webkit-scrollbar {
    width: 3px;
    background-color: #F5F5F5; }
  .pro-aside .list-wrapper::-webkit-scrollbar-track {
    background-color: #222222; }
  .pro-aside .list-wrapper::-webkit-scrollbar-thumb {
    background-color: #E6E7EB; }
  .pro-aside .list-wrapper .checkbox-item {
    margin-bottom: 10px;
    display: flex;
    align-items: center; }
    .pro-aside .list-wrapper .checkbox-item input {
      margin-right: 5px;
      cursor: pointer; }
    .pro-aside .list-wrapper .checkbox-item label {
      cursor: pointer; }
  .pro-aside .list-wrapper .input-wrapper input {
    background-color: #A4A6AB;
    padding: 10px 0;
    color: white;
    text-indent: 10px;
    margin-top: 5px;
    width: 100%; }
    .pro-aside .list-wrapper .input-wrapper input::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: white; }
    .pro-aside .list-wrapper .input-wrapper input::-moz-placeholder {
      /* Firefox 19+ */
      color: white; }
    .pro-aside .list-wrapper .input-wrapper input:-ms-input-placeholder {
      /* IE 10+ */
      color: white; }
    .pro-aside .list-wrapper .input-wrapper input:-moz-placeholder {
      /* Firefox 18- */
      color: white; }
  .pro-aside .list-wrapper .list-item {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px; }
    .pro-aside .list-wrapper .list-item span {
      margin-bottom: 5px; }
    .pro-aside .list-wrapper .list-item select {
      background-color: #A4A6AB;
      padding: 10px 0;
      color: white; }
  .pro-aside .list-wrapper.years {
    flex-direction: row;
    justify-content: space-between;
    overflow: hidden; }
    .pro-aside .list-wrapper.years .select-wrapper {
      position: relative; }
      .pro-aside .list-wrapper.years .select-wrapper::before {
        content: '\e812';
        display: block;
        font-family: "dzyga";
        position: absolute;
        font-size: 1.2em;
        color: white;
        left: 0;
        top: 12px;
        transform: translate(50%, -50%);
        z-index: 2; }
    .pro-aside .list-wrapper.years select {
      border-radius: 5px;
      color: white;
      background-color: #555459;
      padding: 5px 20px 5px 30px;
      position: relative; }

main .add-project,
main .add-company,
main .add-casting {
  width: 80%;
  margin: 0 auto; }
  main .add-project .project-form .left,
  main .add-project .company-form .left,
  main .add-company .project-form .left,
  main .add-company .company-form .left,
  main .add-casting .project-form .left,
  main .add-casting .company-form .left {
    width: calc(66% - 1px);
    margin-right: 20px; }
  main .add-project .project-form .right,
  main .add-project .company-form .right,
  main .add-company .project-form .right,
  main .add-company .company-form .right,
  main .add-casting .project-form .right,
  main .add-casting .company-form .right {
    width: calc(33% - 20px); }
    main .add-project .project-form .right .input-wrapper,
    main .add-project .company-form .right .input-wrapper,
    main .add-company .project-form .right .input-wrapper,
    main .add-company .company-form .right .input-wrapper,
    main .add-casting .project-form .right .input-wrapper,
    main .add-casting .company-form .right .input-wrapper {
      width: 100%; }
      main .add-project .project-form .right .input-wrapper span,
      main .add-project .company-form .right .input-wrapper span,
      main .add-company .project-form .right .input-wrapper span,
      main .add-company .company-form .right .input-wrapper span,
      main .add-casting .project-form .right .input-wrapper span,
      main .add-casting .company-form .right .input-wrapper span {
        margin-bottom: 0; }
  main .add-project .project-form .col-md,
  main .add-project .company-form .col-md,
  main .add-company .project-form .col-md,
  main .add-company .company-form .col-md,
  main .add-casting .project-form .col-md,
  main .add-casting .company-form .col-md {
    width: 66%; }
  main .add-project .project-form .col-sm,
  main .add-project .company-form .col-sm,
  main .add-company .project-form .col-sm,
  main .add-company .company-form .col-sm,
  main .add-casting .project-form .col-sm,
  main .add-casting .company-form .col-sm {
    width: calc(33% - 10px); }
    main .add-project .project-form .col-sm .input-wrapper.photo,
    main .add-project .company-form .col-sm .input-wrapper.photo,
    main .add-company .project-form .col-sm .input-wrapper.photo,
    main .add-company .company-form .col-sm .input-wrapper.photo,
    main .add-casting .project-form .col-sm .input-wrapper.photo,
    main .add-casting .company-form .col-sm .input-wrapper.photo {
      width: 100%; }
  main .add-project .project-form .col-wrapper,
  main .add-project .company-form .col-wrapper,
  main .add-company .project-form .col-wrapper,
  main .add-company .company-form .col-wrapper,
  main .add-casting .project-form .col-wrapper,
  main .add-casting .company-form .col-wrapper {
    width: 100%;
    display: flex; }
    main .add-project .project-form .col-wrapper .input-wrapper.poster label,
    main .add-project .company-form .col-wrapper .input-wrapper.poster label,
    main .add-company .project-form .col-wrapper .input-wrapper.poster label,
    main .add-company .company-form .col-wrapper .input-wrapper.poster label,
    main .add-casting .project-form .col-wrapper .input-wrapper.poster label,
    main .add-casting .company-form .col-wrapper .input-wrapper.poster label {
      height: 375px; }
    main .add-project .project-form .col-wrapper .left,
    main .add-project .company-form .col-wrapper .left,
    main .add-company .project-form .col-wrapper .left,
    main .add-company .company-form .col-wrapper .left,
    main .add-casting .project-form .col-wrapper .left,
    main .add-casting .company-form .col-wrapper .left {
      margin-bottom: 10px; }
  main .add-project .project-form .field-wrapper,
  main .add-project .company-form .field-wrapper,
  main .add-company .project-form .field-wrapper,
  main .add-company .company-form .field-wrapper,
  main .add-casting .project-form .field-wrapper,
  main .add-casting .company-form .field-wrapper {
    width: calc(33% - 10px); }
    main .add-project .project-form .field-wrapper span,
    main .add-project .company-form .field-wrapper span,
    main .add-company .project-form .field-wrapper span,
    main .add-company .company-form .field-wrapper span,
    main .add-casting .project-form .field-wrapper span,
    main .add-casting .company-form .field-wrapper span {
      margin-bottom: 5px;
      font-size: 0.9em; }
    main .add-project .project-form .field-wrapper .input-wrapper,
    main .add-project .company-form .field-wrapper .input-wrapper,
    main .add-company .project-form .field-wrapper .input-wrapper,
    main .add-company .company-form .field-wrapper .input-wrapper,
    main .add-casting .project-form .field-wrapper .input-wrapper,
    main .add-casting .company-form .field-wrapper .input-wrapper {
      width: 100%; }
  main .add-project .project-form .field-wrapper.col-md,
  main .add-project .company-form .field-wrapper.col-md,
  main .add-company .project-form .field-wrapper.col-md,
  main .add-company .company-form .field-wrapper.col-md,
  main .add-casting .project-form .field-wrapper.col-md,
  main .add-casting .company-form .field-wrapper.col-md {
    width: 66%; }
  main .add-project .project-form .heading,
  main .add-project .company-form .heading,
  main .add-company .project-form .heading,
  main .add-company .company-form .heading,
  main .add-casting .project-form .heading,
  main .add-casting .company-form .heading {
    width: 100%; }
  main .add-project .project-form .full,
  main .add-project .company-form .full,
  main .add-company .project-form .full,
  main .add-company .company-form .full,
  main .add-casting .project-form .full,
  main .add-casting .company-form .full {
    width: 100%; }
  main .add-project .project-form .form-btn,
  main .add-project .company-form .form-btn,
  main .add-company .project-form .form-btn,
  main .add-company .company-form .form-btn,
  main .add-casting .project-form .form-btn,
  main .add-casting .company-form .form-btn {
    padding: 10px;
    background-color: #FFCD00;
    border-radius: 5px;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    main .add-project .project-form .form-btn:hover,
    main .add-project .company-form .form-btn:hover,
    main .add-company .project-form .form-btn:hover,
    main .add-company .company-form .form-btn:hover,
    main .add-casting .project-form .form-btn:hover,
    main .add-casting .company-form .form-btn:hover {
      color: white; }
  main .add-project .project-form .form-btn-save,
  main .add-project .company-form .form-btn-save,
  main .add-company .project-form .form-btn-save,
  main .add-company .company-form .form-btn-save,
  main .add-casting .project-form .form-btn-save,
  main .add-casting .company-form .form-btn-save {
    padding: 10px;
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    background-color: #303032;
    width: 160px;
    text-align: center;
    color: white; }
    main .add-project .project-form .form-btn-save:hover,
    main .add-project .company-form .form-btn-save:hover,
    main .add-company .project-form .form-btn-save:hover,
    main .add-company .company-form .form-btn-save:hover,
    main .add-casting .project-form .form-btn-save:hover,
    main .add-casting .company-form .form-btn-save:hover {
      color: #FFCD00; }

.article-wrapper ul {
  list-style: circle; }
  .article-wrapper ul li {
    margin-bottom: 10px;
    padding-left: 15px;
    position: relative; }
    .article-wrapper ul li::before {
      content: '';
      display: block;
      position: absolute;
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #303032;
      left: 0;
      top: 5px; }

.title-wrapper {
  font-family: 'MontserratExtraBold', sans-serif;
  font-weight: 900;
  font-size: 18px;
  color: #222222;
  line-height: normal; }

.personality-wrapper .personality-items {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 30px; }
  .personality-wrapper .personality-items .personality-item {
    width: 100%;
    display: flex;
    align-items: flex-start;
    background-color: #f2f2f2;
    margin-bottom: 20px; }
    .personality-wrapper .personality-items .personality-item:last-child {
      margin-bottom: 0; }
    .personality-wrapper .personality-items .personality-item .img {
      width: 170px;
      height: auto;
      position: relative; }
      .personality-wrapper .personality-items .personality-item .img a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 2; }
    .personality-wrapper .personality-items .personality-item .desc {
      padding: 15px 20px;
      color: #222222; }
      .personality-wrapper .personality-items .personality-item .desc .personality-title {
        font-weight: 700;
        font-family: 'MontserratBold', sans-serif;
        font-size: 1.2em;
        margin-bottom: 5px;
        transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
        .personality-wrapper .personality-items .personality-item .desc .personality-title:hover {
          color: #FFCD00; }
      .personality-wrapper .personality-items .personality-item .desc .personality-role {
        margin-bottom: 15px;
        line-height: normal; }
        .personality-wrapper .personality-items .personality-item .desc .personality-role a {
          font-weight: 700;
          font-family: 'MontserratBold', sans-serif; }
          .personality-wrapper .personality-items .personality-item .desc .personality-role a:last-child::after {
            content: none; }
          .personality-wrapper .personality-items .personality-item .desc .personality-role a::after {
            content: ', '; }
      .personality-wrapper .personality-items .personality-item .desc .personality-date {
        margin-bottom: 15px;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        line-height: normal; }
        .personality-wrapper .personality-items .personality-item .desc .personality-date b {
          font-weight: 700;
          font-family: 'MontserratBold', sans-serif; }
      .personality-wrapper .personality-items .personality-item .desc .last-project p {
        margin-bottom: 5px;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: normal; }
      .personality-wrapper .personality-items .personality-item .desc .last-project ul {
        display: flex;
        flex-wrap: wrap; }
        .personality-wrapper .personality-items .personality-item .desc .last-project ul li {
          font-family: 'MontserratRegular', sans-serif;
          font-weight: 400;
          margin-bottom: 5px;
          margin-right: 5px;
          font-size: 12px;
          line-height: normal; }
          .personality-wrapper .personality-items .personality-item .desc .last-project ul li:last-child::after {
            content: none; }
          .personality-wrapper .personality-items .personality-item .desc .last-project ul li::after {
            content: ','; }
          .personality-wrapper .personality-items .personality-item .desc .last-project ul li a {
            font-weight: 700;
            font-family: 'MontserratBold', sans-serif; }

.news-wrapper .month-wrapper {
  background-color: #f2f2f2;
  margin: 20px 0;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative; }

.news-wrapper .month-slider {
  margin: 10px 25px 20px 75px; }
  .news-wrapper .month-slider .glide__track .list-item {
    display: flex;
    align-items: center;
    justify-content: center; }
    .news-wrapper .month-slider .glide__track .list-item a {
      display: flex;
      align-items: center;
      justify-content: center; }
  .news-wrapper .month-slider .glide__arrows .icon {
    color: #222222;
    font-size: 14px; }

.news-wrapper .blocks-wrapper .block:nth-child(3n) {
  width: 100%;
  margin: 20px 0; }

.news-wrapper .blocks-wrapper .ad-wrapper {
  width: 100%; }

.dzyga-news .blocks-wrapper .block:nth-child(3n) {
  width: 100%;
  margin: 20px 0; }

.box-office-wrapper .tabs-btn-wrapper {
  margin-bottom: 20px; }

.box-office-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0 5px; }
  .box-office-table thead {
    width: 100%; }
    .box-office-table thead tr {
      background-color: #f2f2f2; }
      .box-office-table thead tr th {
        text-align: left;
        height: 40px;
        border-collapse: collapse;
        font-size: 12px;
        font-family: 'MontserratRegular', sans-serif;
        font-weight: 400; }
        .box-office-table thead tr th.t-name {
          font-size: 12px; }
  .box-office-table tbody {
    width: 100%; }
    .box-office-table tbody tr {
      background-color: #f2f2f2; }
      .box-office-table tbody tr td {
        height: 70px;
        font-family: 'MontserratExtraBold', sans-serif;
        font-weight: 900;
        font-size: 12px;
        border-collapse: collapse; }
      .box-office-table tbody tr:nth-child(odd) {
        background-color: #ffffff; }
  .box-office-table .img {
    width: 50px;
    min-width: 50px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: relative; }
    .box-office-table .img a {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2; }
  .box-office-table .t-name {
    font-size: 14px;
    padding-left: 20px; }
    .box-office-table .t-name a {
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
      .box-office-table .t-name a:hover {
        color: #FFCD00; }
  .box-office-table .t-money {
    padding-left: 10px; }
  .box-office-table .t-rolling {
    padding-left: 10px; }
  .box-office-table .t-label a {
    font-size: 14px;
    color: #000000; }

.sign .btn {
  padding: 10px 60px;
  display: inline-block;
  margin-left: auto;
  color: white;
  background-color: #222222;
  transition: all 0.3s linear;
  border-radius: 0; }
  .sign .btn:hover {
    background-color: #FFCD00;
    color: #ffffff; }

.input-box label {
  position: absolute;
  height: 50%;
  margin: auto;
  top: 0;
  left: 10px;
  color: #787878;
  font-family: 'MontserratRegular', sans-serif;
  font-weight: 400;
  opacity: 0;
  z-index: -1;
  transition: all .4s ease;
  font-size: 12px; }

.input-box input:focus:not(:placeholder-shown) + label,
.input-box input:not(:placeholder-shown) + label {
  top: -25px;
  transition: all .4s ease;
  opacity: 1;
  z-index: 1; }

.dzyga-main.tariff {
  width: 100%;
  padding: 0;
  padding: 20px 0px 50px 0; }

.form-contact-us {
  padding: 15px 20px;
  background-color: #F2F2F2; }
  .form-contact-us .form-btn-save {
    background-color: #303032;
    color: white;
    padding: 10px 20px; }

.input-wrapper.photo {
  position: relative; }
  .input-wrapper.photo label {
    width: 100%;
    background-color: #A4A6AB;
    cursor: pointer;
    margin-top: 5px; }
  .input-wrapper.photo label {
    width: 100%;
    background-position: center;
    background-size: cover;
    position: relative;
    left: 0;
    display: block;
    opacity: 1;
    z-index: 2; }
  .input-wrapper.photo input {
    display: none; }

.input-box {
  margin-bottom: 40px;
  width: 100%;
  position: relative; }
  .input-box .tagify {
    background-color: #A4A6AB;
    max-width: 100%;
    overflow-x: auto; }
    .input-box .tagify .tagify__tag__removeBtn {
      color: white; }
    .input-box .tagify .tagify__tag > div {
      background-color: #222222; }
    .input-box .tagify .tagify__tag > div > * {
      color: white; }
  .input-box.photo {
    position: relative; }
    .input-box.photo label {
      width: 100%;
      background-color: #A4A6AB;
      cursor: pointer;
      margin-top: 5px; }
    .input-box.photo label[for=photo] {
      width: 100%;
      background-position: center;
      background-size: cover;
      position: relative;
      left: 0;
      display: block;
      opacity: 1;
      z-index: 2; }
    .input-box.photo input {
      display: none; }
  .input-box.error input {
    border: 1px solid red; }
  .input-box input {
    width: 100%;
    text-indent: 10px;
    height: 40px;
    border: none;
    overflow: hidden;
    background-color: #a3a3a4;
    color: #f2f2f2;
    font-family: 'MontserratRegular', sans-serif;
    font-weight: 400;
    font-size: 12px; }
    .input-box input::placeholder {
      color: #f2f2f2; }
    .input-box input::-webkit-input-placeholder {
      color: #f2f2f2; }
    .input-box input::-moz-placeholder {
      color: #f2f2f2; }
    .input-box input:-ms-input-placeholder {
      color: #f2f2f2; }
    .input-box input:-moz-placeholder {
      color: #f2f2f2; }
  .input-box input[type="password"] {
    font: large Verdana, sans-serif;
    letter-spacing: 1px; }
    .input-box input[type="password"]::placeholder {
      font-family: 'MontserratRegular', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: normal; }
    .input-box input[type="password"]::-webkit-input-placeholder {
      font-family: 'MontserratRegular', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: normal; }
    .input-box input[type="password"]::-moz-placeholder {
      font-family: 'MontserratRegular', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: normal; }
    .input-box input[type="password"]:-ms-input-placeholder {
      font-family: 'MontserratRegular', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: normal; }
    .input-box input[type="password"]:-moz-placeholder {
      font-family: 'MontserratRegular', sans-serif;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: normal; }
  .input-box label {
    position: absolute;
    height: 50%;
    margin: auto;
    top: 0;
    left: 10px;
    color: #787878;
    font-family: 'MontserratRegular', sans-serif;
    font-weight: 400;
    opacity: 0;
    z-index: -1;
    transition: all .4s ease;
    font-size: 12px; }
  .input-box span {
    font-size: 0.9em; }

.input-wrapper {
  margin: 0 0 15px 0;
  width: 100%;
  position: relative; }
  .input-wrapper.full {
    width: 100%; }
  .input-wrapper.phone {
    width: 100%;
    margin-bottom: 0; }
    .input-wrapper.phone input:not(:only-of-type) {
      margin-bottom: 10px; }
    .input-wrapper.phone input {
      width: 100%;
      background-color: #a4a6ab;
      color: #fff;
      padding: 10px;
      margin-top: 5px;
      height: 36px; }
  .input-wrapper input,
  .input-wrapper select {
    width: 100%;
    background-color: #a4a6ab;
    color: #fff;
    padding: 10px;
    margin-top: 5px;
    height: 36px; }
  .input-wrapper span,
  .input-wrapper label {
    font-size: 0.9em; }
  .input-wrapper.delete-field {
    display: none;
    margin-top: 15px; }

.btn-field {
  font-size: 1em;
  color: white;
  position: absolute;
  right: 5px;
  top: 15px; }

.photo-portfolio {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row; }
  .photo-portfolio span {
    width: 100%; }
  .photo-portfolio input {
    display: none; }

.img-preview {
  height: 62px;
  width: 62px;
  margin-right: 10px;
  margin-bottom: 10px;
  background-size: cover;
  background-position: center;
  position: relative; }
  .img-preview .js-delete-preview {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
    cursor: pointer; }

.project,
.company {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap; }
  .project.add,
  .company.add {
    flex-wrap: nowrap;
    width: 100%; }
  .project .input-wrapper,
  .company .input-wrapper {
    width: calc(50% - 10px); }
  .project .btn-field,
  .company .btn-field {
    top: 30px; }
  .project.delete,
  .company.delete {
    display: none; }

label.file {
  margin-right: 50%;
  background-color: #303032;
  color: white;
  margin-top: 5px;
  cursor: pointer;
  text-align: center;
  margin-bottom: 15px;
  padding: 10px 25px;
  width: 50%; }

textarea {
  resize: none;
  height: 150px;
  width: 100%;
  background-color: #a4a6ab;
  color: #fff;
  padding: 10px;
  margin-top: 5px; }

.select-box {
  margin-bottom: 40px;
  width: 100%;
  position: relative; }
  .select-box.error select {
    border: 1px solid red; }
  .select-box select {
    width: 100%;
    text-indent: 2px;
    padding: 0;
    height: 40px;
    border: none;
    border-radius: 0;
    overflow: hidden;
    font-family: 'MontserratRegular', sans-serif;
    font-weight: 400;
    background-color: #a3a3a4;
    color: #f2f2f2;
    font-size: 12px; }
  .select-box label {
    position: absolute;
    height: 50%;
    margin: auto;
    left: 10px;
    color: #787878;
    font-family: 'MontserratRegular', sans-serif;
    font-weight: 400;
    top: -25px;
    opacity: 1;
    z-index: 1;
    font-weight: 500;
    transition: all .4s ease;
    font-size: 12px;
    display: flex; }
    .select-box label .hint-wrapper:hover .info {
      visibility: visible;
      overflow: visible;
      opacity: 1; }
    .select-box label .info {
      position: absolute;
      width: 250px;
      padding: 5px;
      top: -210px;
      right: -125px;
      background-color: #222;
      color: white;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.3s linear;
      padding: 10px; }
      .select-box label .info::after {
        content: '';
        display: block;
        width: 0;
        height: 0;
        position: absolute;
        left: 110px;
        top: 100%;
        border-style: solid;
        border-width: 15px 12.5px 0 12.5px;
        border-color: #222 transparent transparent transparent; }
      .select-box label .info a {
        color: #FFCD00;
        font-family: MontserratBold; }
    .select-box label i {
      color: #222222;
      cursor: pointer;
      position: relative; }

.sign-wrap {
  padding: 60px 0 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 0 auto; }
  .sign-wrap .password-refresh {
    background-color: #F2F2F2;
    padding: 15px 30px 20px;
    display: flex;
    flex-direction: column; }
    .sign-wrap .password-refresh h4 {
      margin-bottom: 40px;
      font-family: MontserratBold; }
    .sign-wrap .password-refresh button[type=submit] {
      padding: 10px 35px;
      display: block;
      color: white;
      background-color: #222222;
      transition: all 0.3s linear;
      margin-bottom: 10px;
      width: 70%; }
      .sign-wrap .password-refresh button[type=submit]:hover {
        color: #FFCD00; }
  .sign-wrap .content {
    display: flex; }
    .sign-wrap .content .info {
      margin-top: 55px;
      border-left: 1px solid #414046;
      height: auto;
      margin-left: 30px;
      padding-left: 30px; }
      .sign-wrap .content .info p {
        margin-bottom: 15px; }
  .sign-wrap img {
    width: 250px;
    height: auto;
    margin-bottom: 55px; }
  .sign-wrap .tabs-wrap {
    display: flex;
    flex-direction: column;
    margin-top: 55px; }
    .sign-wrap .tabs-wrap .tab-label-items {
      display: flex;
      max-width: 100%; }
      .sign-wrap .tabs-wrap .tab-label-items .label-item {
        max-width: 50%;
        width: 170px;
        height: 40px;
        background-color: #f2f2f2;
        color: #222222;
        transition: all 0.3s linear;
        font-size: 14px;
        font-weight: 700;
        line-height: normal;
        font-family: 'MontserratBold', sans-serif; }
        .sign-wrap .tabs-wrap .tab-label-items .label-item.active {
          background-color: #222222;
          color: #f2f2f2;
          transition: all 0.3s linear; }
        .sign-wrap .tabs-wrap .tab-label-items .label-item:hover {
          color: #FFCD00;
          transition: all 0.3s linear; }
    .sign-wrap .tabs-wrap .tab-content-items {
      background-color: #f2f2f2;
      width: 100%;
      max-width: 100%; }
      .sign-wrap .tabs-wrap .tab-content-items .content-item {
        display: none;
        padding: 45px 20px 20px;
        flex-direction: column; }
        .sign-wrap .tabs-wrap .tab-content-items .content-item.active {
          display: flex; }
        .sign-wrap .tabs-wrap .tab-content-items .content-item button {
          width: 160px;
          height: 40px;
          margin-bottom: 15px;
          background-color: #222222;
          color: #f2f2f2;
          font-size: 14px;
          font-family: 'MontserratMedium', sans-serif;
          font-style: normal;
          font-weight: 500;
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.3s linear; }
          .sign-wrap .tabs-wrap .tab-content-items .content-item button:hover {
            background-color: #FFCD00;
            color: #222222;
            transition: all 0.3s linear; }
        .sign-wrap .tabs-wrap .tab-content-items .content-item .resed-pas {
          font-size: 12px;
          color: #787878;
          transition: all 0.3s linear; }
          .sign-wrap .tabs-wrap .tab-content-items .content-item .resed-pas:hover {
            color: #222222;
            transition: all 0.3s linear; }
  .sign-wrap .form-wrapper {
    background-color: #F2F2F2;
    padding: 10px 20px;
    margin-top: 70px;
    max-width: 72%; }
    .sign-wrap .form-wrapper .heading {
      background-color: inherit;
      text-align: center; }
    .sign-wrap .form-wrapper .step-2,
    .sign-wrap .form-wrapper .step-3,
    .sign-wrap .form-wrapper .step-2-company {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between; }
      .sign-wrap .form-wrapper .step-2 .col,
      .sign-wrap .form-wrapper .step-3 .col,
      .sign-wrap .form-wrapper .step-2-company .col {
        width: calc(50% - 10px); }
      .sign-wrap .form-wrapper .step-2 button[type=submit],
      .sign-wrap .form-wrapper .step-3 button[type=submit],
      .sign-wrap .form-wrapper .step-2-company button[type=submit] {
        padding: 10px 60px;
        display: block;
        margin-left: auto;
        color: white;
        background-color: #222222;
        transition: all 0.3s linear; }
        .sign-wrap .form-wrapper .step-2 button[type=submit]:hover,
        .sign-wrap .form-wrapper .step-3 button[type=submit]:hover,
        .sign-wrap .form-wrapper .step-2-company button[type=submit]:hover {
          color: #FFCD00; }
    .sign-wrap .form-wrapper .step-3 button[type=submit] {
      margin-top: 40px; }
    .sign-wrap .form-wrapper .step-2-company .input-wrapper {
      margin-bottom: 25px; }
    .sign-wrap .form-wrapper .step-2-company span {
      font-size: 0.9em; }
    .sign-wrap .form-wrapper.tariffs {
      max-width: 100%; }
      .sign-wrap .form-wrapper.tariffs .heading {
        text-align: center; }
      .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
        display: flex; }
        .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item {
          padding: 30px 20px;
          background-color: #414046;
          color: white;
          text-align: center;
          display: flex;
          flex-direction: column;
          min-height: 213px;
          max-height: 213px;
          margin-right: 20px; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item.top {
            min-height: 270px;
            position: relative;
            top: -25px;
            padding-top: 55px; }
            .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item.top span.top {
              position: absolute;
              top: 0;
              left: 50%;
              transform: translate(-50%, 0%);
              background-color: #302F34;
              padding: 5px 40px;
              margin-bottom: 0;
              font-size: 1em; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item:last-of-type {
            margin-right: 0; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item .tariff-price {
            font-family: 'MontserratExtraBold';
            font-size: 1.4em; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item .tariff-description {
            margin: 15px 0; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item .tariff-btn {
            background-color: #302F34;
            border: 1px solid white;
            padding: 5px 40px; }
          .sign-wrap .form-wrapper.tariffs .tariffs-wrapper .tariff-item span {
            margin-bottom: 15px;
            display: block;
            font-size: 0.9em; }
  .sign-wrap .step-list {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
    margin-top: 15px;
    margin-bottom: 65px; }
    .sign-wrap .step-list .list-item {
      background-color: #a3a3a4;
      width: 35px;
      height: 35px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      border-radius: 50%;
      position: relative;
      margin: 0 50px; }
      .sign-wrap .step-list .list-item:last-of-type::after {
        display: none; }
      .sign-wrap .step-list .list-item::after {
        content: '';
        display: block;
        position: absolute;
        height: 2px;
        width: 100px;
        background-color: inherit;
        left: 100%; }
      .sign-wrap .step-list .list-item.active {
        background-color: #222222; }

.team-wrapper .project {
  flex-wrap: nowrap; }

.team-wrapper .input-wrapper {
  width: auto;
  margin-right: 20px; }

.team-wrapper .select {
  width: 35%;
  margin-right: 0; }

.article-wrapper h1 {
  font-size: 1.7em;
  font-family: MontserratBold;
  padding: 10 0px; }

.article-wrapper h2 {
  font-size: 1.5em;
  font-family: MontserratBold;
  padding: 10 0px; }

.article-wrapper h3 {
  font-size: 1.3em;
  font-family: MontserratBold;
  padding: 10 0px; }

.article-wrapper h4 {
  font-size: 1.2em;
  font-family: MontserratBold;
  padding: 10 0px; }

.article-wrapper h5 {
  font-size: 1.1em;
  font-family: MontserratBold;
  padding: 10 0px; }

.article-wrapper h6 {
  font-size: 1em;
  font-family: MontserratBold;
  padding: 10px 0; }

.article-wrapper .news-img {
  height: 305px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center; }

.article-wrapper .title-article {
  font-size: 18px;
  line-height: normal;
  font-family: 'MontserratExtraBold', sans-serif;
  font-weight: 900;
  margin-bottom: 10px; }

.article-wrapper .date {
  font-size: 12px;
  line-height: normal;
  margin-bottom: 40px;
  font-family: 'MontserratMedium';
  font-weight: 500;
  color: #9a999d; }

.article-wrapper .content {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px; }
  .article-wrapper .content .gallery-items {
    display: flex;
    flex-wrap: wrap; }
    .article-wrapper .content .gallery-items .gallery-item {
      width: calc(20% - 20px);
      margin-bottom: 20px;
      margin-right: 20px;
      height: 150px;
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }
      .article-wrapper .content .gallery-items .gallery-item:nth-child(5n) {
        margin-right: 0; }
      .article-wrapper .content .gallery-items .gallery-item a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0; }
  .article-wrapper .content p {
    margin: 0 0 15px 0;
    font-size: 13px;
    line-height: normal;
    font-family: 'MontserratMedium';
    font-weight: 500; }
  .article-wrapper .content .gallery-items {
    margin: 20px 0 25px; }
  .article-wrapper .content .ad-wrapper {
    width: 100%;
    margin-bottom: 30px; }

.breadcrumbs {
  color: #a3a3a4;
  font-family: MontserratBold;
  font-weight: 700;
  font-size: 14px; }
  .breadcrumbs .breadcrumb-items {
    display: flex; }
    .breadcrumbs .breadcrumb-items .breadcrumb-item {
      display: flex;
      align-items: center;
      margin-right: 10px; }
      .breadcrumbs .breadcrumb-items .breadcrumb-item:last-child::after {
        content: none; }
      .breadcrumbs .breadcrumb-items .breadcrumb-item::after {
        content: '\e803';
        font-family: "dzygambd";
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        margin-left: 10px; }
      .breadcrumbs .breadcrumb-items .breadcrumb-item a {
        display: flex;
        align-items: center;
        height: 40px;
        font-size: 14px; }

.flatpickr-calendar {
  background: transparent;
  opacity: 0;
  display: none;
  text-align: center;
  visibility: hidden;
  padding: 0;
  -webkit-animation: none;
  animation: none;
  direction: ltr;
  border: 0;
  font-size: 14px;
  line-height: 24px;
  border-radius: 5px;
  position: absolute;
  width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  background: #fff;
  -webkit-box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08);
  box-shadow: 1px 0 0 #e6e6e6, -1px 0 0 #e6e6e6, 0 1px 0 #e6e6e6, 0 -1px 0 #e6e6e6, 0 3px 13px rgba(0, 0, 0, 0.08); }

.flatpickr-calendar.open, .flatpickr-calendar.inline {
  opacity: 1;
  max-height: 640px;
  visibility: visible; }

.flatpickr-calendar.open {
  display: inline-block;
  z-index: 99999; }

.flatpickr-calendar.animate.open {
  -webkit-animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1);
  animation: fpFadeInDown 300ms cubic-bezier(0.23, 1, 0.32, 1); }

.flatpickr-calendar.inline {
  display: block;
  position: relative;
  top: 2px; }

.flatpickr-calendar.static {
  position: absolute;
  top: calc(100% + 2px); }

.flatpickr-calendar.static.open {
  z-index: 999;
  display: block; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+1) .flatpickr-day.inRange:nth-child(7n+7) {
  -webkit-box-shadow: none !important;
  box-shadow: none !important; }

.flatpickr-calendar.multiMonth .flatpickr-days .dayContainer:nth-child(n+2) .flatpickr-day.inRange:nth-child(7n+1) {
  -webkit-box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -2px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-calendar .hasWeeks .dayContainer, .flatpickr-calendar .hasTime .dayContainer {
  border-bottom: 0;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0; }

.flatpickr-calendar .hasWeeks .dayContainer {
  border-left: 0; }

.flatpickr-calendar.showTimeInput.hasTime .flatpickr-time {
  height: 40px;
  border-top: 1px solid #e6e6e6; }

.flatpickr-calendar.noCalendar.hasTime .flatpickr-time {
  height: auto; }

.flatpickr-calendar:before, .flatpickr-calendar:after {
  position: absolute;
  display: block;
  pointer-events: none;
  border: solid transparent;
  content: '';
  height: 0;
  width: 0;
  left: 22px; }

.flatpickr-calendar.rightMost:before, .flatpickr-calendar.rightMost:after {
  left: auto;
  right: 22px; }

.flatpickr-calendar:before {
  border-width: 5px;
  margin: 0 -5px; }

.flatpickr-calendar:after {
  border-width: 4px;
  margin: 0 -4px; }

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after {
  bottom: 100%; }

.flatpickr-calendar.arrowTop:before {
  border-bottom-color: #e6e6e6; }

.flatpickr-calendar.arrowTop:after {
  border-bottom-color: #fff; }

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  top: 100%; }

.flatpickr-calendar.arrowBottom:before {
  border-top-color: #e6e6e6; }

.flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff; }

.flatpickr-calendar:focus {
  outline: 0; }

.flatpickr-wrapper {
  position: relative;
  display: inline-block; }

.flatpickr-months {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-months .flatpickr-month {
  background: transparent;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9);
  height: 28px;
  line-height: 1;
  text-align: center;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  overflow: hidden;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  text-decoration: none;
  cursor: pointer;
  position: absolute;
  top: 0;
  line-height: 16px;
  height: 28px;
  padding: 10px;
  z-index: 3;
  color: rgba(0, 0, 0, 0.9);
  fill: rgba(0, 0, 0, 0.9); }

.flatpickr-months .flatpickr-prev-month.disabled, .flatpickr-months .flatpickr-next-month.disabled {
  display: none; }

.flatpickr-months .flatpickr-prev-month i, .flatpickr-months .flatpickr-next-month i {
  position: relative; }

.flatpickr-months .flatpickr-prev-month.flatpickr-prev-month, .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
  /*
      /*rtl:begin:ignore*/
  left: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month.flatpickr-next-month, .flatpickr-months .flatpickr-next-month.flatpickr-next-month {
  /*
      /*rtl:begin:ignore*/
  right: 0;
  /*
      /*rtl:end:ignore*/ }

/*
      /*rtl:begin:ignore*/
/*
      /*rtl:end:ignore*/
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  color: #959ea9; }

.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #f64747; }

.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  width: 14px;
  height: 14px; }

.flatpickr-months .flatpickr-prev-month svg path, .flatpickr-months .flatpickr-next-month svg path {
  -webkit-transition: fill .1s;
  transition: fill .1s;
  fill: inherit; }

.numInputWrapper {
  position: relative;
  height: auto; }

.numInputWrapper input, .numInputWrapper span {
  display: inline-block; }

.numInputWrapper input {
  width: 100%; }

.numInputWrapper input::-ms-clear {
  display: none; }

.numInputWrapper input::-webkit-outer-spin-button, .numInputWrapper input::-webkit-inner-spin-button {
  margin: 0;
  -webkit-appearance: none; }

.numInputWrapper span {
  position: absolute;
  right: 0;
  width: 14px;
  padding: 0 4px 0 2px;
  height: 50%;
  line-height: 50%;
  opacity: 0;
  cursor: pointer;
  border: 1px solid rgba(57, 57, 57, 0.15);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.numInputWrapper span:hover {
  background: rgba(0, 0, 0, 0.1); }

.numInputWrapper span:active {
  background: rgba(0, 0, 0, 0.2); }

.numInputWrapper span:after {
  display: block;
  content: "";
  position: absolute; }

.numInputWrapper span.arrowUp {
  top: 0;
  border-bottom: 0; }

.numInputWrapper span.arrowUp:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-bottom: 4px solid rgba(57, 57, 57, 0.6);
  top: 26%; }

.numInputWrapper span.arrowDown {
  top: 50%; }

.numInputWrapper span.arrowDown:after {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid rgba(57, 57, 57, 0.6);
  top: 40%; }

.numInputWrapper span svg {
  width: inherit;
  height: auto; }

.numInputWrapper span svg path {
  fill: rgba(0, 0, 0, 0.5); }

.numInputWrapper:hover {
  background: rgba(0, 0, 0, 0.05); }

.numInputWrapper:hover span {
  opacity: 1; }

.flatpickr-current-month {
  font-size: 135%;
  line-height: inherit;
  font-weight: 300;
  color: inherit;
  position: absolute;
  width: 75%;
  left: 12.5%;
  padding: 6.16px 0 0 0;
  line-height: 1;
  height: 28px;
  display: inline-block;
  text-align: center;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.flatpickr-current-month span.cur-month {
  font-family: inherit;
  font-weight: 700;
  color: inherit;
  display: inline-block;
  margin-left: .5ch;
  padding: 0; }

.flatpickr-current-month span.cur-month:hover {
  background: rgba(0, 0, 0, 0.05); }

.flatpickr-current-month .numInputWrapper {
  width: 6ch;
  width: 7ch\0;
  display: inline-block; }

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: rgba(0, 0, 0, 0.9); }

.flatpickr-current-month input.cur-year {
  background: transparent;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: inherit;
  cursor: text;
  padding: 0 0 0 .5ch;
  margin: 0;
  display: inline-block;
  font-size: inherit;
  font-family: inherit;
  font-weight: 300;
  line-height: inherit;
  height: auto;
  border: 0;
  border-radius: 0;
  vertical-align: initial;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-current-month input.cur-year:focus {
  outline: 0; }

.flatpickr-current-month input.cur-year[disabled], .flatpickr-current-month input.cur-year[disabled]:hover {
  font-size: 100%;
  color: rgba(0, 0, 0, 0.5);
  background: transparent;
  pointer-events: none; }

.flatpickr-weekdays {
  background: transparent;
  text-align: center;
  overflow: hidden;
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  height: 28px; }

.flatpickr-weekdays .flatpickr-weekdaycontainer {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1; }

span.flatpickr-weekday {
  cursor: default;
  font-size: 90%;
  background: transparent;
  color: rgba(0, 0, 0, 0.54);
  line-height: 1;
  margin: 0;
  text-align: center;
  display: block;
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-weight: bolder; }

.dayContainer, .flatpickr-weeks {
  padding: 1px 0 0 0; }

.flatpickr-days {
  position: relative;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  width: 307.875px; }

.flatpickr-days:focus {
  outline: 0; }

.dayContainer {
  padding: 0;
  outline: 0;
  text-align: left;
  width: 307.875px;
  min-width: 307.875px;
  max-width: 307.875px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  display: inline-block;
  display: -ms-flexbox;
  display: -webkit-box;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-around;
  justify-content: space-around;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  opacity: 1; }

.dayContainer + .dayContainer {
  -webkit-box-shadow: -1px 0 0 #e6e6e6;
  box-shadow: -1px 0 0 #e6e6e6; }

.flatpickr-day {
  background: none;
  border: 1px solid transparent;
  border-radius: 150px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #393939;
  cursor: pointer;
  font-weight: 400;
  width: 14.2857143%;
  -webkit-flex-basis: 14.2857143%;
  -ms-flex-preferred-size: 14.2857143%;
  flex-basis: 14.2857143%;
  max-width: 39px;
  height: 39px;
  line-height: 39px;
  margin: 0;
  display: inline-block;
  position: relative;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center; }

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  cursor: pointer;
  outline: 0;
  background: #e6e6e6;
  border-color: #e6e6e6; }

.flatpickr-day.today {
  border-color: #959ea9; }

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  border-color: #959ea9;
  background: #959ea9;
  color: #fff; }

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #569ff7;
  -webkit-box-shadow: none;
  box-shadow: none;
  color: #fff;
  border-color: #569ff7; }

.flatpickr-day.selected.startRange, .flatpickr-day.startRange.startRange, .flatpickr-day.endRange.startRange {
  border-radius: 50px 0 0 50px; }

.flatpickr-day.selected.endRange, .flatpickr-day.startRange.endRange, .flatpickr-day.endRange.endRange {
  border-radius: 0 50px 50px 0; }

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #569ff7;
  box-shadow: -10px 0 0 #569ff7; }

.flatpickr-day.selected.startRange.endRange, .flatpickr-day.startRange.startRange.endRange, .flatpickr-day.endRange.startRange.endRange {
  border-radius: 50px; }

.flatpickr-day.inRange {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6;
  box-shadow: -5px 0 0 #e6e6e6,5px 0 0 #e6e6e6; }

.flatpickr-day.disabled, .flatpickr-day.disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  border-color: transparent;
  cursor: default; }

.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
  cursor: not-allowed;
  color: rgba(57, 57, 57, 0.1); }

.flatpickr-day.week.selected {
  border-radius: 0;
  -webkit-box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7;
  box-shadow: -5px 0 0 #569ff7,5px 0 0 #569ff7; }

.flatpickr-day.hidden {
  visibility: hidden; }

.rangeMode .flatpickr-day {
  margin-top: 1px; }

.flatpickr-weekwrapper {
  display: inline-block;
  float: left; }

.flatpickr-weekwrapper .flatpickr-weeks {
  padding: 0 12px;
  -webkit-box-shadow: 1px 0 0 #e6e6e6;
  box-shadow: 1px 0 0 #e6e6e6; }

.flatpickr-weekwrapper .flatpickr-weekday {
  float: none;
  width: 100%;
  line-height: 28px; }

.flatpickr-weekwrapper span.flatpickr-day, .flatpickr-weekwrapper span.flatpickr-day:hover {
  display: block;
  width: 100%;
  max-width: none;
  color: rgba(57, 57, 57, 0.3);
  background: transparent;
  cursor: default;
  border: none; }

.flatpickr-innerContainer {
  display: block;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden; }

.flatpickr-rContainer {
  display: inline-block;
  padding: 0;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.flatpickr-time {
  text-align: center;
  outline: 0;
  display: block;
  height: 0;
  line-height: 40px;
  max-height: 40px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  overflow: hidden;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.flatpickr-time:after {
  content: "";
  display: table;
  clear: both; }

.flatpickr-time .numInputWrapper {
  -webkit-box-flex: 1;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  width: 40%;
  height: 40px;
  float: left; }

.flatpickr-time .numInputWrapper span.arrowUp:after {
  border-bottom-color: #393939; }

.flatpickr-time .numInputWrapper span.arrowDown:after {
  border-top-color: #393939; }

.flatpickr-time.hasSeconds .numInputWrapper {
  width: 26%; }

.flatpickr-time.time24hr .numInputWrapper {
  width: 49%; }

.flatpickr-time input {
  background: transparent;
  -webkit-box-shadow: none;
  box-shadow: none;
  border: 0;
  border-radius: 0;
  text-align: center;
  margin: 0;
  padding: 0;
  height: inherit;
  line-height: inherit;
  color: #393939;
  font-size: 14px;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield; }

.flatpickr-time input.flatpickr-hour {
  font-weight: bold; }

.flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
  font-weight: 400; }

.flatpickr-time input:focus {
  outline: 0;
  border: 0; }

.flatpickr-time .flatpickr-time-separator, .flatpickr-time .flatpickr-am-pm {
  height: inherit;
  display: inline-block;
  float: left;
  line-height: inherit;
  color: #393939;
  font-weight: bold;
  width: 2%;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-align-self: center;
  -ms-flex-item-align: center;
  align-self: center; }

.flatpickr-time .flatpickr-am-pm {
  outline: 0;
  width: 18%;
  cursor: pointer;
  text-align: center;
  font-weight: 400; }

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eee; }

.flatpickr-input[readonly] {
  cursor: pointer; }

@-webkit-keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

@keyframes fpFadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0); } }

.tagify {
  --tag-bg:#E5E5E5;
  --tag-hover:#D3E2E2;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  border: 1px solid #ddd;
  padding: 0;
  cursor: text;
  position: relative;
  -webkit-transition: .1s;
  -o-transition: .1s;
  transition: .1s; }

@-webkit-keyframes tags--bump {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); } }

@keyframes tags--bump {
  30% {
    -webkit-transform: scale(1.2);
    transform: scale(1.2); } }

.tagify:hover {
  border-color: #ccc; }

.tagify.tagify--focus {
  border-color: #85c8ea; }

.tagify[readonly] {
  cursor: default; }

.tagify[readonly] > .tagify__input {
  visibility: hidden;
  width: 0;
  margin: 5px 0; }

.tagify[readonly] .tagify__tag__removeBtn {
  display: none; }

.tagify[readonly] .tagify__tag > div {
  padding: .3em .5em; }

.tagify[readonly] .tagify__tag > div::before {
  background: -webkit-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: -o-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.tagify + input, .tagify + textarea {
  border: 0;
  display: none; }

.tagify__tag {
  display: inline-block;
  margin: 5px 0 5px 5px;
  position: relative;
  z-index: 1;
  cursor: default;
  -webkit-transition: .13s ease-out;
  -o-transition: .13s ease-out;
  transition: .13s ease-out; }

.tagify__tag.tagify--editable > div::before {
  -webkit-box-shadow: 0 0 0 2px #d3e2e2 inset !important;
  box-shadow: 0 0 0 2px #d3e2e2 inset !important;
  -webkit-box-shadow: 0 0 0 2px var(--tag-hover) inset !important;
  box-shadow: 0 0 0 2px var(--tag-hover) inset !important; }

.tagify__tag.tagify--editable.tagify--invalid > div::before {
  -webkit-box-shadow: 0 0 0 2px #d39494 inset !important;
  box-shadow: 0 0 0 2px #d39494 inset !important; }

.tagify__tag > div {
  vertical-align: top;
  position: relative;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  max-width: 100%;
  padding: .3em .5em;
  color: #000;
  border-radius: 3px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-transition: .13s ease-out;
  -o-transition: .13s ease-out;
  transition: .13s ease-out;
  padding-right: 1.5em; }

.tagify__tag > div > * {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: top; }

.tagify__tag > div > [contenteditable] {
  outline: 0; }

.tagify__tag > div::before {
  content: '';
  position: absolute;
  border-radius: inherit;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  -webkit-box-shadow: 0 0 0 16px #e5e5e5 inset;
  box-shadow: 0 0 0 16px #e5e5e5 inset;
  -webkit-box-shadow: 0 0 0 16px var(--tag-bg) inset;
  box-shadow: 0 0 0 16px var(--tag-bg) inset;
  z-index: -1;
  pointer-events: none;
  -webkit-transition: 120ms ease;
  -o-transition: 120ms ease;
  transition: 120ms ease;
  -webkit-animation: .3s tags--bump 1 ease-out;
  animation: .3s tags--bump 1 ease-out; }

.tagify__tag:hover:not([readonly]) div::before {
  top: -2px;
  right: -2px;
  bottom: -2px;
  left: -2px;
  -webkit-box-shadow: 0 0 0 16px #d3e2e2 inset;
  box-shadow: 0 0 0 16px #d3e2e2 inset;
  -webkit-box-shadow: 0 0 0 16px var(--tag-hover) inset;
  box-shadow: 0 0 0 16px var(--tag-hover) inset; }

.tagify__tag.tagify--noAnim {
  -webkit-animation: none;
  animation: none; }

.tagify__tag.tagify--hide {
  width: 0 !important;
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
  opacity: 0;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transition: .3s;
  -o-transition: .3s;
  transition: .3s;
  pointer-events: none; }

.tagify__tag.tagify--mark div::before {
  -webkit-animation: none;
  animation: none; }

.tagify__tag.tagify--notAllowed div > span {
  opacity: .5; }

.tagify__tag.tagify--notAllowed div::before {
  -webkit-box-shadow: 0 0 0 20px rgba(211, 148, 148, 0.44) inset !important;
  box-shadow: 0 0 0 20px rgba(211, 148, 148, 0.44) inset !important;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }

.tagify__tag[readonly] .tagify__tag__removeBtn {
  display: none; }

.tagify__tag[readonly] > div {
  padding: .3em .5em; }

.tagify__tag[readonly] > div::before {
  background: -webkit-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: -o-linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  background: linear-gradient(45deg, #d6d6d6 25%, transparent 25%, transparent 50%, #d6d6d6 50%, #d6d6d6 75%, transparent 75%, transparent) 0/5px 5px;
  -webkit-box-shadow: none;
  box-shadow: none; }

.tagify__tag__removeBtn {
  font: 14px/16px Serif;
  width: 14px;
  height: 14px;
  text-align: center;
  border-radius: 50px;
  position: absolute;
  z-index: 1;
  right: calc(.5em - 2px);
  top: 50%;
  cursor: pointer;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out; }

.tagify__tag__removeBtn::after {
  content: "\00D7"; }

.tagify__tag__removeBtn:hover {
  color: #fff;
  background: #c77777; }

.tagify__tag__removeBtn:hover + div > span {
  opacity: .5; }

.tagify__tag__removeBtn:hover + div::before {
  -webkit-box-shadow: 0 0 0 20px rgba(211, 148, 148, 0.3) inset !important;
  box-shadow: 0 0 0 20px rgba(211, 148, 148, 0.3) inset !important;
  -webkit-transition: .2s;
  -o-transition: .2s;
  transition: .2s; }

.tagify__input {
  display: block;
  min-width: 110px;
  margin: 5px;
  padding: .3em .5em;
  position: relative; }

.tagify__input:empty::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: .5;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }

.tagify__input:focus {
  outline: 0; }

.tagify__input:focus::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: 0;
  -webkit-transform: translatex(6px);
  -ms-transform: translatex(6px);
  transform: translatex(6px); }

@supports (-moz-appearance: none) {
  .tagify__input:focus::before {
    display: none; } }

.tagify__input:focus:empty::before {
  -webkit-transition: .2s ease-out;
  -o-transition: .2s ease-out;
  transition: .2s ease-out;
  opacity: .3;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none; }

@supports (-moz-appearance: none) {
  .tagify__input:focus:empty::before {
    display: inline-block; } }

.tagify__input::before {
  content: attr(data-placeholder);
  line-height: 1.8;
  position: absolute;
  top: 0;
  z-index: 1;
  color: #000;
  white-space: nowrap;
  pointer-events: none;
  opacity: 0; }

@supports (-moz-appearance: none) {
  .tagify__input::before {
    line-height: inherit;
    position: relative; } }

.tagify__input::after {
  content: attr(data-suggest);
  color: #000;
  opacity: .3;
  pointer-events: none; }

.tagify__input .tagify__tag {
  line-height: 1.1;
  margin: 0; }

.tagify__input .tagify__tag > div {
  padding-top: 0;
  padding-bottom: 0; }

.tagify__input .tagify__tag > div::before {
  top: -3px;
  bottom: -3px; }

.tagify__input .tagify__tag:hover:not([readonly]) > div::before {
  top: -3px;
  bottom: -3px;
  left: 0;
  right: 0; }

.tagify--mix .tagify__input {
  padding: 5px;
  margin: 0;
  width: 100%;
  height: 100%;
  line-height: 1.7; }

.tagify__dropdown {
  position: absolute;
  z-index: 999;
  background: #fff;
  max-height: 300px;
  overflow: auto;
  border: 1px solid #85c8ea;
  -webkit-box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  box-shadow: 0 2px 4px -2px rgba(0, 0, 0, 0.2);
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

.tagify__dropdown__item {
  -webkit-box-sizing: inherit;
  box-sizing: inherit;
  padding: .35em .6em;
  margin: 2px;
  cursor: pointer;
  border-radius: 3px;
  position: relative;
  outline: 0; }

.tagify__dropdown__item--active {
  background: #e5e5e5; }

.tagify__dropdown__item:active {
  background: #f2f2f2; }

/*body {*/
/*    font-family: "TimesNew", sans-serif !important;*/
/*}*/
/* Фикс - добавление красной звездочки к обязательным полям */
.required label::after {
  content: '*';
  margin-left: 4px;
  color: red; }

/* Фикс - */
.has-error input,
.has-error select {
  border: 1px solid red; }

.help-block {
  color: red;
  display: block;
  /*margin-top: 5px;*/
  /*margin-bottom: 10px;*/ }

/* */
.add-to-bookmark.active,
.pro-menu-sub a.active {
  color: #ffcd00 !important; }

.input-box.has-success input {
  border: 1px solid green; }

.input-box.has-error input,
.not-unique {
  border: 1px solid red !important; }

.help-block.help-block-error {
  color: #cc0b16 !important; }

.profile-form .input-wrapper .tagify,
.input-wrapper .tagify {
  background-color: #a4a6ab;
  color: #fff; }

.tagify__tag > div {
  background-color: #000;
  color: #fff; }

.bgс-a4a6ab {
  background-color: #a4a6ab !important;
  color: #fff; }

.page-ttl-h1 {
  display: inline-block;
  margin: 20px;
  font-family: "GothamProBold", sans-serif;
  font-size: 24px;
  font-weight: normal;
  line-height: 32px; }

.content-p {
  font-size: 14px;
  line-height: 20px; }

.hcmin {
  height: min-content !important; }

.hcmax {
  height: max-content !important; }

.hcbox {
  height: content-box !important; }

.hcfit {
  height: fit-content !important; }

.w100p {
  width: 100% !important; }

.w348 {
  width: 348px !important; }

.w376 {
  width: 376px !important; }

.w400 {
  width: 400px !important; }

.w462 {
  width: 462px !important; }

.w500 {
  width: 500px !important; }

.profile-form .col.col-right .project .btn-field,
.profile-form .input-wrapper .btn-field {
  top: 16px !important; }

.project.delete {
  -webkit-flex-wrap: nowrap;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  width: 100%; }

.profile-form .input-wrapper.label {
  margin-bottom: 0 !important; }

.tox .tox-edit-area,
.mce-content-body {
  background-color: #a4a6ab !important;
  color: white !important; }

.tariffs-wrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex; }

.tariffs-wrapper .tariff-item {
  padding: 30px 20px;
  background-color: #414046;
  color: #fff;
  text-align: center;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  min-height: 213px;
  max-height: 213px;
  margin-right: 20px; }

.tariffs-wrapper .tariff-item.top {
  min-height: 270px;
  position: relative;
  top: -25px;
  padding-top: 55px; }

.tariffs-wrapper .tariff-item.top span.top {
  position: absolute;
  top: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
  -ms-transform: translate(-50%);
  transform: translate(-50%);
  background-color: #302f34;
  padding: 5px 40px;
  margin-bottom: 0;
  font-size: 1em; }

.tariffs-wrapper .tariff-item .tariff-btn {
  background-color: #302f34;
  border: 1px solid #fff;
  color: #fff;
  padding: 5px 40px; }

.tariffs-wrapper .tariff-item .tariff-price {
  font-family: MontserratExtraBold, serif;
  font-size: 1.4em; }

.tariffs-wrapper .tariff-item .tariff-description {
  margin: 15px 0; }

.tariffs-wrapper .tariff-item span {
  margin-bottom: 15px;
  display: block;
  font-size: .9em; }

.project-form .full,
.project-form .heading {
  width: 100% !important; }

.project-form .form-btn {
  padding: 10px;
  background-color: #ffcd00;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

.sign .heading {
  background-color: #f2f2f2 !important; }

.input-wrapper.email.label,
.input-wrapper.position.label,
.input-wrapper.access-level.label {
  margin: 0 !important; }

/* Подсказка при множественном выборе */
.select2-selection__rendered,
.select2-container--krajee .select2-selection--single,
.select2-container--krajee .select2-selection--multiple .select2-search--inline .select2-search__field {
  background-color: #a4a6ab !important;
  color: #fff;
  font-size: 14px !important;
  width: 100% !important; }

/* Подсказка при одиночном выборе */
.select2-selection__placeholder {
  color: #fff !important;
  font-size: 14px !important; }

/* Выбранный элемент при одиночном выборе */
.select2-selection--single .select2-selection__rendered {
  background-color: #a4a6ab !important;
  color: #fff !important;
  font-size: 14px !important; }

/* Кнопка удаления при одиночном выборе */
.select2-selection__clear {
  color: #fff !important;
  /*font-size: 1.9em !important;*/
  font-size: 14px !important;
  right: 2rem !important; }

/* Выбранный элемент при множественном выборе */
.select2-container--krajee .select2-selection--multiple .select2-selection__choice {
  background-color: #000 !important;
  color: #fff !important;
  font-size: 12px !important;
  margin: 2px !important;
  padding: 2px !important;
  height: 26px !important; }

/* Кнопка удаления при множественном выборе */
.select2-container--krajee .select2-selection--multiple .select2-selection__choice__remove {
  float: right;
  background-color: #000 !important;
  color: #fff;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  /*opacity: 0.4;*/
  /*filter: alpha(opacity=40);*/
  display: inline-block;
  text-shadow: 0 1px 0 #fff;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin: 2px; }

/* предложение для нового актера */
.select2-results__option.none11:before {
  content: '"'; }

.select2-results__option.none11:after {
  content: '" - додати як нового'; }

/* frontend/views/pro-company/add.php */
/* фикс кнопки-добавлялки */
.btn-field {
  top: 16px !important; }

.casting-apply {
  padding: 10px 20px !important;
  background-color: #303032 !important;
  color: #fff !important;
  -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important;
  -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important;
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important; }

.casting-apply.active {
  background-color: #ffcd00 !important;
  color: #222 !important; }

.casting-apply.resolution_off {
  background-color: red !important;
  color: #fff !important; }

.casting-apply.resolution_on {
  background-color: green !important;
  color: #fff !important; }

.casting-form .form-btn {
  padding: 10px;
  background-color: #ffcd00;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }

/* Скрываем radio-кнопки для звездочек */
.review.comment-form input[type="radio"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  visibility: hidden; }

.sign2prof,
.sign2company,
.sign3actor,
.sign4 {
  padding: 25px 20px 20px;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column; }

.input-wrapper.select-box label {
  left: 0 !important;
  top: 0 !important; }

.tooltipster-sidetip.tooltipster-shadow.transfers-tooltipster .tooltipster-box {
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  border-radius: 0; }

.tooltipster-sidetip.tooltipster-shadow.transfers-tooltipster .tooltipster-content {
  font-family: "Open Sans", Arial, sans-serif;
  font-size: 15px;
  color: #000;
  padding: 10px 15px; }

.form-btn-save2 {
  padding: 10px;
  -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
  background-color: #303032;
  text-align: center;
  color: #fff;
  margin-left: auto;
  width: 240px;
  cursor: pointer;
  margin-top: 5px;
  height: 36px; }

/* Чек-бокс в фильтрах "Все" */
.pro-aside .checkbox-all {
  /*padding: 0 20px;*/
  margin: 10px 15px 0 0; }

#registration-form3 .select2-search__field {
  width: 200px !important;
  font-size: 12px !important;
  color: #a4a6ab !important; }

.casting-form .form-heading2,
.company-form .form-heading2,
.profile-form .form-heading2,
.project-form .form-heading2 {
  width: 100%;
  margin-bottom: 0;
  display: block; }

/* Фикс кнопки "Удалить строку" */
.input-wrapper.btn-del {
  width: 40px !important;
  margin: 0; }

.input-wrapper.btn-del a {
  margin: 4px; }

/* Фикс длины полей для команды */
.input-wrapper.team-email {
  min-width: 165px !important;
  width: 165px !important;
  padding-right: 8px;
  margin: 0; }

/*.input-wrapper.team-first_name {*/
/*    min-width: 100px !important;*/
/*    width: 100px !important;*/
/*    padding-right: 8px;*/
/*}*/
/*.input-wrapper.team-position {*/
/*    min-width: 100px !important;*/
/*    width: 100px !important;*/
/*    padding-right: 8px;*/
/*}*/
/*.input-wrapper.team-auth_id {*/
/*    min-width: 120px !important;*/
/*    width: 120px !important;*/
/*    padding-right: 8px;*/
/*}*/
.input-wrapper.team-last_name,
.input-wrapper.team-first_name,
.input-wrapper.team-position,
.input-wrapper.team-auth_id {
  width: calc(31% - 23px) !important;
  padding-right: 8px;
  margin: 0; }

/* Фикс длины полей для проектов */
.project.linkreview .input-wrapper.input89 {
  width: calc(89% - 20px);
  padding-right: 8px;
  margin: 0; }

.input-wrapper.project-name {
  width: calc(40% - 20px) !important;
  padding-right: 8px;
  margin: 0; }

.input-wrapper.project-role {
  width: calc(60% - 20px) !important;
  padding-right: 8px;
  margin: 0; }

/* Фикс длины полей для клиентов */
.row.row50 {
  width: calc(50% - 10px) !important;
  /*padding-right: 8px;*/
  /*margin: 0;*/ }

.input-wrapper.company-name {
  width: calc(50% - 20px) !important;
  padding-right: 8px;
  margin: 0; }

.input-wrapper.company-name2 {
  width: calc(50% - 10px) !important;
  /*padding-right: 8px;*/
  margin: 0;
  height: 20px; }

.input-wrapper.company-uri {
  width: calc(48% - 20px) !important;
  padding-right: 8px;
  margin: 0;
  /*height: 20px;*/ }

.input-wrapper.company-uri2 {
  width: calc(48% - 20px) !important;
  /*padding-right: 8px;*/
  padding-left: 18px;
  margin: 0;
  /*height: 20px;*/ }

.input-wrapper.agent-name {
  width: calc(45% - 8px) !important;
  padding-right: 8px;
  margin: 0; }

.input-wrapper.agent-phone,
.input-wrapper.agent-email {
  width: calc(27% - 8px) !important;
  padding-right: 8px;
  margin: 0; }

.input-wrapper.input40 {
  width: 40px !important;
  padding-right: 8px; }

.input-wrapper.format {
  width: calc(16% - 20px) !important;
  /*padding-right: 8px;*/
  /*margin: 0;*/ }

.input-wrapper.category {
  width: calc(34% - 20px) !important;
  /*padding-right: 8px;*/
  /*margin: 0;*/ }

.input-wrapper.telechannel,
.input-wrapper.series_count {
  width: calc(26% - 8px) !important;
  padding-right: 8px;
  margin: 0; }

.pro-version .project-item.audition {
  min-height: 160px !important;
  margin-bottom: 0 !important; }

@media screen and (min-width: 767px) {
  main .dzyga-main.audition {
    /*width: calc(100% - 560px) !important;*/
    padding-right: 20px !important; } }

.input-wrapper.input10 {
  margin: 0 0 0 0 !important;
  width: calc(10% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input15 {
  margin: 0 0 0 0 !important;
  width: calc(15% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input20 {
  margin: 0 0 0 0 !important;
  width: calc(20% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input24 {
  margin: 0 0 0 0 !important;
  width: calc(24% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input44 {
  margin: 0 0 0 0 !important;
  width: calc(44% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input64 {
  margin: 0 0 0 0 !important;
  width: calc(64% - 8px) !important;
  padding-right: 8px; }

.input-wrapper.input88 {
  margin: 0 0 0 0 !important;
  width: calc(88% - 20px) !important; }

.input-wrapper.label {
  margin: 0 0 0 0 !important;
  width: calc(50% - 40px) !important; }

.input-wrapper.label2 {
  /*margin: 0 10px 0 0 !important;*/
  width: calc(50% - 20px) !important; }

/* Стили для модального окна */
.modal-title {
  margin: 0;
  line-height: 1.42857143;
  font-size: 14px; }

.modal-body {
  position: relative;
  padding: 0 15px;
  /*overflow-y: auto;*/ }

.slimScrollDiv {
  height: 100% !important; }

.modal-dialog-custom {
  width: 650px;
  height: auto; }

.modal-content-custom {
  height: 100%; }

.slimScrollDiv {
  height: 320px !important;
  min-height: 320px !important;
  max-height: 400px !important; }

/*.slimScrollDiv .scroller {*/
/*    height: 320px !important;*/
/*    min-height: 320px !important;*/
/*    max-height: 400px !important;*/
/*}*/
.slimScroll {
  height: 320px !important; }

.modal-header {
  padding: 10px 15px !important;
  border-bottom: 1px solid #e5e5e5; }

.modal-footer {
  padding: 10px 15px !important;
  text-align: right;
  border-top: 1px solid #e5e5e5; }

button.close {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none; }

.close {
  float: right;
  font-size: 21px;
  font-weight: bold;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  filter: alpha(opacity=20);
  opacity: 0.2; }

.row {
  margin-right: -15px;
  margin-left: -15px; }

.form-group {
  margin-bottom: 15px; }

label {
  /*display: inline-block;*/
  max-width: 100%;
  margin-bottom: 5px;
  /*font-weight: 700;*/ }

.has-success .form-control {
  border-color: #3c763d;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

textarea.form-control {
  height: auto; }

.form-control {
  display: block;
  width: 100%;
  height: 34px;
  padding: 6px 12px;
  font-size: 14px;
  line-height: 1.42857143;
  color: #555555;
  background-color: #fff;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
  transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s; }

.form-control::-ms-expand {
  background-color: transparent;
  border: 0; }

.form-control:focus {
  border-color: #4D90FE;
  outline: none;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

.form-control::placeholder {
  color: #ffffff;
  opacity: 1; }

.form-control:disabled,
.form-control[readonly] {
  /*background-color: #eeeeee;*/
  opacity: 1; }

.form-control:disabled {
  cursor: not-allowed; }

@media (min-width: 992px) {
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left; } }

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px; }

@media (min-width: 992px) {
  .col-md-1 {
    width: 8.3333333%; }
  /*.my.col-md-1 {*/
  /*    width: 8%;*/
  /*}*/
  .col-md-2 {
    width: 16.66666667%; }
  .my.col-md-2 {
    width: 15%; }
  .col-md-4 {
    width: 33.33333333%; }
  .col-md-6 {
    width: 50%; }
  .col-md-8 {
    width: 66.66666667%; }
  .col-md-10 {
    width: 83.33333333%; }
  .col-md-12p {
    width: 12%;
    float: left;
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px; }
  .col-md-12 {
    width: 100%; } }

@media (min-width: 1201px) {
  .btn {
    -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); } }

.btn-success {
  color: #fff;
  background-color: #5cb85c;
  border-color: #4cae4c; }

.pro-version .company-item .project-castings-list,
.pro-version .person-item .project-castings-list,
.pro-version .project-item .project-castings-list {
  width: 100%; }

.pro-version .project-item {
  min-height: auto; }

.dzyga-main.audition {
  padding-left: 20px !important; }

.casting-apply.audition {
  background-color: #a4a6ab !important; }

.btn-del {
  width: 40px !important;
  height: 40px !important;
  padding: 10px 0 0 0; }

.btn-del a {
  margin: 4px; }

.list-item.last a {
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  padding: 10px;
  background-color: #ffcd00 !important;
  font-family: MontserratBold, sans-serif;
  width: 100%; }

.list-item.last a:hover {
  color: #222 !important; }

/**/
.input-wrapper.phone input,
.input-wrapper input,
.input-wrapper select {
  padding: 0 5px !important;
  margin-top: 0 !important; }

/*.pro-version .project-item .project-castings-list .list-item {*/
/*    justify-content: stretch !important;*/
/*}*/
.casting-edit {
  padding: 10px 12px !important;
  background-color: #303032 !important;
  color: #fff !important;
  -webkit-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important;
  -o-transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important;
  transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82) !important; }

.casting-edit.audition {
  background-color: #a4a6ab !important; }

.heading-wrapper .fields-heading {
  font-size: 14px !important;
  font-weight: 700 !important; }

/* Отчерчивание кнопик "Добавить..." */
/*.heading-wrapper:after {*/
/*    content: "";*/
/*    display: block;*/
/*    position: absolute;*/
/*    !*width: 100%;*!*/
/*    !*width: min-content;*!*/
/*    width: content-box;*/
/*    height: 1px;*/
/*    background-color: #a4a6ab;*/
/*    left: 0;*/
/*    top: 60px;*/
/*}*/
.project-form .input-wrapper input.input,
.project-form .input-wrapper a.input {
  width: 100%;
  background-color: #a4a6ab;
  color: #fff;
  margin-top: 0 !important;
  height: 34px;
  font-family: MontserratMedium, sans-serif;
  font-size: 15.05px !important;
  line-height: 17.8667px; }

.project-form .input-wrapper input.input {
  padding: 0 5px 0 12px !important; }

.project-form .input-wrapper a.input {
  padding: 10px 5px 0 12px !important; }

.cinema-duration a:last-of-type::after {
  content: ", "; }

/* Скрытие выбранных элементов в списке от повторного выбора (удаления из выбранных) */
.select2-results__option[aria-selected=true] {
  display: none; }

/* Фикс многострочного текста по высоте */
/*
https://getinstance.info/articles/css/truncate-multiline-text/
<div class="box">
    <div class="box__in">
 */
.box {
  overflow: hidden;
  min-height: 20px;
  max-height: 100px;
  width: 100%; }

.box__in {
  -webkit-column-width: 80px;
  -moz-column-width: 80px;
  column-width: 80px; }

/* В select d блоках фильтров */
.list-item.filter select {
  padding: 0 !important; }

/**/
aside.aside-profile-edit {
  height: min-content; }

/* Фикс высоты слайдера без слайдера */
.glide__slide.cinema {
  min-height: min-content;
  /*max-height: 240px;*/ }

.single-cinema .cinema-frames {
  background-color: #f2f2f2;
  padding: 0 20px;
  margin: 0; }

/**/
#sel_day1::after,
#sel_day2::after,
#sel_day3::after,
#sel_day4::after,
#sel_day5::after,
#sel_day6::after {
  /*content: "\25B2"; /* вверх */
  content: "\25BC";
  /* вниз */
  /*content: "▼";*/
  font-size: 16px;
  margin-left: 5px; }

#sel_day1.open::after,
#sel_day2.open::after,
#sel_day3.open::after,
#sel_day4.open::after,
#sel_day5.open::after,
#sel_day6.open::after {
  content: "▲";
  font-size: 16px;
  margin-left: 5px; }

.accaunt {
  width: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between; }

/**/
p.list a:not(:last-child)::after {
  content: ","; }

/* Стили для картинок меньше 7штук */
.grid-of-images a {
  /*cursor: -webkit-zoom-in;*/
  /*cursor: -moz-zoom-in;*/
  cursor: zoom-in;
  border-bottom: 0;
  display: block;
  float: left;
  margin: 0 5px 5px 0;
  /*width: 110px;*/
  /*height: 90px;*/ }

button.mfp-arrow,
button.mfp-close {
  overflow: visible;
  cursor: pointer;
  background: 0 0;
  border: 0;
  display: block;
  outline: 0;
  padding: 0;
  z-index: 1046;
  box-shadow: none; }

.mfp-arrow-right {
  right: 0; }

.mfp-arrow {
  position: absolute;
  opacity: .65;
  margin: -55px 0 0;
  top: 50%;
  padding: 0;
  width: 90px;
  height: 110px; }

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
  user-select: none; }

/* Фикс выпадашек с возрастом в фильтрах  */
.pro-aside .list-wrapper.years {
  padding: 0 0 0 0;
  margin: 10px 15px 20px 15px; }

.pro-aside .list-wrapper.years select {
  border-radius: 5px;
  color: #fff;
  background-color: #555459;
  padding: 5px 0 5px 30px;
  position: relative;
  width: 100px; }

/*.select-wrapper.year:before {*/
/*    content:           "\e812";*/
/*    display:           block;*/
/*    font-family:       GothamProBold, dzyga, serif;*/
/*    position:          absolute;*/
/*    font-size:         1.2em;*/
/*    color:             #fff;*/
/*    left:              0;*/
/*    top:               30px;*/
/*    !*-webkit-transform: translate(50%,-50%);*!*/
/*    !*-ms-transform:     translate(50%,-50%);*!*/
/*    transform:         translate(50%,-50%);*/
/*    z-index:           2;*/
/*}*/
.tab.content-item input,
.tab.content-item select {
  width: 100%;
  background-color: #a4a6ab;
  color: #fff; }

.tabs-btn-wrapper .tab-btn {
  padding: 10px 10px !important; }

.slider__bullets {
  bottom: 0; }

.slider__bullet {
  background-color: #a4a6ab; }

.slider__bullet.glide__bullet--active,
.slider__bullet:hover {
  background-color: #ffcd00; }

.glide__bullet {
  border: 0 solid transparent !important; }

/* Фикс размера кнопки "Удалить акаунт" */
.input-wrapper.ac-del {
  color: red;
  width: auto !important; }

/* Фикс соц кнопок в футере */
.social-list {
  display: flex; }

.social-list li {
  color: #ffcd00;
  padding-right: 20px; }

/* Убрать стрелочки из input числового */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none; }

input[type='number'] {
  -moz-appearance: textfield; }

.block-open {
  margin-top: 0;
  margin-bottom: 15px; }

/* Фикс скрола вправо профессий если больше 6 */
.tabs-btn-wrapper {
  flex-wrap: wrap; }

/*.movie-poster img,*/
/*.cinema-poster img {*/
/*    width: auto;*/
/*    height: 100%;*/
/*    right: 0;*/
/*    margin: auto;*/
/*}*/
.dzyga-header .pro-menu .list-item .sub-menu,
.dzyga-header .pro-menu a .sub-menu {
  padding: 20px 20px; }

/* Фикс запятой после "Выбор проекта" перед названием проекта */
.cinema-info-wrapper p.one a:not(:last-of-type):after {
  content: " "; }

.company .info-wrapper .company-information p span {
  font-family: MontserratMedium, sans-serif; }

.company .contacts-list .list-item:last-of-type {
  font-family: MontserratMedium, sans-serif; }

/* Фикс вывод картинко лого */
.company .person-photo,
.profile .person-photo {
  /*width: 280px;*/
  /*height: 340px;*/
  /*background-position: 50%;*/
  /*background-repeat: no-repeat;*/
  -webkit-background-size: contain !important;
  background-size: contain !important; }

/* Фикс crop */
.cropbox .btn-group {
  margin-top: 5px; }

.cropbox .btn {
  padding: 10px 5px;
  margin-right: 5px;
  background-color: #a4a6ab;
  color: #ffffff; }

.workarea-cropbox,
.bg-cropbox {
  height: 800px;
  min-height: 800px;
  width: auto;
  min-width: auto;
  z-index: 200; }

.frame-cropbox {
  /*height: 300px;*/
  /*min-height: 300px;*/
  /*width: auto;*/
  /*min-width: auto;*/
  z-index: 201; }

div.btn.btn-primary.btn-file {
  display: none !important; }

/**/
.blog-posts-item {
  margin: 20px 0;
  padding: 20px 0;
  border-top: 1px solid #eee; }

.comment-count,
.comment-reply {
  color: #337ab7;
  text-decoration: none;
  cursor: pointer; }

.comments.comments-post {
  margin-left: 40px; }

.comments .comments .comments .comments .comments .comments {
  margin-left: 0; }

.review-item-header,
.comment-item,
.review-item-footer {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap; }

.review-item-body {
  width: calc(100% - 80px); }

.review-item-body.c2 {
  width: calc(100% - 80px); }

.comment-item.c2 {
  width: calc(100% - 40px); }

.review-item {
  border-top: 1px solid #bcbcbc; }

.review-item .review-item {
  padding: 10px 0; }

.review-item .reviewer-rating {
  margin-bottom: 0; }

@media screen and (max-width: 801px) {
  .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 0 !important; }
  .tariffs-wrapper .tariff-item {
    margin-right: 0 !important;
    margin-bottom: 10px; }
  .tariffs-wrapper .tariff-item.top {
    margin-top: 30px; }
  .sign-wrap .step-list {
    margin-top: 10px;
    margin-bottom: 15px; }
  .dzyga-header-mobile {
    padding: 15px 20px 15px 0; }
  .input-wrapper.casting-name {
    width: 100%; }
  .account-list .list-item {
    margin-bottom: 15px; }
  .list-item:hover {
    color: #ffcd00; } }

@media screen and (max-width: 769px) {
  .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 0 !important; }
  .tariffs-wrapper .tariff-item {
    margin-right: 0 !important;
    margin-bottom: 10px; }
  .tariffs-wrapper .tariff-item.top {
    margin-top: 30px; }
  .sign-wrap .step-list {
    margin-top: 10px;
    margin-bottom: 15px; }
  .dzyga-header-mobile {
    padding: 15px 20px 15px 0; }
  .input-wrapper.casting-name {
    width: 100%; }
  .account-list .list-item {
    margin-bottom: 15px; }
  .list-item:hover {
    color: #ffcd00; } }

@media screen and (max-width: 361px) {
  .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
    margin: 0 !important; }
  .tariffs-wrapper .tariff-item {
    margin-right: 0 !important;
    margin-bottom: 10px; }
  .tariffs-wrapper .tariff-item.top {
    margin-top: 30px; }
  .sign-wrap .step-list {
    margin-top: 10px;
    margin-bottom: 15px; }
  .dzyga-header-mobile {
    padding: 15px 20px 15px 0; }
  .input-wrapper.casting-name {
    width: 100%; }
  .account-list .list-item {
    margin-bottom: 15px; }
  .list-item:hover {
    color: #ffcd00; }
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    padding-top: 20px;
    margin-top: 0 !important;
    margin-bottom: 0;
    height: auto; }
  .navigation-list .list-item a {
    font-size: 16px; }
  ::placeholder {
    color: #fff; }
  .select-wrapper.year label {
    display: none; }
  .select-wrapper.year:before {
    top: 14px; } }

@media screen and (max-height: 361px) {
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    padding-top: 80px;
    margin-bottom: 46px;
    height: auto;
    width: 100%; }
  .navigation-list .list-item a {
    font-size: 16px; }
  ::placeholder {
    color: #000; }
  #search_text2 {
    line-height: 26px; } }

@media screen and (max-width: 768px) {
  .dzyga-header-mobile .mobile-menu .wrapper {
    width: 45%; }
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    left: 0; }
  #search_text2 {
    line-height: 26px; } }

@media screen and (max-width: 660px) {
  .dzyga-header-mobile .mobile-menu .wrapper {
    width: 0; }
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    left: 0 !important; }
  .dzyga-header-mobile .mobile-menu.active {
    overflow-y: auto !important; }
  #search_text2 {
    line-height: 26px; } }

.dzyga-faq .faq-heading {
  cursor: auto; }

.dzyga-header-mobile .mobile-menu.active {
  overflow: auto; }

.input-wrapper.photo {
  margin-right: 15px; }

.single-cinema .cinema-reactions-wrapper .comment-form {
  border-top: 1px solid #bcbcbc; }

.characteristics-list .list-item span:not(:last-of-type):after {
  content: ","; }

#personprofileregform-date_birth::placeholder {
  color: #fff;
  opacity: 1;
  /*margin-left: 20px;*/ }

/* Ширина */
.form-contact-us {
  width: 360px; }

#feedbackform-message {
  height: 200px; }

#userRole,
#userRole option {
  font-size: 14px; }

#search_text2::placeholder {
  color: #000; }

/*.dzyga-premiere .premiere-slider .glide__slide {*/
/*    margin-bottom: 20px;*/
/*    padding-top: 10px;*/
/*}*/
/*.dzyga-premiere .premiere-slider .glide__slide .movie-poster {*/
/*    height: 370px;*/
/*}*/
@media (max-width: 1200px) {
  .dzyga-premiere .premiere-slider .glide__slide .movie-poster {
    height: 434px; } }

@media (max-width: 660px) {
  .single-cinema .cinema-frames .frames-slider .glide__slide a,
  .cinema .cinema-poster,
  .single-cinema .cinema-preview .cinema-poster,
  .reviews-wrapper .cinema .cinema-poster,
  .glide__slide.cinema .cinema-poster {
    width: auto;
    margin: auto; }
  .single-cinema .cinema-preview .cinema-poster {
    height: 300px; }
  .social-list {
    margin-left: 20px; }
  .bottom-nav-list {
    margin-top: 15px; }
  .cinema-rating,
  .bottom-nav-list.first,
  .bottom-nav-list.second {
    display: none; }
  .checkbox-item {
    margin-top: 15px; }
  .pro-version.profile .dzyga-main .person-item {
    /*flex-wrap: wrap;*/
    flex-direction: column;
    /*min-height: 200px;*/
    margin-bottom: 250px;
    background-color: unset; }
  .pro-version .project-item,
  .pro-version .person-item {
    flex-direction: column;
    /*min-height: 220px;*/
    min-height: min-content;
    /*margin-bottom: 260px;*/
    background-color: unset; }
  .pro-version .project-item.pr,
  .pro-version .person-item.pr {
    margin-bottom: 280px;
    /*margin-bottom: auto;*/ }
  .pro-version .person-item.co {
    margin-bottom: 180px; }
  .pro-version .project-item .info-content {
    height: min-content; }
  .pro-version .project-item .info-wrapper {
    width: 100% !important; }
  .person .person-info-wrapper,
  .information.person-information {
    flex-direction: column;
    /*min-height: 200px;*/
    /*margin-bottom: 250px;*/
    /*background-color: unset;*/ }
  .pro-version .person-item .info-content p a:not(:nth-child(-n + 4)) {
    display: none; }
  .single-cinema .cinema-full-information .cinema-production,
  .person .person-info-wrapper .person-information,
  .person .person-info-wrapper .person-information .main-info,
  .profile .information .contacts-info {
    width: 100%;
    padding-left: 0; }
  a.film {
    margin-left: 5px; }
  /* Убираем стрелки */
  .glide__arrow.glide__arrow--left {
    margin-left: 10px; }
  .glide__arrow.glide__arrow--right {
    margin-right: 10px; }
  .film span {
    display: none; }
  .company .info-wrapper,
  .news-wrapper .glide__slides,
  .tab.temp.active .glide__slides {
    flex-wrap: wrap;
    /*flex-direction: row;*/
    flex-direction: column;
    justify-content: flex-start; }
  /*.glide__slides li {*/
  /*    display: block;*/
  /*}*/
  .month .list-item {
    text-align: left;
    padding: 5px; }
  .paginatorNew.pager-new-main-container {
    padding-top: 100px; }
  .cinema,
  .glide__slide.cinema {
    /*width: 90px;*/
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column; }
  main .dzyga-main.more-details-faq {
    width: 100%; }
  .company .info-wrapper .company-information {
    width: auto; }
  .project .linkreview,
  .accaunt .input-wrapper,
  .profile-form .col.col-left {
    width: 100%; }
  .popup-gallery {
    display: flex;
    flex-direction: row; }
  .project.linkreview .input-wrapper.input89 {
    width: calc(80% - 20px); } }

.month {
  margin-left: 30px; }

.month .all-month {
  position: unset; }

.details li {
  margin-left: 40px; }

.details li::before {
  content: "- "; }

.form-group.field-personprofileform-image {
  margin-bottom: 0; }

.div-tiny p {
  text-indent: 20px;
  margin-bottom: 10px; }

.soc {
  font-size: 16px;
  color: #FFB21B;
  margin-right: 10px; }

.common-home button {
  padding: 5px; }

/* Цифры в кружочке */
.circle {
  background: #ffcd00;
  padding: 10px;
  margin: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  max-width: 10px;
  max-height: 10px; }

.circle__number {
  font-size: 1rem;
  color: #fff; }

/* Фикс для стрелок слайдера на главной */
.dzyga-cinema .cinema-slider,
.laureats-slider {
  width: calc(100% - 70px); }

/**/
[class*=" icon-"]::before,
[class^="icon-"]::before {
  margin-right: .01em;
  margin-left: .01em; }

/* */
form label[for="photo"] {
  height: 410px !important; }

/**/
/*a.asc::after,
 a.desc::after {*/
/*    position: relative;*/
/*    top: 1px;*/
/*    display: inline-block;*/
/*    font-family: 'Glyphicons Halflings';*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    line-height: 1;*/
/*    padding-left: 5px;*/
/*}*/
/*a.asc::after {*/
/*    content: !*"\e113"*! "\e151";*/
/*}*/
/*a.desc::after {*/
/*    content: !*"\e114"*! "\e152";*/
/*}*/
/*.fa {*/
/*    font-family: fontawesome !important;*/
/*}*/
/**/
.pro-aside .list-wrapper .checkbox-item {
  min-height: 1.5em; }

.pro-version .project-item .img,
.pro-version .person-item .img {
  background-size: contain; }

/* Список Team */
ul.team li {
  display: inline-block;
  /* все в строку */ }

.item-inner {
  min-height: 300px; }

/* Список кинотеатров - кнопки */
.btns2 a,
.icons a {
  padding: 6px 5px !important;
  margin-right: 5px; }

.icons {
  display: flex;
  justify-content: flex-end; }

.icons a:last-of-type {
  margin-bottom: 15px !important; }

/**/
main .dzyga-main.audition {
  padding: 20px 20px 50px 0;
  width: calc(100% - 560px); }

/*@media screen and (max-width: 600px) {*/
/*    table.box-office-table td:before {*/
/*        content: attr(data-label);*/
/*        float: left;*/
/*        text-transform: uppercase;*/
/*        font-weight: bold;*/
/*    }*/
/*}*/
.checkbox-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start; }

.checkbox-group input {
  cursor: pointer;
  width: 10px !important;
  margin-right: 10px; }

@media (min-width: 1201px) {
  .btn {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .btn:hover {
      color: #FFCD00;
      background-color: white; }
  .link-search {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .link-search:hover {
      color: white; }
  .cinema-wrapper .cinema-filter .list-item a {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .cinema-wrapper .cinema-filter .list-item a:hover {
      background-color: #222222;
      color: white; }
  .tabs-btn-wrapper .tab-btn {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .tabs-btn-wrapper .tab-btn:hover {
      color: #FFCD00; }
  .dzyga-header .top-navigation a {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .dzyga-header .top-navigation a:hover {
      color: #FFCD00; }
  .dzyga-header .top-navigation .social-list a {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
    .dzyga-header .top-navigation .social-list a:hover {
      color: white; }
  .add-to-bookmark:hover .icon-bookmark-empty::before {
    content: '\e80b'; } }

@media (max-width: 1200px) {
  body {
    margin-top: 80px; }
  main {
    transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82); }
  h2.section-heading {
    font-size: 3em; }
  .container {
    overflow: hidden;
    width: 100%; }
  .dzyga-header .top-navigation,
  .dzyga-header .main-navigation {
    display: none; }
  .dzyga-header-mobile {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
    background-color: #222222;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10; }
    .dzyga-header-mobile .mobile-menu {
      position: absolute;
      width: 100%;
      height: 100vh;
      top: 0;
      left: 0;
      background-color: rgba(0, 0, 0, 0.9);
      z-index: 10;
      transition: all 0.3s cubic-bezier(0.68, 0.21, 0.66, 0.82);
      overflow: hidden;
      opacity: 0;
      color: #222222;
      visibility: hidden; }
      .dzyga-header-mobile .mobile-menu.active {
        left: 0;
        opacity: 1;
        visibility: visible;
        overflow: visible; }
        .dzyga-header-mobile .mobile-menu.active .wrapper {
          left: 70%; }
      .dzyga-header-mobile .mobile-menu .wrapper {
        background-color: #303032;
        height: 100%;
        width: 30%;
        left: 100%;
        position: relative;
        padding: 20px;
        transition: 0.4s cubic-bezier(0.165, 0.84, 0.44, 1) 0.2s;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        color: white; }
        .dzyga-header-mobile .mobile-menu .wrapper .search-wrapper {
          width: 100%; }
          .dzyga-header-mobile .mobile-menu .wrapper .search-wrapper input {
            width: 100%;
            padding: 5px 15px;
            border-radius: 5px; }
        .dzyga-header-mobile .mobile-menu .wrapper .navigation-list {
          margin: 10px 0; }
          .dzyga-header-mobile .mobile-menu .wrapper .navigation-list .list-item {
            padding: 5px; }
        .dzyga-header-mobile .mobile-menu .wrapper .btn {
          margin: 10px 0; }
        .dzyga-header-mobile .mobile-menu .wrapper .languages-list {
          display: flex;
          width: 100%;
          justify-content: space-evenly; }
        .dzyga-header-mobile .mobile-menu .wrapper .social-list {
          display: flex;
          width: 100%;
          justify-content: space-between;
          margin-top: 20px; }
        .dzyga-header-mobile .mobile-menu .wrapper .pro-menu {
          flex-direction: column;
          justify-content: flex-start;
          align-items: flex-start;
          padding: 0; }
          .dzyga-header-mobile .mobile-menu .wrapper .pro-menu::before, .dzyga-header-mobile .mobile-menu .wrapper .pro-menu::after {
            display: none; }
          .dzyga-header-mobile .mobile-menu .wrapper .pro-menu .list-item {
            position: relative; }
          .dzyga-header-mobile .mobile-menu .wrapper .pro-menu .list-item.children:hover > .sub-menu {
            display: block;
            right: 100%;
            max-height: 300px;
            overflow-y: scroll;
            left: initial;
            top: 0;
            width: auto;
            padding: 10px 5px; }
          .dzyga-header-mobile .mobile-menu .wrapper .pro-menu .list-item.children .sub-menu .list-item {
            width: 100%; }
    .dzyga-header-mobile .logo,
    .dzyga-header-mobile .btn-menu {
      position: relative;
      z-index: 11; }
  .dzyga-premiere .section-heading {
    left: 50px;
    top: 280px; }
  .dzyga-premiere .premiere-slider .glide__slide .movie-poster {
    height: 370px; } }

@media (max-width: 1024px) {
  h2.section-heading {
    font-size: 2.8em; }
  .dzyga-premiere .section-heading {
    top: 265px; }
  .cinema,
  .glide__slide.cinema {
    min-height: auto;
    height: auto; }
  .tabs-btn-wrapper .tab-btn {
    padding: 10px 30px; }
  .single-cinema .cinema-preview .cinema-poster {
    margin-right: 0;
    height: 300px;
    width: calc(30% - 15px); }
  .single-cinema .cinema-preview .cinema-video {
    flex-grow: 0;
    width: 70%; }
  .glide__slide.video-wrapper {
    height: 300px; } }

@media (max-width: 992px) {
  main .dzyga-main {
    width: 100%;
    padding-bottom: 0; }
  main aside {
    width: 100%;
    padding-bottom: 0; }
    main aside .aside-ad {
      height: 200px; }
    main aside .person {
      width: calc(25% - 5px); }
    main aside .premier-wrapper {
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: space-between; }
      main aside .premier-wrapper .premier-film {
        width: calc(50% - 5px); }
        main aside .premier-wrapper .premier-film:last-child {
          margin-bottom: 10px; }
  .single-cinema .cinema-preview .cinema-poster {
    height: 350px; } }

@media (max-width: 768px) {
  h2.section-heading {
    font-size: 2.5em; }
  .dzyga-premiere .premiere-slider {
    width: calc(100% - 150px); }
  .dzyga-faq {
    margin-top: 20px;
    padding: 20px 0; }
  .dzyga-bottom-navigation .logo-ling {
    margin-right: 80px; }
  .dzyga-bottom-navigation .logo {
    width: 180px; }
  .person .person-info-wrapper .person-photo {
    width: 250px; }
  .single-cinema .cinema-preview .cinema-poster {
    height: 300px; }
  .glide__slide.video-wrapper {
    height: 470px; }
  .dzyga-header-mobile .mobile-menu .wrapper {
    width: 45%; }
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    left: 55%; } }

@media (max-width: 660px) {
  ul.team li {
    display: block;
    /* все в столбик */ }
  .project-form .col.col-left {
    width: 100%; }
  .project.delete {
    display: block;
    /* все в столбик */ }
  .project.delete .input-wrapper {
    margin-bottom: 10px; }
  .input-wrapper.btn-del,
  .select2,
  .input-wrapper.team-first_name,
  .input-wrapper.team-email,
  .input-wrapper.team-position,
  .input-wrapper.team-auth_id {
    width: 100% !important; }
  .form-btn.js-del-team {
    margin-left: 0 !important; }
  .dzyga-main .cinemas {
    width: 100%; }
  .dzyga-main .cinemas .company-item {
    flex-wrap: nowrap;
    flex-direction: column;
    margin-bottom: 350px; }
  .dzyga-main .cinemas .company-item .img {
    min-width: 150px;
    height: 100%;
    background: no-repeat 50%;
    background-size: cover;
    position: relative;
    width: 180px;
    flex-shrink: 0; }
  .project.h20.delete {
    display: none !important; }
  .project.team.delete {
    display: block !important; }
  .project.team.delete label {
    display: block !important; }
  /* Цвет текста и фона для ввода */
  .mce-content-body,
  .form-group input,
  .form-group select,
  .form-group textarea {
    background-color: #a4a6ab;
    color: #fff; }
  .casting .cinema-castings-wrapper .characteristics-list {
    display: inherit;
    /* все в столбик */ }
  .casting-item .casting-desc {
    margin-top: 30px !important; }
  .modal-dialog-custom {
    width: auto; }
  /*.modal-footer {*/
  /*    text-align: left;*/
  /*}*/
  .btns {
    position: relative;
    top: 20px;
    right: 0;
    width: 100%; }
  .btns2 {
    display: flex;
    justify-content: flex-start;
    padding-left: 0 !important;
    /*line-height: 20px;*/
    /*min-height: 20px;*/ }
  .btns2 a {
    margin-right: 5px;
    /*min-height: 20px;*/ }
  .icons a {
    margin-bottom: 0;
    min-height: 32px; }
  /* Фикс моб меню */
  .dzyga-header-mobile .mobile-menu {
    background-color: #303032;
    height: calc(100vh - 78px);
    top: 78px; }
  .dzyga-header-mobile .mobile-menu .wrapper .social-list {
    margin-bottom: 25px;
    margin-left: 0;
    margin-right: 0; }
  .dzyga-header-mobile .mobile-menu .wrapper .social-list .list-item:last-child {
    padding-right: 0; }
  h2.section-heading {
    font-size: 1.8em; }
  .dzyga-header-mobile .mobile-menu .wrapper {
    width: 55%; }
  .dzyga-header-mobile .mobile-menu.active .wrapper {
    left: 45%; }
  .dzyga-header-mobile {
    padding: 15px 20px; }
  body {
    margin-top: 70px; }
  main .dzyga-main {
    padding: 20px 0 0 0; }
  .dzyga-premiere .section-heading {
    transform: none;
    position: relative;
    top: initial;
    left: 0; }
  .dzyga-premiere .premiere-slider {
    width: calc(100% - 40px);
    margin: 0 auto; }
  .cinema,
  .glide__slide.cinema {
    flex-direction: column; }
  .cinema .cinema-poster,
  .glide__slide.cinema .cinema-poster {
    width: 100%;
    height: 243px; }
  .dzyga-cinema .cinema-slider,
  .laureats-slider {
    width: calc(100% - 40px); }
  .cinema-info-wrapper {
    width: 100%; }
  .glide__arrows .glide__arrow--left {
    left: -40px; }
  .glide__arrows .glide__arrow--right {
    right: -40px; }
  .block {
    width: 100%; }
  main aside .person {
    width: calc(50% - 5px); }
  .dzyga-bottom-navigation .container {
    flex-direction: column;
    align-items: center; }
  .dzyga-bottom-navigation .logo-ling {
    margin: 0 0 10px 0; }
  .cinema-info-wrapper {
    padding: 15px 15px 20px 15px; }
  .cinema-rating {
    position: relative;
    top: 0;
    right: 0;
    flex-wrap: nowrap;
    align-items: center; }
  .tabs-btn-wrapper {
    flex-wrap: wrap;
    justify-content: space-between; }
  .cinema-wrapper .tabs-btn-wrapper {
    margin-bottom: 10px; }
  .tabs-btn-wrapper .tab-btn {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    padding: 10px 5px;
    display: block; }
  .box-office-table .t-name {
    padding-left: 5px; }
  .box-office-table .t-money,
  .box-office-table .t-rolling {
    padding-left: 5px; }
  .box-office-table thead tr th {
    font-size: 0.9em; }
  .box-office-table .t-name {
    font-size: 0.95em; }
  .box-office-table tbody tr td {
    font-size: .9em; }
  .box-office-wrapper .tabs-btn-wrapper {
    margin-bottom: 0; }
  .reviews-wrapper .cinema .cinema-poster {
    width: 100%; }
  .breadcrumbs {
    display: none; }
  .article-wrapper .news-img {
    margin-top: 0; }
  .article-wrapper .content .gallery-items {
    justify-content: space-between; }
  .article-wrapper .content .gallery-items .gallery-item {
    width: calc(50% - 20px);
    height: auto; }
  .article-wrapper .content .gallery-items .gallery-item:nth-child(5n) {
    margin-right: 20px; }
  .personality-wrapper .personality-items .personality-item .img {
    min-width: 150px;
    width: 150px; }
  .person .person-info-wrapper .person-information {
    width: 100%; }
  .person .photos .frames-slider {
    width: calc(100% - 40px); }
  .single-cinema .cinema-preview .cinema-video {
    width: 100%;
    height: 300px; }
  .single-cinema .cinema-preview .cinema-poster {
    width: 100%;
    height: 443px;
    margin-bottom: 15px; } }

@media screen and (max-width: 600px) {
  table.box-office-table thead {
    display: none; }
  table.box-office-table tr {
    /*height: 60px;*/
    display: block;
    margin-bottom: 1rem;
    border-bottom: 2px solid #e8e9eb; }
  table.box-office-table td {
    height: 30px !important;
    display: block;
    text-align: right; }
  table.box-office-table td:first-child {
    height: 70px !important; }
  table.box-office-table td:before {
    content: attr(data-label);
    float: left;
    font-weight: bold; }
  .box-office-table .t-money,
  .box-office-table .t-name,
  .box-office-table .t-rolling {
    padding-left: 0; }
  /* Отображение кастинга */
  main .dzyga-main.audition {
    width: calc(100% - 0px); }
  /* Перенос даты приема заявок на новую строку */
  .project-castings-list.audition span:first-child {
    display: block; } }

/* Личное меню в ноуте */
@media (max-height: 720px) {
  .account-wrapper .account-list {
    overflow: hidden !important;
    overflow-y: scroll !important;
    height: 300px;
    padding-right: 15px; } }
