/*body {*/
/*    font-family: "TimesNew", sans-serif !important;*/
/*}*/

/* Фикс - добавление красной звездочки к обязательным полям */
.required label::after {
    content: '*';
    margin-left: 4px;
    color: red;
}

/* Фикс - */
.has-error input,
.has-error select {
    border: 1px solid red;
}

.help-block {
    color: red;
    display: block;
    /*margin-top: 5px;*/
    /*margin-bottom: 10px;*/
}

/* */
.add-to-bookmark.active,
.pro-menu-sub a.active {
    color: #ffcd00 !important;
}

.input-box.has-success input {
    border: 1px solid green;
}

.input-box.has-error input,
.not-unique {
    border: 1px solid red !important;
}

.help-block.help-block-error {
    color: #cc0b16 !important;
}

.profile-form .input-wrapper .tagify,
.input-wrapper .tagify {
    background-color: #a4a6ab;
    color: #fff;
}

.tagify__tag>div {
    background-color: #000;
    color: #fff;
}

.bgс-a4a6ab {
    background-color: #a4a6ab !important;
    color: #fff;
}

.page-ttl-h1 {
    display: inline-block;
    margin: 20px;
    font-family: "GothamProBold", sans-serif;
    font-size: 24px;
    font-weight: normal;
    line-height: 32px;
}

.content-p {
    font-size: 14px;
    line-height: 20px;
}

.hcmin {
    height: min-content !important;
}

.hcmax {
    height: max-content !important;
}

.hcbox {
    height: content-box !important;
}

.hcfit {
    height: fit-content !important;
}

.w100p {
    width: 100% !important;
}

.w348 {
    width: 348px !important;
}

.w376 {
    width: 376px !important;
}

.w400 {
    width: 400px !important;
}

.w462 {
    width: 462px !important;
}

.w500 {
    width: 500px !important;
}

.profile-form .col.col-right .project .btn-field,
.profile-form .input-wrapper .btn-field {
    top: 16px !important;
}

.project.delete {
    -webkit-flex-wrap: nowrap;
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
    width: 100%;
}

.profile-form .input-wrapper.label {
    margin-bottom: 0 !important;
}

.tox .tox-edit-area,
.mce-content-body {
    background-color: #a4a6ab !important;
    color: white !important;
}

.tariffs-wrapper {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.tariffs-wrapper .tariff-item {
    padding: 30px 20px;
    background-color: #414046;
    color: #fff;
    text-align: center;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    min-height: 213px;
    max-height: 213px;
    margin-right: 20px;
}

.tariffs-wrapper .tariff-item.top {
    min-height: 270px;
    position: relative;
    top: -25px;
    padding-top: 55px;
}

.tariffs-wrapper .tariff-item.top span.top {
    position: absolute;
    top: 0;
    left: 50%;
    -webkit-transform: translate(-50%);
    -ms-transform: translate(-50%);
    transform: translate(-50%);
    background-color: #302f34;
    padding: 5px 40px;
    margin-bottom: 0;
    font-size: 1em;
}

.tariffs-wrapper .tariff-item .tariff-btn {
    background-color: #302f34;
    border: 1px solid #fff;
    color: #fff;
    padding: 5px 40px;
}

.tariffs-wrapper .tariff-item .tariff-price {
    font-family: MontserratExtraBold, serif;
    font-size: 1.4em;
}

.tariffs-wrapper .tariff-item .tariff-description {
    margin: 15px 0;
}

.tariffs-wrapper .tariff-item span {
    margin-bottom: 15px;
    display: block;
    font-size: .9em;
}

.project-form .full,
.project-form .heading {
    width: 100% !important;
}

.project-form .form-btn {
    padding: 10px;
    background-color: #ffcd00;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    -o-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    transition: all .3s cubic-bezier(.68, .21, .66, .82);
}

.sign .heading {
    background-color: #f2f2f2 !important;
}

.input-wrapper.email.label,
.input-wrapper.position.label,
.input-wrapper.access-level.label {
    margin: 0 !important;
}

/* Подсказка при множественном выборе */
.select2-selection__rendered,
.select2-container--krajee .select2-selection--single,
.select2-container--krajee .select2-selection--multiple .select2-search--inline .select2-search__field {
    background-color: #a4a6ab !important;
    color: #fff;
    font-size: 14px !important;
    width: 100% !important;
}

/* Подсказка при одиночном выборе */
.select2-selection__placeholder {
    color: #fff !important;
    font-size: 14px !important;
}

/* Выбранный элемент при одиночном выборе */
.select2-selection--single .select2-selection__rendered {
    background-color: #a4a6ab !important;
    color: #fff !important;
    font-size: 14px !important;
}

/* Кнопка удаления при одиночном выборе */
.select2-selection__clear {
    color: #fff !important;
    /*font-size: 1.9em !important;*/
    font-size: 14px !important;
    right: 2rem !important;
}

/* Выбранный элемент при множественном выборе */
.select2-container--krajee .select2-selection--multiple .select2-selection__choice {
    background-color: #000 !important;
    color: #fff !important;
    font-size: 12px !important;
    margin: 2px !important;
    padding: 2px !important;
    height: 26px !important;
}

/* Кнопка удаления при множественном выборе */
.select2-container--krajee .select2-selection--multiple .select2-selection__choice__remove {
    float: right;
    background-color: #000 !important;
    color: #fff;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    /*opacity: 0.4;*/
    /*filter: alpha(opacity=40);*/
    display: inline-block;
    text-shadow: 0 1px 0 #fff;
    font-size: 14px;
    font-weight: 700;
    line-height: 1;
    margin: 2px;
}

/* предложение для нового актера */
.select2-results__option.none11:before {
    content: '"';
}

.select2-results__option.none11:after {
    content: '" - додати як нового';
}



/* frontend/views/pro-company/add.php */
/* фикс кнопки-добавлялки */
.btn-field {
    top: 16px !important;
}


.casting-apply {
    padding: 10px 20px !important;
    background-color: #303032 !important;
    color: #fff !important;
    -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
    -o-transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
    transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
}

.casting-apply.active {
    background-color: #ffcd00 !important;
    color: #222 !important;
}

.casting-apply.resolution_off {
    background-color: red !important;
    color: #fff !important;
}

.casting-apply.resolution_on {
    background-color: green !important;
    color: #fff !important;
}

.casting-form .form-btn {
    padding: 10px;
    background-color: #ffcd00;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    -o-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    transition: all .3s cubic-bezier(.68, .21, .66, .82);
}


/* Скрываем radio-кнопки для звездочек */
.review.comment-form input[type="radio"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    visibility: hidden;
}

.sign2prof,
.sign2company,
.sign3actor,
.sign4 {
    padding: 25px 20px 20px;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
}


.input-wrapper.select-box label {
    left: 0 !important;
    top: 0 !important;
}


.tooltipster-sidetip.tooltipster-shadow.transfers-tooltipster .tooltipster-box {
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
}

.tooltipster-sidetip.tooltipster-shadow.transfers-tooltipster .tooltipster-content {
    font-family: "Open Sans", Arial, sans-serif;
    font-size: 15px;
    color: #000;
    padding: 10px 15px;
}

.form-btn-save2 {
    padding: 10px;
    -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    -o-transition: all .3s cubic-bezier(.68, .21, .66, .82);
    transition: all .3s cubic-bezier(.68, .21, .66, .82);
    background-color: #303032;
    text-align: center;
    color: #fff;
    margin-left: auto;
    width: 240px;
    cursor: pointer;
    margin-top: 5px;
    height: 36px;
}

/* Чек-бокс в фильтрах "Все" */
.pro-aside .checkbox-all {
    /*padding: 0 20px;*/
    margin: 10px 15px 0 0;
}

// .pro-aside .list-wrapper.prof {
//     padding-top: 10px;
// }

#registration-form3 .select2-search__field {
    width: 200px !important;
    font-size: 12px !important;
    color: #a4a6ab !important;
}

.casting-form .form-heading2,
.company-form .form-heading2,
.profile-form .form-heading2,
.project-form .form-heading2 {
    width: 100%;
    margin-bottom: 0;
    display: block;
}


/* Фикс кнопки "Удалить строку" */
.input-wrapper.btn-del {
    width: 40px !important;
    margin: 0;
}

.input-wrapper.btn-del a {
    margin: 4px;
}

/* Фикс длины полей для команды */
.input-wrapper.team-email {
    min-width: 165px !important;
    width: 165px !important;
    padding-right: 8px;
    margin: 0;
}

/*.input-wrapper.team-first_name {*/
/*    min-width: 100px !important;*/
/*    width: 100px !important;*/
/*    padding-right: 8px;*/
/*}*/
/*.input-wrapper.team-position {*/
/*    min-width: 100px !important;*/
/*    width: 100px !important;*/
/*    padding-right: 8px;*/
/*}*/
/*.input-wrapper.team-auth_id {*/
/*    min-width: 120px !important;*/
/*    width: 120px !important;*/
/*    padding-right: 8px;*/
/*}*/
.input-wrapper.team-last_name,
.input-wrapper.team-first_name,
.input-wrapper.team-position,
.input-wrapper.team-auth_id {
    width: calc(31% - 23px) !important;
    padding-right: 8px;
    margin: 0;
}

/* Фикс длины полей для проектов */
.project.linkreview .input-wrapper.input89 {
    width: calc(89% - 20px);
    padding-right: 8px;
    margin: 0;
}

.input-wrapper.project-name {
    width: calc(40% - 20px) !important;
    padding-right: 8px;
    margin: 0;
}

.input-wrapper.project-role {
    width: calc(60% - 20px) !important;
    padding-right: 8px;
    margin: 0;
}

/* Фикс длины полей для клиентов */
.row.row50 {
    width: calc(50% - 10px) !important;
    /*padding-right: 8px;*/
    /*margin: 0;*/
}

.input-wrapper.company-name {
    width: calc(50% - 20px) !important;
    padding-right: 8px;
    margin: 0;
}

.input-wrapper.company-name2 {
    width: calc(50% - 10px) !important;
    /*padding-right: 8px;*/
    margin: 0;
    height: 20px;
}

.input-wrapper.company-uri {
    width: calc(48% - 20px) !important;
    padding-right: 8px;
    margin: 0;
    /*height: 20px;*/
}

.input-wrapper.company-uri2 {
    width: calc(48% - 20px) !important;
    /*padding-right: 8px;*/
    padding-left: 18px;
    margin: 0;
    /*height: 20px;*/
}

.input-wrapper.agent-name {
    width: calc(45% - 8px) !important;
    padding-right: 8px;
    margin: 0;
}

.input-wrapper.agent-phone,
.input-wrapper.agent-email {
    width: calc(27% - 8px) !important;
    padding-right: 8px;
    margin: 0;
}

.input-wrapper.input40 {
    width: 40px !important;
    padding-right: 8px;
}

.input-wrapper.format {
    width: calc(16% - 20px) !important;
    /*padding-right: 8px;*/
    /*margin: 0;*/
}

.input-wrapper.category {
    width: calc(34% - 20px) !important;
    /*padding-right: 8px;*/
    /*margin: 0;*/
}

.input-wrapper.telechannel,
.input-wrapper.series_count {
    width: calc(26% - 8px) !important;
    padding-right: 8px;
    margin: 0;
}


.pro-version .project-item.audition {
    min-height: 160px !important;
    margin-bottom: 0 !important;
}

@media screen and (min-width: 767px) {
    main .dzyga-main.audition {
        /*width: calc(100% - 560px) !important;*/
        padding-right: 20px !important;
    }
}


.input-wrapper.input10 {
    margin: 0 0 0 0 !important;
    width: calc(10% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input15 {
    margin: 0 0 0 0 !important;
    width: calc(15% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input20 {
    margin: 0 0 0 0 !important;
    width: calc(20% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input24 {
    margin: 0 0 0 0 !important;
    width: calc(24% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input44 {
    margin: 0 0 0 0 !important;
    width: calc(44% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input64 {
    margin: 0 0 0 0 !important;
    width: calc(64% - 8px) !important;
    padding-right: 8px;
}

.input-wrapper.input88 {
    margin: 0 0 0 0 !important;
    width: calc(88% - 20px) !important;
}

.input-wrapper.label {
    margin: 0 0 0 0 !important;
    width: calc(50% - 40px) !important;
}

.input-wrapper.label2 {
    /*margin: 0 10px 0 0 !important;*/
    width: calc(50% - 20px) !important;
}

/* Стили для модального окна */
// .modal {}

// .modal-dialog {}

// .modal-content {}

// .modal-header {}

.modal-title {
    margin: 0;
    line-height: 1.42857143;
    font-size: 14px;
}

.modal-body {
    position: relative;
    padding: 0 15px;
    /*overflow-y: auto;*/
}

.slimScrollDiv {
    height: 100% !important;
}

.modal-dialog-custom {
    width: 650px;
    height: auto;
}

.modal-content-custom {
    height: 100%;
}

.slimScrollDiv {
    height: 320px !important;
    min-height: 320px !important;
    max-height: 400px !important;
}

/*.slimScrollDiv .scroller {*/
/*    height: 320px !important;*/
/*    min-height: 320px !important;*/
/*    max-height: 400px !important;*/
/*}*/
.slimScroll {
    height: 320px !important;
}

.modal-header {
    padding: 10px 15px !important;
    border-bottom: 1px solid #e5e5e5;
}

.modal-footer {
    padding: 10px 15px !important;
    text-align: right;
    border-top: 1px solid #e5e5e5;
}

button.close {
    padding: 0;
    cursor: pointer;
    background: transparent;
    border: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}

.close {
    float: right;
    font-size: 21px;
    font-weight: bold;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: 0.2;
}

.row {
    margin-right: -15px;
    margin-left: -15px;
}

.form-group {
    margin-bottom: 15px;
}

label {
    /*display: inline-block;*/
    max-width: 100%;
    margin-bottom: 5px;
    /*font-weight: 700;*/
}

.has-success .form-control {
    border-color: #3c763d;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

textarea.form-control {
    height: auto;
}

.form-control {
    display: block;
    width: 100%;
    height: 34px;
    padding: 6px 12px;
    font-size: 14px;
    line-height: 1.42857143;
    color: #555555;
    background-color: #fff;
    background-image: none;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    -o-transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.form-control::-ms-expand {
    background-color: transparent;
    border: 0;
}

.form-control:focus {
    border-color: #4D90FE;
    outline: none;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
}

.form-control::placeholder {
    color: #ffffff;
    opacity: 1;
}

.form-control:disabled,
.form-control[readonly] {
    /*background-color: #eeeeee;*/
    opacity: 1;
}

.form-control:disabled {
    cursor: not-allowed;
}

@media (min-width: 992px) {

    .col-md-1,
    .col-md-2,
    .col-md-3,
    .col-md-4,
    .col-md-5,
    .col-md-6,
    .col-md-7,
    .col-md-8,
    .col-md-9,
    .col-md-10,
    .col-md-11,
    .col-md-12 {
        float: left;
    }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
}

@media (min-width: 992px) {
    .col-md-1 {
        width: 8.3333333%;
    }

    /*.my.col-md-1 {*/
    /*    width: 8%;*/
    /*}*/
    .col-md-2 {
        width: 16.66666667%;
    }

    .my.col-md-2 {
        width: 15%;
    }

    .col-md-4 {
        width: 33.33333333%;
    }

    .col-md-6 {
        width: 50%;
    }

    .col-md-8 {
        width: 66.66666667%;
    }

    .col-md-10 {
        width: 83.33333333%;
    }

    .col-md-12p {
        width: 12%;
        float: left;
        position: relative;
        min-height: 1px;
        padding-right: 15px;
        padding-left: 15px;
    }

    .col-md-12 {
        width: 100%;
    }
}

@media (min-width: 1201px) {
    .btn {
        -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82);
        -o-transition: all .3s cubic-bezier(.68, .21, .66, .82);
        transition: all .3s cubic-bezier(.68, .21, .66, .82);
    }
}

.btn-success {
    color: #fff;
    background-color: #5cb85c;
    border-color: #4cae4c;
}

.pro-version .company-item .project-castings-list,
.pro-version .person-item .project-castings-list,
.pro-version .project-item .project-castings-list {
    width: 100%;
}

.pro-version .project-item {
    min-height: auto;
}

.dzyga-main.audition {
    padding-left: 20px !important;
}

.casting-apply.audition {
    background-color: #a4a6ab !important;
}

.btn-del {
    width: 40px !important;
    height: 40px !important;
    padding: 10px 0 0 0;
}

.btn-del a {
    margin: 4px;
}

.list-item.last a {
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    padding: 10px;
    background-color: #ffcd00 !important;
    font-family: MontserratBold, sans-serif;
    width: 100%;
}

.list-item.last a:hover {
    color: #222 !important;
}

/**/
.input-wrapper.phone input,
.input-wrapper input,
.input-wrapper select {
    padding: 0 5px !important;
    margin-top: 0 !important;
}

/*.pro-version .project-item .project-castings-list .list-item {*/
/*    justify-content: stretch !important;*/
/*}*/

.casting-edit {
    padding: 10px 12px !important;
    background-color: #303032 !important;
    color: #fff !important;
    -webkit-transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
    -o-transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
    transition: all .3s cubic-bezier(.68, .21, .66, .82) !important;
}

.casting-edit.audition {
    background-color: #a4a6ab !important;
}

.heading-wrapper .fields-heading {
    font-size: 14px !important;
    font-weight: 700 !important;
}

/* Отчерчивание кнопик "Добавить..." */
/*.heading-wrapper:after {*/
/*    content: "";*/
/*    display: block;*/
/*    position: absolute;*/
/*    !*width: 100%;*!*/
/*    !*width: min-content;*!*/
/*    width: content-box;*/
/*    height: 1px;*/
/*    background-color: #a4a6ab;*/
/*    left: 0;*/
/*    top: 60px;*/
/*}*/

.project-form .input-wrapper input.input,
.project-form .input-wrapper a.input {
    width: 100%;
    background-color: #a4a6ab;
    color: #fff;
    margin-top: 0 !important;
    height: 34px;
    font-family: MontserratMedium, sans-serif;
    font-size: 15.05px !important;
    line-height: 17.8667px;
}

.project-form .input-wrapper input.input {
    padding: 0 5px 0 12px !important;
}

.project-form .input-wrapper a.input {
    padding: 10px 5px 0 12px !important;
}


.cinema-duration a:last-of-type::after {
    content: ", ";
}

/* Скрытие выбранных элементов в списке от повторного выбора (удаления из выбранных) */
.select2-results__option[aria-selected=true] {
    display: none;
}

/* Фикс многострочного текста по высоте */
/*
https://getinstance.info/articles/css/truncate-multiline-text/
<div class="box">
    <div class="box__in">
 */
.box {
    overflow: hidden;
    min-height: 20px;
    max-height: 100px;
    width: 100%;
}

.box__in {
    -webkit-column-width: 80px;
    -moz-column-width: 80px;
    column-width: 80px;
}

/* В select d блоках фильтров */
.list-item.filter select {
    padding: 0 !important;
}

/**/
aside.aside-profile-edit {
    height: min-content;
}

/* Фикс высоты слайдера без слайдера */
.glide__slide.cinema {
    min-height: min-content;
    /*max-height: 240px;*/
}

.single-cinema .cinema-frames {
    background-color: #f2f2f2;
    padding: 0 20px;
    margin: 0;
}

/**/
#sel_day1::after,
#sel_day2::after,
#sel_day3::after,
#sel_day4::after,
#sel_day5::after,
#sel_day6::after {
    /*content: "\25B2"; /* вверх */
    content: "\25BC";
    /* вниз */
    /*content: "▼";*/
    font-size: 16px;
    margin-left: 5px;
}

#sel_day1.open::after,
#sel_day2.open::after,
#sel_day3.open::after,
#sel_day4.open::after,
#sel_day5.open::after,
#sel_day6.open::after {
    content: "▲";
    font-size: 16px;
    margin-left: 5px;
}

.accaunt {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

/**/
p.list a:not(:last-child)::after {
    content: ",";
}

/* Стили для картинок меньше 7штук */
.grid-of-images a {
    /*cursor: -webkit-zoom-in;*/
    /*cursor: -moz-zoom-in;*/
    cursor: zoom-in;
    border-bottom: 0;
    display: block;
    float: left;
    margin: 0 5px 5px 0;
    /*width: 110px;*/
    /*height: 90px;*/
}

button.mfp-arrow,
button.mfp-close {
    overflow: visible;
    cursor: pointer;
    background: 0 0;
    border: 0;
    display: block;
    outline: 0;
    padding: 0;
    z-index: 1046;
    box-shadow: none;
}

.mfp-arrow-right {
    right: 0;
}

.mfp-arrow {
    position: absolute;
    opacity: .65;
    margin: -55px 0 0;
    top: 50%;
    padding: 0;
    width: 90px;
    height: 110px;
}

.mfp-arrow,
.mfp-close,
.mfp-counter,
.mfp-preloader {
    user-select: none;
}

/* Фикс выпадашек с возрастом в фильтрах  */
.pro-aside .list-wrapper.years {
    padding: 0 0 0 0;
    margin: 10px 15px 20px 15px;
}

.pro-aside .list-wrapper.years select {
    border-radius: 5px;
    color: #fff;
    background-color: #555459;
    padding: 5px 0 5px 30px;
    position: relative;
    width: 100px;
}

/*.select-wrapper.year:before {*/
/*    content:           "\e812";*/
/*    display:           block;*/
/*    font-family:       GothamProBold, dzyga, serif;*/
/*    position:          absolute;*/
/*    font-size:         1.2em;*/
/*    color:             #fff;*/
/*    left:              0;*/
/*    top:               30px;*/
/*    !*-webkit-transform: translate(50%,-50%);*!*/
/*    !*-ms-transform:     translate(50%,-50%);*!*/
/*    transform:         translate(50%,-50%);*/
/*    z-index:           2;*/
/*}*/

.tab.content-item input,
.tab.content-item select {
    width: 100%;
    background-color: #a4a6ab;
    color: #fff;
}

.tabs-btn-wrapper .tab-btn {
    padding: 10px 10px !important;
}

.slider__bullets {
    bottom: 0;
}

.slider__bullet {
    background-color: #a4a6ab;
}

.slider__bullet.glide__bullet--active,
.slider__bullet:hover {
    background-color: #ffcd00;
}

.glide__bullet {
    border: 0 solid transparent !important;
}

/* Фикс размера кнопки "Удалить акаунт" */
.input-wrapper.ac-del {
    color: red;
    width: auto !important;
}

/* Фикс соц кнопок в футере */
.social-list {
    display: flex;
}

.social-list li {
    color: #ffcd00;
    padding-right: 20px;
}

/* Убрать стрелочки из input числового */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type='number'] {
    -moz-appearance: textfield;
}

.block-open {
    margin-top: 0;
    margin-bottom: 15px;
}

/* Фикс скрола вправо профессий если больше 6 */
.tabs-btn-wrapper {
    flex-wrap: wrap;
}

/*.movie-poster img,*/
/*.cinema-poster img {*/
/*    width: auto;*/
/*    height: 100%;*/
/*    right: 0;*/
/*    margin: auto;*/
/*}*/

.dzyga-header .pro-menu .list-item .sub-menu,
.dzyga-header .pro-menu a .sub-menu {
    padding: 20px 20px;
}

/* Фикс запятой после "Выбор проекта" перед названием проекта */
.cinema-info-wrapper p.one a:not(:last-of-type):after {
    content: " ";
}

.company .info-wrapper .company-information p span {
    font-family: MontserratMedium, sans-serif;
}

.company .contacts-list .list-item:last-of-type {
    font-family: MontserratMedium, sans-serif;
}

/* Фикс вывод картинко лого */
.company .person-photo,
.profile .person-photo {
    /*width: 280px;*/
    /*height: 340px;*/
    /*background-position: 50%;*/
    /*background-repeat: no-repeat;*/
    -webkit-background-size: contain !important;
    background-size: contain !important;
}

/* Фикс crop */
.cropbox .btn-group {
    margin-top: 5px;
}

.cropbox .btn {
    padding: 10px 5px;
    margin-right: 5px;
    background-color: #a4a6ab;
    color: #ffffff;
}

.workarea-cropbox,
.bg-cropbox {
    height: 800px;
    min-height: 800px;
    width: auto;
    min-width: auto;
    z-index: 200;
}

.frame-cropbox {
    /*height: 300px;*/
    /*min-height: 300px;*/
    /*width: auto;*/
    /*min-width: auto;*/
    z-index: 201;
}

div.btn.btn-primary.btn-file {
    display: none !important;
}

/**/
.blog-posts-item {
    margin: 20px 0;
    padding: 20px 0;
    border-top: 1px solid #eee;
}

.comment-count,
.comment-reply {
    color: #337ab7;
    text-decoration: none;
    cursor: pointer;
}

.comments.comments-post {
    margin-left: 40px;
}

.comments .comments .comments .comments .comments .comments {
    margin-left: 0;
}

.review-item-header,
.comment-item,
.review-item-footer {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    flex-wrap: wrap;
}

.review-item-body {
    width: calc(100% - 80px);
}

.review-item-body.c2 {
    width: calc(100% - 80px);
}

.comment-item.c2 {
    width: calc(100% - 40px);
}

.review-item {
    border-top: 1px solid #bcbcbc;
}

.review-item .review-item {
    padding: 10px 0;
}

.review-item .reviewer-rating {
    margin-bottom: 0;
}

@media screen and (max-width: 801px) {
    .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin: 0 !important;
    }

    .tariffs-wrapper .tariff-item {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }

    .tariffs-wrapper .tariff-item.top {
        margin-top: 30px;
    }

    .sign-wrap .step-list {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .dzyga-header-mobile {
        padding: 15px 20px 15px 0;
    }

    .input-wrapper.casting-name {
        width: 100%;
    }

    .account-list .list-item {
        margin-bottom: 15px;
    }

    .list-item:hover {
        color: #ffcd00;
    }
}

@media screen and (max-width: 769px) {
    .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin: 0 !important;
    }

    .tariffs-wrapper .tariff-item {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }

    .tariffs-wrapper .tariff-item.top {
        margin-top: 30px;
    }

    .sign-wrap .step-list {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .dzyga-header-mobile {
        padding: 15px 20px 15px 0;
    }

    .input-wrapper.casting-name {
        width: 100%;
    }

    .account-list .list-item {
        margin-bottom: 15px;
    }

    .list-item:hover {
        color: #ffcd00;
    }
}

@media screen and (max-width: 361px) {
    .sign-wrap .form-wrapper.tariffs .tariffs-wrapper {
        flex-wrap: wrap;
        flex-direction: column;
        align-items: center;
        margin: 0 !important;
    }

    .tariffs-wrapper .tariff-item {
        margin-right: 0 !important;
        margin-bottom: 10px;
    }

    .tariffs-wrapper .tariff-item.top {
        margin-top: 30px;
    }

    .sign-wrap .step-list {
        margin-top: 10px;
        margin-bottom: 15px;
    }

    .dzyga-header-mobile {
        padding: 15px 20px 15px 0;
    }

    .input-wrapper.casting-name {
        width: 100%;
    }

    .account-list .list-item {
        margin-bottom: 15px;
    }

    .list-item:hover {
        color: #ffcd00;
    }

    .dzyga-header-mobile .mobile-menu.active .wrapper {
        padding-top: 20px;
        margin-top: 0 !important;
        margin-bottom: 0;
        height: auto;
    }

    .navigation-list .list-item a {
        font-size: 16px;
    }

    ::placeholder {
        color: #fff;
    }

    .select-wrapper.year label {
        display: none;
    }

    .select-wrapper.year:before {
        top: 14px;
    }
}

@media screen and (max-height: 361px) {
    .dzyga-header-mobile .mobile-menu.active .wrapper {
        padding-top: 80px;
        margin-bottom: 46px;
        height: auto;
        width: 100%;
    }

    .navigation-list .list-item a {
        font-size: 16px;
    }

    ::placeholder {
        color: #000;
    }

    #search_text2 {
        line-height: 26px;
    }
}

@media screen and (max-width: 768px) {
    .dzyga-header-mobile .mobile-menu .wrapper {
        width: 45%;
    }

    .dzyga-header-mobile .mobile-menu.active .wrapper {
        left: 0;
    }

    #search_text2 {
        line-height: 26px;
    }
}

@media screen and (max-width: 660px) {
    .dzyga-header-mobile .mobile-menu .wrapper {
        width: 0;
    }

    .dzyga-header-mobile .mobile-menu.active .wrapper {
        left: 0 !important;
    }

    .dzyga-header-mobile .mobile-menu.active {
        overflow-y: auto !important;
    }

    #search_text2 {
        line-height: 26px;
    }
}

.dzyga-faq .faq-heading {
    cursor: auto;
}

.dzyga-header-mobile .mobile-menu.active {
    overflow: auto;
}

.input-wrapper.photo {
    margin-right: 15px;
}

.single-cinema .cinema-reactions-wrapper .comment-form {
    border-top: 1px solid #bcbcbc;
}

.characteristics-list .list-item span:not(:last-of-type):after {
    content: ",";
}

#personprofileregform-date_birth::placeholder {
    color: #fff;
    opacity: 1;
    /*margin-left: 20px;*/
}

/* Ширина */
.form-contact-us {
    width: 360px;
}

#feedbackform-message {
    height: 200px;
}

#userRole,
#userRole option {
    font-size: 14px;
}

#search_text2::placeholder {
    color: #000;
}

/*.dzyga-premiere .premiere-slider .glide__slide {*/
/*    margin-bottom: 20px;*/
/*    padding-top: 10px;*/
/*}*/

/*.dzyga-premiere .premiere-slider .glide__slide .movie-poster {*/
/*    height: 370px;*/
/*}*/

@media (max-width: 1200px) {
    .dzyga-premiere .premiere-slider .glide__slide .movie-poster {
        height: 434px;
    }
}

@media (max-width: 660px) {

    .single-cinema .cinema-frames .frames-slider .glide__slide a,
    .cinema .cinema-poster,
    .single-cinema .cinema-preview .cinema-poster,
    .reviews-wrapper .cinema .cinema-poster,
    .glide__slide.cinema .cinema-poster {
        width: auto;
        margin: auto;
    }

    .single-cinema .cinema-preview .cinema-poster {
        height: 300px;
    }

    .social-list {
        margin-left: 20px;
    }

    .bottom-nav-list {
        margin-top: 15px;
    }

    .cinema-rating,
    .bottom-nav-list.first,
    .bottom-nav-list.second {
        display: none;
    }

    .checkbox-item {
        margin-top: 15px;
    }

    .pro-version.profile .dzyga-main .person-item {
        /*flex-wrap: wrap;*/
        flex-direction: column;
        /*min-height: 200px;*/
        margin-bottom: 250px;
        background-color: unset;
    }

    .pro-version .project-item,
    .pro-version .person-item {
        flex-direction: column;
        /*min-height: 220px;*/
        min-height: min-content;
        /*margin-bottom: 260px;*/
        background-color: unset;
    }

    .pro-version .project-item.pr,
    .pro-version .person-item.pr {
        margin-bottom: 280px;
        /*margin-bottom: auto;*/
    }

    .pro-version .person-item.co {
        margin-bottom: 180px;
    }

    .pro-version .project-item .info-content {
        height: min-content;
    }

    .pro-version .project-item .info-wrapper {
        width: 100% !important;
    }

    .person .person-info-wrapper,
    .information.person-information {
        flex-direction: column;
        /*min-height: 200px;*/
        /*margin-bottom: 250px;*/
        /*background-color: unset;*/
    }

    .pro-version .person-item .info-content p a:not(:nth-child(-n + 4)) {
        display: none;
    }

    .single-cinema .cinema-full-information .cinema-production,
    .person .person-info-wrapper .person-information,
    .person .person-info-wrapper .person-information .main-info,
    .profile .information .contacts-info {
        width: 100%;
        padding-left: 0;
    }

    a.film {
        margin-left: 5px;
    }

    /* Убираем стрелки */
    .glide__arrow.glide__arrow--left {
        margin-left: 10px;
    }

    .glide__arrow.glide__arrow--right {
        margin-right: 10px;
    }

    .film span {
        display: none;
    }

    .company .info-wrapper,
    .news-wrapper .glide__slides,
    .tab.temp.active .glide__slides {
        flex-wrap: wrap;
        /*flex-direction: row;*/
        flex-direction: column;
        justify-content: flex-start;
    }

    /*.glide__slides li {*/
    /*    display: block;*/
    /*}*/
    .month .list-item {
        text-align: left;
        padding: 5px;
    }

    .paginatorNew.pager-new-main-container {
        padding-top: 100px;
    }

    .cinema,
    .glide__slide.cinema {
        /*width: 90px;*/
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
    }

    main .dzyga-main.more-details-faq {
        width: 100%;
    }

    .company .info-wrapper .company-information {
        width: auto;
    }

    .project .linkreview,
    .accaunt .input-wrapper,
    .profile-form .col.col-left {
        width: 100%;
    }

    .popup-gallery {
        display: flex;
        flex-direction: row;
    }

    .project.linkreview .input-wrapper.input89 {
        width: calc(80% - 20px);
    }
}

.month {
    margin-left: 30px;
}

.month .all-month {
    position: unset;
}

.details li {
    margin-left: 40px;
}

.details li::before {
    content: "- ";
}

.form-group.field-personprofileform-image {
    margin-bottom: 0;
}

.div-tiny p {
    text-indent: 20px;
    margin-bottom: 10px;
}

.soc {
    font-size: 16px;
    color: #FFB21B;
    margin-right: 10px;
}

.common-home button {
    padding: 5px;
}

/* Цифры в кружочке */
.circle {
    background: #ffcd00;
    padding: 10px;
    margin: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    max-width: 10px;
    max-height: 10px;
}

.circle__number {
    font-size: 1rem;
    color: #fff;
}

/* Фикс для стрелок слайдера на главной */
.dzyga-cinema .cinema-slider,
.laureats-slider {
    width: calc(100% - 70px);
}


/**/
[class*=" icon-"]::before,
[class^="icon-"]::before {
    margin-right: .01em;
    margin-left: .01em;
}

/* */
form label[for="photo"] {
    height: 410px !important;
}

/**/
/*a.asc::after,
 a.desc::after {*/
/*    position: relative;*/
/*    top: 1px;*/
/*    display: inline-block;*/
/*    font-family: 'Glyphicons Halflings';*/
/*    font-style: normal;*/
/*    font-weight: normal;*/
/*    line-height: 1;*/
/*    padding-left: 5px;*/
/*}*/
/*a.asc::after {*/
/*    content: !*"\e113"*! "\e151";*/
/*}*/
/*a.desc::after {*/
/*    content: !*"\e114"*! "\e152";*/
/*}*/

/*.fa {*/
/*    font-family: fontawesome !important;*/
/*}*/

/**/
.pro-aside .list-wrapper .checkbox-item {
    min-height: 1.5em;
}

.pro-version .project-item .img,
.pro-version .person-item .img {
    background-size: contain;
}

/* Список Team */
ul.team li {
    display: inline-block;
    /* все в строку */
}

.item-inner {
    min-height: 300px;
}

/* Список кинотеатров - кнопки */
.btns2 a,
.icons a {
    padding: 6px 5px !important;
    margin-right: 5px;
}

.icons {
    display: flex;
    justify-content: flex-end;
}

.icons a:last-of-type {
    margin-bottom: 15px !important;
}


/**/
main .dzyga-main.audition {
    padding: 20px 20px 50px 0;
    width: calc(100% - 560px);
}

/*@media screen and (max-width: 600px) {*/
/*    table.box-office-table td:before {*/
/*        content: attr(data-label);*/
/*        float: left;*/
/*        text-transform: uppercase;*/
/*        font-weight: bold;*/
/*    }*/
/*}*/



.checkbox-group {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.checkbox-group input {
    cursor: pointer;
    width: 10px !important;
    margin-right: 10px;
}