// Styles for personality page elements.
.title-wrapper {
    font-family: 'MontserratExtraBold', sans-serif;
    font-weight: 900;
    font-size: 18px;
    color: #222222;
    line-height: normal;
}
.personality-wrapper {

    .personality-items {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 20px 0 30px;

        .personality-item {
            width: 100%;
            display: flex;
            align-items: flex-start;
            background-color: #f2f2f2;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            .img {
                width: 170px;
                height: auto;
                position: relative;

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 2;
                }
            }

            .desc {
                padding: 15px 20px;
                color: #222222;

                .personality-title {
                    font-weight: 700;
                    font-family: 'MontserratBold', sans-serif;
                    font-size: 1.2em;
                    margin-bottom: 5px;
                    transition: $transition;
                    &:hover {
                        color: $gold;
                    }
                }

                .personality-role {
                    margin-bottom: 15px;
                    line-height: normal;

                    a {
                        font-weight: 700;
                        font-family: 'MontserratBold', sans-serif;
                        &:last-child {
                            &::after {
                                content: none
                            }
                        }
                        &::after {
                            content: ', ';
                            // margin-left: -2px;
                        }
                    }
                }

                .personality-date {
                    margin-bottom: 15px;
                    font-family: 'MontserratRegular', sans-serif;
                    font-weight: 400;
                    line-height: normal;

                    b {
                        font-weight: 700;
                        font-family: 'MontserratBold', sans-serif;
                    }
                }

                .last-project {
                    p {
                        margin-bottom: 5px;
                        font-family: 'MontserratRegular', sans-serif;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                    }

                    ul {
                        display: flex;
                        flex-wrap: wrap;

                        li {
                            font-family: 'MontserratRegular', sans-serif;
                            font-weight: 400;
                            margin-bottom: 5px;
                            margin-right: 5px;
                            font-size: 12px;
                            line-height: normal;
                            &:last-child {
                                &::after {
                                    content: none
                                }
                            }

                            &::after {
                                content: ',';
                                // margin-left: -2px;
                            }

                            a {
                                font-weight: 700;
                                font-family: 'MontserratBold', sans-serif;
                            }
                        }
                    }
                }
            }
        }
    }
}