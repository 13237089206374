@font-face {
    font-family: 'dzyga';
    src: url('../fonts/dzyga.eot?74477912');
    src: url('../fonts/dzyga.eot?74477912#iefix') format('embedded-opentype'),
         url('../fonts/dzyga.ttf?74477912') format('truetype'),
         url('../fonts/dzyga.svg?74477912#dzyga') format('svg');
    font-weight: normal;
    font-style: normal;
  }
  /* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
  /* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
  /*
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    @font-face {
      font-family: 'dzyga';
      src: url('../font/dzyga.svg?61282810#dzyga') format('svg');
    }
  }
  */
   
   [class^="icon-"]:before, [class*=" icon-"]:before {
    font-family: "dzyga";
    font-style: normal;
    font-weight: normal;
    speak: none;
   
    display: inline-block;
    text-decoration: inherit;
    width: 1em;
    margin-right: .2em;
    text-align: center;
    /* opacity: .8; */
   
    /* For safety - reset parent styles, that can break glyph codes*/
    font-variant: normal;
    text-transform: none;
   
    /* fix buttons height, for twitter bootstrap */
    line-height: 1em;
   
    /* Animation center compensation - margins should be symmetric */
    /* remove if not needed */
    margin-left: .2em;
   
    /* you can be more comfortable with increased icons size */
    /* font-size: 120%; */
   
    /* Font smoothing. That was taken from TWBS */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
   
    /* Uncomment for 3D effect */
    /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
  }
   
  .icon-globe:before { content: '\e800'; } /* '' */
  .icon-search:before { content: '\e801'; } /* '' */
  .icon-left-open:before { content: '\e802'; } /* '' */
  .icon-right-open:before { content: '\e803'; } /* '' */
  .icon-star:before { content: '\e804'; } /* '' */
  .icon-star-empty:before { content: '\e805'; } /* '' */
  .icon-plus-circled:before { content: '\e806'; } /* '' */
  .icon-up-big:before { content: '\e807'; } /* '' */
  .icon-up-open:before { content: '\e808'; } /* '' */
  .icon-down-big:before { content: '\e809'; } /* '' */
  .icon-help-circled:before { content: '\e80a'; } /* '' */
  .icon-bookmark:before { content: '\e80b'; } /* '' */
  .icon-phone:before { content: '\e80c'; } /* '' */
  .icon-mail:before { content: '\e80d'; } /* '' */
  .icon-location:before { content: '\e80e'; } /* '' */
  .icon-pencil:before { content: '\e80f'; } /* '' */
  .icon-cancel:before { content: '\e810'; } /* '' */
  .icon-plus:before { content: '\e811'; } /* '' */
  .icon-calendar:before { content: '\e812'; } /* '' */
  .icon-bookmark-empty:before { content: '\f097'; } /* '' */
  .icon-facebook:before { content: '\f09a'; } /* '' */
  .icon-star-half-alt:before { content: '\f123'; } /* '' */
  .icon-youtube-play:before { content: '\f16a'; } /* '' */
  .icon-instagram:before { content: '\f16d'; } /* '' */
  .icon-twitter:before { content: '\f309'; } /* '' */
  .icon-site:before { content: '\f4f0'; } /* '' */

$gold: #FFCD00;

$dark_gray: #222222;
$medium_gray: #303032;
$light_gray: #A3A3A4;

$another_gray: #E6E6E6;

$background_text: #F2F2F2;
$border: #BCBCBC;

$green: #85CA32;

$transition: all .3s cubic-bezier(.68,.21,.66,.82);


a {
    color: inherit;
    // display: block;
}

section {
    overflow-x: hidden;
}

.container {
    width: 1200px;
    // height: 100%;
    padding: 0 20px;
    margin: auto;
    position: relative;
}

.icon {
    font-family: DzygaMBD;
    font-style: normal;
    color: inherit;
}

.list-dropdown {
	max-height: 1em;
    position: relative;
    z-index: 2;

	li {
        opacity: 0;
        overflow: hidden;
        visibility: hidden;
		transition: $transition;
		padding-bottom: 2px;

		&:first-of-type {
            opacity: 1;
            visibility: visible;
            overflow: visible;
            top: 0;
            transition: $transition;
		}
	}

	&:hover>li {
        opacity: 1;
        visibility: visible;
        overflow: visible;
		transition: $transition;
	}
}

.btn {
    padding: 10px 40px;
    background-color: $gold;
    border-radius: 5px;
   
}

h2.section-heading {
    font-family: MontserratBlack;
    font-size: 3.7em;
    text-transform: uppercase;
    color: #A0A0A0;
}

.ad-wrapper, .news-img {
    position: relative;
    margin: 20px 0;
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
    }
}

/* CINEMA ITEM */
.glide__slide.cinema, .cinema {
    height: auto;
    min-height: 240px;
    display: flex;
    align-items: flex-start;
    background-color: $background_text;
    position: relative;
    a.cinema-link {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .cinema-poster {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        height: 240px;
    }
}

.cinema-poster {
    min-width: 170px;
    height: 100%;
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.cinema-info-wrapper {
    padding: 15px 50px 15px 20px;
    position: relative;
    background-color: $background_text;
    flex-grow: 1;
    a {
        position: relative;
        z-index: 3;
    }

    p {
        font-family: MontserratRegular;
        margin-bottom: 5px;
        line-height: 1.1em;

        a {
            font-family: MontserratBold;
            transition: $transition;
            &[href]:hover {
                color: $gold;
            }
            &:not(:last-of-type)::after {
                content: ', ';
            }
        }
    }

    .cinema-name {
        font-family: MontserratBold;
        font-size: 1.1em;
        padding-bottom: 10px;

        a span.name-eng {
            font-family: MontserratRegular;
            font-size: 0.9em;
        }
    }

    .cinema-duration {
        font-family: MontserratBold;
    }

    .cinema-genre {
        font-family: MontserratRegular;

        a {
            font-family: MontserratBold;

            &:not(:last-of-type)::after {
                content: ',';
            }
        }
    }

    .cinema-description {
        margin-top: 10px;
    }
}

/* CINEMA RATING */
.cinema-rating {
    position: absolute;
    top: 20px;
    right: 15px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    p {
        width: 100%;
        justify-content: flex-end;
        display: flex;
        align-items: center;
    }

    span {
        font-family: MontserratBold;
        font-size: 1.1em;
        margin-left: 10px;
    }

    .icon {
        color: $gold;
    }
}

.add-to-bookmark {
    &.active > .icon-bookmark-empty:before {
        content: '\e80b'
    }
}


.glide__arrows {
    button {
        border: none;
        box-shadow: none;
        &:hover > .icon {
            color: $dark_gray;
            transition: $transition;
        }
    }
    .glide__arrow--left {
        left: -50px;
    }
    .glide__arrow--right {
        right: -50px;
    }
    .icon {
        font-size: 1.5em;
        color: $gold;
        transition: $transition;
    }
}

.blocks-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	margin-bottom: 30px;
}

.block {
    width: calc(50% - 10px);
    position: relative;
    img {
        transition: $transition;
    }
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        &:hover + .block-img > img {
            transform: scale(1.1);
            transition: $transition;
            filter: brightness(1.1)
        }
    }
    .block-info {
        padding: 15px 35px 20px 20px;
        background-color: $background_text;
        color: $dark_gray;
    }
    .block-img {
        height: 300px;
        overflow: hidden;
        .img-overlay {
            height: 100%;
            background-position: center;
            background-repeat: no-repeat;
            background-size: cover;
        }
    }
    .block-heading {
        font-size: 1.1em;
        font-family: MontserratBold;
        margin-bottom: 5px;
    }
    &.big {
        width: 100%;
        margin: 20px 0;
    }
}

.icon-star, .icon-star-empty {
    color: $gold;
}

h6.heading {
    font-family: MontserratBold;
    font-size: 1.1em;
}

h5 {
    font-family: MontserratExtraBold;  
    font-size: 1.1em;
}

h4.aside-heading {
    text-transform: uppercase;
    font-size: 1.1em;
    color: $light_gray;
    font-family: MontserratExtraBold;
}

/* PAGINATION */
.pagination {
    display: flex;
    justify-content: center;
    .list-item {
        margin-right: 5px;
        background-color: $background_text;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark_gray;
        transition: $transition;
        &:hover {
            color: $gold;
        }
        &:last-child {
            margin-right: 0px
        }
        a {
            padding: 0;
            font-family: MontserratBold;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
        }
        &.active {
            background-color: $medium_gray;
            color: $gold;
        }
    }
}


/* TABS */
.tabs-btn-wrapper {
    display: flex;
    .tab-btn {
        padding: 10px 40px;
        background-color: $background_text;
        color: $medium_gray;
        font-family: MontserratBold;  
        &.active {
            color: white;
            background-color: $medium_gray;
        }
    }
}

.tabs-content {
    position: relative;
    .tab {
     display: none;
        &.active {
            display: block;
        }
    }
}


.review-item {
    padding: 10px 20px;
    background-color: $background_text;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    border-bottom: 1px solid $border;
    &.comment {
        flex-wrap: nowrap;
        .reviewer-avatar {
            width: 50px;
            height: 50px;
            align-self: flex-start;
        }
        .reviewer {
            width: calc(100% - 50px);
        }
    }
    &:last-of-type {
        border-bottom: none;
    }
  
    .reviewer-name {
        font-family: MontserratBold;
        margin-bottom: 5px;
        display: block;
    }
    .reviewer-rating {
        font-family: MontserratRegular;
        margin-bottom: 10px;
    }
    .icon-star, .icon-star-half-alt {
        color: $gold;
    }
}

.reviewer-avatar {
    width: 70px;
    height: 70px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 50%;
    margin: 0 10px 10px 0;
    position: relative;
    img.pro {
        position: absolute;
        width: 25px;
        bottom: 0;
        left: 0;
        transition: $transition;
        z-index: 1;
    }
    a {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        z-index: 2;
        &:hover ~ * {
            filter: brightness(1.2);
        }
    }
}

.block-open {
    position: relative;
    display: flex;
    align-items: center;
    padding: 0 20px;
    color: $light_gray;
    margin-top: 10px;

    &::after {
        content: '';
        display: block;
        border-style: solid;
        border-width: 6px 6px 0 6px;
        border-color: $light_gray transparent transparent transparent;
        margin-left: 5px;
    }

    &.active::after {
        border-width: 0px 6px 6px 6px;
        border-color: transparent transparent $light_gray transparent;
    }
}

.account-wrapper {
    display: flex;
    align-items: center;
    color: white;
    font-family: MontserratBold;
    position: relative;
    &::after {
        content: '\e803';
        font-family: 'dzygambd';
        transform: rotate(90deg);
        position: relative;
        top: 3px;
        margin-left: 5px;
    }
    .account-icon {
        width: 35px;
        height: 35px;
        border-radius: 50%;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 10px;
    }
    &:hover {
        .account-list {
            opacity: 1;
            visibility: visible;
            overflow: visible;    
        }
    }
    .account-list {
        position: absolute;
        top: 100%;
        right: 0;
        z-index: 10;
        width: 200px;
        opacity: 0;
        visibility: hidden;
        overflow: hidden;
        transition: $transition;
        background-color: $background_text;
        .list-item {
            padding: 12px 8px;
            font-family: 'MontserratRegular';
            color: $dark_gray;
            border-bottom: 1px solid $border;
            text-align: right;
            &:last-of-type {
                border-bottom: 0;
            }
            a {
                transition: $transition;
                &:hover {
                    color: $gold;
                }
            }
        }
    }
}

a.link {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

/* MOBILE BUTTON */
.btn-menu {
  width: 50px;
  height: 40px;
  position: relative;
  transform: rotate(0deg);
  transition: .5s ease-in-out;
  cursor: pointer;
  display: block;
  span {
    display: block;
    position: absolute;
    height: 4px;
    width: 80%;
    background: $gold;
    border-radius: 9px;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);
    transition: .25s ease-in-out;
    &:nth-of-type(1) {
        top: 0px;
    }
    &:nth-of-type(2), &:nth-of-type(3) {
        top: 18px;
    }
    &:nth-of-type(4) {
        top: 36px;
    }
  }
  &.active {
    span {
        background-color: $gold;
        &:nth-of-type(1) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
        &:nth-of-type(2) {
            transform: rotate(45deg);
        }
        &:nth-of-type(3) {
            transform: rotate(-45deg);
        }
        &:nth-of-type(4) {
            top: 18px;
            width: 0%;
            left: 50%;
        }
    }
  }
}

.img-wrapper {
    position: relative;
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}


.filmography-list {
    .list-item {
        display: flex;
        align-items: center;
        padding: 10px;

        &:nth-of-type(2n) {
            background-color: $background_text;
        }

        .film {
            font-family: MontserratBold;

            span {
                font-family: MontserratRegular;

                &::before {
                    content: '/';
                    margin: 0 5px;
                }
            }
        }

        .role {
            flex-grow: 1;
            text-align: right;
            font-family: MontserratRegular;

            span {
                font-family: MontserratBold;
            }
        }

        .icon {
            margin-right: 15px;
        }
    }
}


::-webkit-input-placeholder { /* Chrome/Opera/Safari */
    color: white;
  }
  ::-moz-placeholder { /* Firefox 19+ */
    color: white;
  }
  :-ms-input-placeholder { /* IE 10+ */
    color: white;
  }
  :-moz-placeholder { /* Firefox 18- */
    color: white;
  }