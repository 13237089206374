.box-office-wrapper {
    .tabs-btn-wrapper {
        margin-bottom: 20px;
    }
}
.box-office-table {
    width: 100%;
    // border-collapse: collapse;
    border-collapse:separate; 
    border-spacing: 0 5px;
    
    thead {
        width: 100%;
        tr {
            background-color: #f2f2f2;
            th {
                text-align: left;
                height: 40px;
                border-collapse: collapse;
                font-size: 12px;
                font-family: 'MontserratRegular', sans-serif;
                font-weight: 400;
                &.t-name {
                    font-size: 12px;
                }
            }
        }
    }
    tbody {
        width: 100%;
        tr {
            background-color: #f2f2f2;
            td {
                height: 70px;
                font-family: 'MontserratExtraBold', sans-serif;
                font-weight: 900;
                font-size: 12px;
                border-collapse: collapse;
            }
            &:nth-child(odd) {
                background-color: #ffffff;
            }
        }
    }
    .img {
        width: 50px;
        min-width: 50px;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        position: relative;
        a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 2;
        }
    }
    .t-name {
        font-size: 14px;
        padding-left: 20px;
        a {
            transition: $transition;
            &:hover {
                color: $gold;
            }
        }
    }
    .t-money {
        padding-left: 10px;
    }
    .t-rolling {
        padding-left: 10px;

    }
    .t-label {
        a {
            font-size: 14px;
            color: #000000;
            i {

            }
        }
    }
}